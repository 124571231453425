import { ToastOptions } from "react-toastify";


export const defaultToastOptions: ToastOptions<{}> = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
} as ToastOptions;