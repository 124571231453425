import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button, Form } from "react-bootstrap";
import { removeDiacritics } from "../../shared/helpers/string-utils.helper";

interface FilterComponentProps {
    onFilter,
    onClear,
    filterText
    placeholder?
  }

interface MultipleFilterComponentProps {
  onFilter: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  onClear?: any,
  filterText: string | number | string[] | undefined,
  className?: string,
  filterSelect: string,
  options: any[],
  OnFilterSelect: React.ChangeEventHandler<HTMLSelectElement> | undefined,
  submitFitler: (event: React.FormEvent<HTMLFormElement>) => void;
  styleForm?: string
}

export interface FilterOptionsInterface {
  text: string,
  field: string,
}

export function filterByFields<T>(
    data: T[],
    filterText: string,
    fieldsToFilter: (keyof T)[]
  ): T[] {
    const filterTextToCompare = filterText.toLowerCase();
  
    return data.filter((item) => {
      return fieldsToFilter.some((field) => {
        const fieldValue = removeDiacritics(item[field] || '').toString().toLowerCase();
        return fieldValue.includes(filterTextToCompare);
      });
    });
}

const FilterComponent: FC<FilterComponentProps> = (props) => {
    return (
      <div style={{maxHeight: "32px", overflow: "hidden", width: '388px'}}>
        <Form.Control
          type="search"
          size="sm"
          className="form-control ps-5"
          placeholder={props.placeholder ?? "Pesquisar em todos os campos"}
          value={props.filterText}
          onChange={props.onFilter}
          id="Pesquisar-em-todos-os-campos"
        />
        <Button variant="bg-transparent" style={{bottom: '18px'}} className="btn bg-transparent px-2 py-0 position-relative translate-middle-y gia-search-input-icon" type="submit">
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
    );
  }


  export const MultipleFilterComponent:FC<MultipleFilterComponentProps> = (props) => {

    const submit = (event) => {
      event.preventDefault();
      props.submitFitler(event);
    }
  
    return (
      <div className={`${props.className}`} style={{ maxHeight: "32px" }}>
        <form onSubmit={(event) => submit(event)} className={` ${props.styleForm}  d-flex`} >
          <div style={{'minWidth': '100px'}} className="me-3" >
            <Form.Select value={props.filterSelect} onChange={props.OnFilterSelect} >
              {props.options.map((option, i) => {
                return <option value={option.field} key={option.field} >{option.text}</option>
              })}
            </Form.Select>
          </div>
          <div>
            <Form.Control
              type="search"
              size="sm"
              className="form-control ps-5 me-3 "
              placeholder="Pesquisar"
              value={props.filterText}
              onChange={props.onFilter}
              style={{ width: "300px", overflow: "hidden" }}
            />
            <Button variant="bg-transparent" style={{ bottom: '18px' }} className="btn bg-transparent px-2 py-0 position-relative translate-middle-y gia-search-input-icon" type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
          <div>
            <Button type="submit" size="sm" style={{ maxHeight: "32px" }}>Aplicar</Button>
          </div>
        </form>
      </div>
    );
  }

  export default FilterComponent;
