import React from 'react';
import styles from './menu.module.scss';
import {NavLink} from 'react-router-dom';
import {
  faChartSimple,
  faClock,
  faComments,
  faG,
  faKey,
  faPaperPlane,
  faSolarPanel,
  faTableColumns,
  faUser,
  faUserFriends,
  faUserGroup,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserService from "../../../modules/auth/UserService";
import {Roles} from "../../../modules/auth/roles";
import {clearManagedPlantsFilters} from "../../../pages/managed-plants/managed-plants";

export function Menu() {

  const hasRoleOverview = UserService.hasRole([Roles.OVERVIEW_VIEWER]);
  const hasRoleManagedPlants = UserService.hasRole([Roles.MANAGED_PLANTS_VIEWER]);
  const hasRoleDashboard = UserService.hasRole([Roles.DASHBOARD_VIEWER]);
  const hasRoleUserEditor = UserService.hasRole([Roles.USER_EDITOR]);
  const hasRoleGroupEditor = UserService.hasRole([Roles.GROUP_EDITOR]);
  const hasRolePersonaCredentialEditor = UserService.hasRole([Roles.PERSONA_CREDENTIAL_EDITOR]);
  const hasRoleGrowattEndUser = UserService.hasRole([Roles.GROWATT_END_USER_EDITOR]);
  const hasRoleAlertsEditor = UserService.hasRole([Roles.ALERT_EDITOR]);
  const hasRoleRobbuGroup = UserService.hasRole([Roles.ROBBU_GROUP_EDITOR]);
  const hasRoleSchedulerMessageEditor = UserService.hasRole([Roles.SCHEDULER_MESSAGE_EDITOR]);
  const hasRolePersonaEditor = UserService.hasRole([Roles.PERSONA_EDITOR]);
  const hasRoleCustomerEditor = UserService.hasRole([Roles.CUSTOMER_EDITOR]);

  return (
    <div className={`${styles.Menu}`}>
      <div className="menu-wrapper sticky-top d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="title-wrapper ps-xxl-4 ps-1 title-text">USINAS</div>
          <ul className="menu-items">
            {hasRoleOverview &&
              <NavLink to="/overview">
                <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faChartSimple} title="Visão Geral" /></div>Visão Geral</li>
              </NavLink>
            }
            {hasRoleManagedPlants &&
              <NavLink to="/managed-plants" onClick={clearManagedPlantsFilters}>
                <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faSolarPanel} title="Usinas Gerenciadas" /></div>Usinas Gerenciadas</li>
              </NavLink>
            }
            {hasRoleDashboard &&
              <NavLink to="/dashboards">
                <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faTableColumns} title="Dashboards" /></div>Dashboards</li>
              </NavLink>
            }

            {hasRoleGrowattEndUser &&
              <NavLink to="/growatt-end-user">
                <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faG} fixedWidth={true} size="lg" title="Usuários Growatt" /></div>Usuários Growatt</li>
              </NavLink>
            }

            {hasRolePersonaCredentialEditor &&
              <NavLink to="/credential">
                <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faKey} fixedWidth={true} size="lg" title="Credenciais" /></div>Credenciais</li>
              </NavLink>
            }
            {(hasRoleAlertsEditor || hasRoleSchedulerMessageEditor) &&
              <>
                <div className="title-wrapper ps-xxl-4 ps-1 title-text">EVENTOS</div>
                <ul className="menu-items">

                  {hasRoleAlertsEditor &&
                  <NavLink to="/event-configuration">
                    <li className="ps-xxl-4 ps-1">
                      <FontAwesomeIcon className="pe-2" icon={faPaperPlane} title="Alertas" />Alertas</li>
                  </NavLink>
                  }

                  {hasRoleSchedulerMessageEditor &&
                  <NavLink to="/manage-scheduler-messages">
                    <li className="ps-xxl-4 ps-1">
                      <FontAwesomeIcon className="pe-2" icon={faClock} title="Agendamentos" />Agendamentos</li>
                  </NavLink>
                  }

                </ul>
              </>
            }
            {(hasRoleUserEditor || hasRoleGroupEditor || hasRolePersonaEditor || hasRoleRobbuGroup || hasRoleCustomerEditor) &&
              <>
                <div className="title-wrapper ps-xxl-4 ps-1 title-text">USUÁRIOS</div>
                <ul className="menu-items">
                  {hasRoleUserEditor &&
                    <NavLink to="/manage-users">
                      <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faUser} fixedWidth={true} size="lg" title="Usuários" /></div>Usuários</li>
                    </NavLink>
                  }
                  {hasRoleGroupEditor &&
                    <NavLink to="/manage-groups">
                      <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faUsers} fixedWidth={true} size="lg" title="Grupos" /></div>Grupos</li>
                    </NavLink>
                  }
                  {hasRoleRobbuGroup &&
                    <NavLink to="/robbu-scheduler-group">
                      <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faComments} fixedWidth={true} size="lg" title="Grupos de Mensagens" /></div>Grupos de Mensagens</li>
                    </NavLink>
                  }
                  {hasRolePersonaEditor && <NavLink to="/persona">
                    <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faUserGroup} fixedWidth={true} size="lg" title="Persona" /></div>Persona</li>
                  </NavLink>
                  }
                  {hasRoleCustomerEditor && <NavLink to="/customer">
                    <li className="ps-xxl-4 ps-1"><div className="icon"><FontAwesomeIcon icon={faUserFriends} fixedWidth={true} size="lg" title="Clientes" /></div>Clientes</li>
                  </NavLink>
                  }
                </ul>
              </>
            }
          </ul>
        </div>
      </div>
    </div>
  )
}
