import axios from "axios";

export const loadPersonaList = (paginationUri, typeUri, filtersUri) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/persona/list?${paginationUri}${typeUri}${filtersUri}`;
  return axios.get(url);
}

export const getPersona = (id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/persona/edit/${id}`;
  return axios.get(url);

}

export const findPersonaType = (type, id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/persona/edit/${type}/${id}`;
  return axios.get(url);
}

export const findLegalEntityByDocument = (document: string) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/legalEntity/document/${document}`;
  return axios.get(url);
}

export const save = (type, id, persona, personaTYpeValue) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/persona/edit/${type}/${id}`;
  return axios.put(url, { personaDTO: persona, simplePersonaTypeValue: personaTYpeValue });
}
