import React, {FC} from 'react';
import styles from './header.module.scss';
import Navbar from '../navbar/navbar';
import {toAbsoluteUrl} from '../../../shared/helpers/AssetHelpers';

interface HeaderProps {
}

const Header: FC<HeaderProps> = () => (
  <div className={`${styles.Header} container-fluid g-0`}>
    <div className="row">
      <div className="col-12">
        <div className="container-fluid g-0">
          <div className="row g-0 h-100">
            <div className="col col-2 ps-xxl-4 ps-1 h-100 align-self-center">
              <img className={styles.imgLogo} src={toAbsoluteUrl('/media/haus-logo-h55.png')} alt="Haus Platform Logo"/>
            </div>
            <div className="col col-10 h-100 align-self-center">
              <Navbar/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
