import React, {FC} from 'react';
import styles from './emissions-reduction.module.scss';
import EmissionsReductionKpi from './kpi/emissions-reduction-kpi';
import EmissionsReductionKpiVariant from './kpi-variant/emissions-reduction-kpi-variant';
import GiaSpacer from '../../shared/gia-spacer/gia-spacer';
import {EmissionReductionInterface} from './interfaces/emission-reduction.interface';
import Skeleton from 'react-loading-skeleton';


interface EmissionsReductionProps {
  emissionReduction: EmissionReductionInterface,
  isLoading?: boolean
}

const EmissionsReduction: FC<EmissionsReductionProps> = ({emissionReduction, isLoading}) => (
  <div className={styles.EmissionsReduction} data-testid="EmissionsReduction">
    {isLoading && <Skeleton height={127}/> }
    {!isLoading &&
      <div className="card">
        <div className="title">
          Redução de Emissões
        </div>
        <div className="body">
          <EmissionsReductionKpiVariant value={emissionReduction.co2} valueSuffix={emissionReduction.co2Suffix} img={'/media/i-save.png'} />
          <GiaSpacer/>
          <EmissionsReductionKpi title={'ÁRVORES PLANTADAS'} value={emissionReduction.plantedTrees} valueSuffix={emissionReduction.plantedTreesSuffix} img={'/media/i-tree.png'} />
          <GiaSpacer/>
          <EmissionsReductionKpi title={'VIAGENS DE CARRO'} value={emissionReduction.carTravels} valueSuffix={emissionReduction.carTravelsSuffix} img={'/media/i-car.png'} />
          <GiaSpacer/>
          <EmissionsReductionKpi title={'VIAGENS DE ÔNIBUS'} value={emissionReduction.busTravels} valueSuffix={emissionReduction.busTravelsSuffix} img={'/media/i-bus.png'} />
          <GiaSpacer/>
          <EmissionsReductionKpi title={'VIAGENS DE AVIÃO'} value={emissionReduction.airplaneTravels} valueSuffix={emissionReduction.airplaneTravelsSuffix} img={'/media/i-airplane.png'} />
        </div>
      </div>
    }
  </div>
);

export default EmissionsReduction;
