import React, {FC} from "react";
import Skeleton from "react-loading-skeleton";

const PlantManagerSkeleton: FC = () => {

  const fieldHeight = 32;
  const fieldWidth = 450;

  return (
    <>
      <Skeleton height={40} width={100}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>
      <Skeleton height={fieldHeight} width={fieldWidth}></Skeleton>

      <div style={{display:'inline-flex'}}>
        <Skeleton height={fieldHeight} width={80} style={{marginRight:'8px'}}></Skeleton>
        <Skeleton height={fieldHeight} width={80} style={{marginRight:'8px'}}></Skeleton>
        <Skeleton height={fieldHeight} width={80} style={{marginRight:'8px'}}></Skeleton>
      </div>
    </>
  );
};

export default PlantManagerSkeleton;
