import React, {FC, useContext, useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import styles from "./navbar.module.scss";
import {toAbsoluteUrl} from "../../../shared/helpers/AssetHelpers";
import UserService from "../../../modules/auth/UserService";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {UserPictureContext} from "../../../shared/contexts/UserPictureContext";

interface NavbarProps { }

const Navbar: FC<NavbarProps> = () => {
  const [currentUser, setCurrentUser] = useState({
    name: UserService.getName(),
  });

  const { pictureContext, setPictureContext } = useContext(UserPictureContext);

  const [hover, setHover] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    UserService.getUserInfo().then((response: any) => {
      const userInfo = response.data;
      const newCurrentUser = {
        ...currentUser,
      };
      setPictureContext(userInfo.picture);
      setCurrentUser(newCurrentUser);
    });
  }, [setCurrentUser]);

  const doLogout = () => UserService.doLogout();

  return (
    <div className={`${styles.Navbar} h-100`} data-testid="Navbar">
      <div className="container-fluid g-0 pt-3 pb-3 h-100">
        <div className="row g-0 ps-4 pe-4 h-100 justify-content-between">
          <div className={`${styles.title} col-auto align-self-center`}>
            Gestão Integrada de Ativos
          </div>
          <div className="col-auto h-100 align-self-end">
            <div className="container h-100">
              <div className="row g-0 h-100 align-items-center">
                <div className="col col-auto h-100 d-flex align-items-center">
                  <div
                    className={`${styles.photo} ${!pictureContext ? styles.blankPicture : ""}`}
                    style={pictureContext ? { backgroundImage: `url("${pictureContext}")` } : {}}>
                  </div>
                </div>
                <div className="col col-auto h-100 d-flex align-items-start">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic" className={styles.logoutDropdown}>
                      {currentUser.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onMouseEnter={() => setHover(!hover)}
                        onMouseLeave={() => setHover(!hover)}
                        onClick={() => navigate("/user-configuration")}>
                        {!hover ? (
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.42857 8C4.84675 8 6.06383 9.00455 6.58552 10.437L6.66205 10.6667H14.8571C15.4883 10.6667 16 11.2636 16 12C16 12.6838 15.5588 13.2473 14.9904 13.3244L14.8571 13.3333H6.66205C6.19138 14.8869 4.92139 16 3.42857 16C1.53503 16 0 14.2092 0 12C0 9.79087 1.53503 8 3.42857 8ZM3.42857 10.6667C2.79739 10.6667 2.28571 11.2636 2.28571 12C2.28571 12.7364 2.79739 13.3333 3.42857 13.3333C4.05975 13.3333 4.57143 12.7364 4.57143 12C4.57143 11.2636 4.05975 10.6667 3.42857 10.6667ZM12.5714 0C14.465 0 16 1.79087 16 4C16 6.20913 14.465 8 12.5714 8C11.1533 8 9.93618 6.99545 9.41448 5.56298L9.33795 5.33333H1.14286C0.51168 5.33333 0 4.73637 0 4C0 3.31622 0.441193 2.75266 1.00958 2.67564L1.14286 2.66667H9.33795C9.80862 1.11308 11.0786 0 12.5714 0ZM12.5714 2.66667C11.9402 2.66667 11.4286 3.26363 11.4286 4C11.4286 4.73637 11.9402 5.33333 12.5714 5.33333C13.2026 5.33333 13.7143 4.73637 13.7143 4C13.7143 3.26363 13.2026 2.66667 12.5714 2.66667Z" fill="#3C4043" />
                          </svg>
                        ) : (
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.42857 8C4.84675 8 6.06383 9.00455 6.58552 10.437L6.66205 10.6667H14.8571C15.4883 10.6667 16 11.2636 16 12C16 12.6838 15.5588 13.2473 14.9904 13.3244L14.8571 13.3333H6.66205C6.19138 14.8869 4.92139 16 3.42857 16C1.53503 16 0 14.2092 0 12C0 9.79087 1.53503 8 3.42857 8ZM3.42857 10.6667C2.79739 10.6667 2.28571 11.2636 2.28571 12C2.28571 12.7364 2.79739 13.3333 3.42857 13.3333C4.05975 13.3333 4.57143 12.7364 4.57143 12C4.57143 11.2636 4.05975 10.6667 3.42857 10.6667ZM12.5714 0C14.465 0 16 1.79087 16 4C16 6.20913 14.465 8 12.5714 8C11.1533 8 9.93618 6.99545 9.41448 5.56298L9.33795 5.33333H1.14286C0.51168 5.33333 0 4.73637 0 4C0 3.31622 0.441193 2.75266 1.00958 2.67564L1.14286 2.66667H9.33795C9.80862 1.11308 11.0786 0 12.5714 0ZM12.5714 2.66667C11.9402 2.66667 11.4286 3.26363 11.4286 4C11.4286 4.73637 11.9402 5.33333 12.5714 5.33333C13.2026 5.33333 13.7143 4.73637 13.7143 4C13.7143 3.26363 13.2026 2.66667 12.5714 2.66667Z" fill="#1A73E8" />
                          </svg>
                        )}
                        Preferências
                      </Dropdown.Item>
                      <Dropdown.Item onClick={doLogout}>
                        <FontAwesomeIcon icon={faPowerOff}></FontAwesomeIcon>
                        Sair
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col col-auto d-none d-lg-flex align-items-center border-start ps-3 col-logo-2">
                  <a className="about" href="https://www.solagora.com.br/" target="_blank" rel="noreferrer">
                    <img className={styles.solagora} src={toAbsoluteUrl("/media/logo_sol_agora.svg")} alt={"Logo"} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
