import Skeleton from "react-loading-skeleton";
import styles from "./robbu-scheduler-group-edit.module.scss";

const RobbuGroupSkeleton = () => {

return(
    <div className={`${styles.skeletonContainer}`}>
        <Skeleton className="mt-4" height={12} width={50}/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={30}/>

        <Skeleton height={12} width={60} className="mt-4"/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={140}/>

        <Skeleton className="mt-4 mb-2" height={12} width={50}/>        
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-2" height={105}/>

        <div className={`d-flex align-items-center row mt-2`}>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
        </div>
    </div>
);
}

export default RobbuGroupSkeleton;