import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './user.module.scss';
import {toast} from 'react-toastify';
import {GiaTextField} from '../../../components/gia-forms-components/gia-forms-components';
import {useForm} from 'react-hook-form';
import {Button, Col, Form, Row} from 'react-bootstrap';
import RoleInterface from '../../../shared/interfaces/role-interface';
import {useNavigate, useParams} from 'react-router-dom';
import UserFormSkeleton from './userSkeleton';
import {userFormSchema, UserFormSchemaType} from '../schemas';
import {zodResolver} from "@hookform/resolvers/zod";
import {createUser, deleteUserById, getUserById, updateUser} from '../_requests';
import UserInterface from '../../../shared/interfaces/user.interface';
import {getAllRoles} from '../../../shared/services/role.service';
import {GroupInterface} from '../../../shared/interfaces/group-interface';
import {getAllGroups} from '../../groups/_requests';
import {defaultToastOptions} from '../../../shared/toast/toast-default.options';
import { roleTranslations } from '../../../modules/auth/roles';

interface UserProps { };
interface UserData extends UserInterface { 
};

interface UserFormProps{
    user: UserData;
    roles: RoleInterface[];
    groups: GroupInterface[];
    isLoading: boolean | undefined;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isEdit: boolean;
}

const User:FC<UserProps> = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<UserData>({
        id: undefined,
        email: '',
        firstName: '',
        lastName: '',
        active: 0,
        roles: [],
        groups: []
      });
    const [roles, setRoles] = useState<RoleInterface[]>([]);
    const [groups, setGroups] = useState<GroupInterface[]>([]);
    const isEdit:boolean = id ? true : false;

    useEffect(() => {
      setIsLoading(true);
      const fetchUserData = async () => {
        try {
          let { data }:{ data:UserData } = await getUserById(String(id));
          const rolesSorted = data?.roles?.sort((a, b) => a.name.localeCompare(b.name));
          const groupsSorted = data?.groups?.sort((a, b) => a.name.localeCompare(b.name));         
          setUser( { ...data, roles: rolesSorted, groups: groupsSorted } );
        } catch (error) {
          console.log("Erro ao obter dados do Usuário: " + error);
          navigate('/manage-users');
        }
      };
    
      const fetchRolesData = async () => {
        try {
          const { data }: { data:RoleInterface[] } = await getAllRoles();
          setRoles(data);
        } catch (error) {
          console.log("Erro ao obter dados das Funções: " + error);
          navigate('/manage-users');
        }
      };

      const fetchGroupsData = async () => {
        try {
          const { data }: { data: GroupInterface[] }  = await getAllGroups();
          setGroups(data);
        } catch (error) {
          console.log("Erro ao obter dados dos Grupos: " + error);
          navigate('/manage-users');
        }
      };

      fetchRolesData();
      fetchGroupsData();

      if(id){
        fetchUserData();
      }else{
        setIsLoading(false);
      }
    },[id, navigate]);

    return (
            <div className={`${styles.userContainer} container-fluid `}>
                    <div className="gia-page-title-default">Usuários</div>
                    <div className={`${styles.formContainer} row d-flex flex-column align-items-center`}>
                   <UserForm user={user} roles={roles} groups={groups} setIsLoading={setIsLoading} isLoading={isLoading} isEdit={isEdit}/>
                </div>
            </div>
    );
}

//TODO move UserForm to User component, why it is separated?
const UserForm: FC<UserFormProps> = ({ user, roles, groups, setIsLoading, isLoading, isEdit }) => {

    const { register, handleSubmit, setValue, formState: { errors }, clearErrors, reset, getValues } = useForm<UserFormSchemaType>({
      mode: 'all',
      resolver: zodResolver(userFormSchema)
    });
    const navigate = useNavigate();
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
      reset(user);
      setIsLoading(false);
    }, [user, reset, setIsLoading]);

    const addGroup = () => {
      const groupsFromForm = getValues().groups;
      const newGroup = {
        id: undefined,
        name: undefined,
        description: undefined,
      } as any;
      groupsFromForm.push(newGroup);
      setValue('groups', groupsFromForm);
      clearErrors('groups');
      forceUpdate();
    };

    const deleteGroup = (group:GroupInterface) => {
      const groupsFromForm = getValues().groups.filter(g => g.id !== group.id);
      setValue('groups', groupsFromForm);
      forceUpdate();
     };

    const handleGroupChange = (index: number, value: string) => {
      const group = groups.filter(group => group.id === +value);
      const groupsFromForm = getValues().groups;
      groupsFromForm[index] = { id: group[0].id, name: group[0].name, description: group[0]?.description };
      setValue('groups', groupsFromForm);
      clearErrors(`groups.${index}`);
    };

    //TODO remove async, use promise and isSubmitting from form
    const saveData = async (formData: UserFormSchemaType) => {

      //TODO create method to normalizeToSave and abstract this code
      const data:UserData = {
        id: user?.id,
        email: formData.email,
        username: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        active: formData.active ? 1 : 0,
        roles: formData.roles,
        groups: formData.groups
      }

      const successMessage = `Usuário ${isEdit ? 'atualizado' : 'criado'} com sucesso`;
      const request = (isEdit) ? updateUser(data) : createUser(data);

      try{
          const { data:responseData } = await request;
          toast.success(successMessage, defaultToastOptions);
          if(!isEdit){
            navigate(`/manage-users/user/${responseData.id}`);
          }
        }catch(error){
          toast.error("Não foi possível salvar o usuário. \n"
              + "Por favor tente novamente.");
        }
    };


    const deleteUser = async () => {
      //TODO make this a modal
      const confirm = window.confirm('Confirma excluir o usuário? Esta ação não pode ser desfeita.');
      if(confirm) {
        try {
          await deleteUserById(String(user.id));
          toast.success("Usuário excluido com sucesso.", defaultToastOptions);
          backToManageList();
        } catch (error) {
          console.log("Erro ao excluir o Usuário: " + error);
          toast.error("Não foi possível excluir o usuário. \n"
            + "Por favor tente novamente.", defaultToastOptions);
        }
      }
    };

    const backToManageList = () => {
      navigate('/manage-users');
    }
  
    return (
      <>
        {isLoading && <UserFormSkeleton/>}
        {!isLoading && 
        <form className={`${styles.form} d-flex flex-column gia-form`} onSubmit={handleSubmit(saveData)}>
          <GiaTextField 
            title="Email" 
            type="email" 
            autocomplete='email' 
            registerName={register("email")} 
            placeholder="nome.sobrenome@email.com" 
            required={true}
            isInvalid={!!errors.email}
            isInvalidText={errors?.email?.message}
            max={50} 
            className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-4" />

          <GiaTextField 
            title="Nome"
            type="text"
            autocomplete='given-name'
            registerName={register("firstName")} 
            placeholder="Nome" 
            required={true}
            max={20} 
            isInvalid={!!errors.firstName}
            isInvalidText={errors?.firstName?.message}
            className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" />

          <GiaTextField title="Sobrenome" 
            type="text" 
            autocomplete='family-name' 
            registerName={register("lastName")} 
            placeholder="Sobrenome" 
            max={20} 
            required={true}
            isInvalid={!!errors.lastName}
            isInvalidText={errors?.lastName?.message}
            className="col-lg-5 col-sm-12 col-xs-12 col-md-5 mb-4" />

          <label htmlFor="Ativo" className='label required'>Situação</label>
          <div className={`${styles.checkContainer} col-lg-5 col-sm-12 col-xs-12 col-md-5 mb-4`}>
            <Form.Check
              type='checkbox'
              id="Ativo"
              label='Ativo'
              {...register('active')} 
              isInvalid={!!errors.active}
              value={1}
            />
          </div>

          <p className='label mb-1 required'>GRUPOS</p>
          {getValues().groups?.length < 1 ? <div className="gia-message-warning mt-2">Não há grupos</div> : false}

          {errors.groups && <p className={`${styles.inputError} gia-message-error`}>{errors.groups.message}</p>}

          {getValues().groups?.length > 0 ?  <span className='label mb-2'>Grupo</span> : false}
          <div className='col-lg-6 col-sm-12 col-xs-12 col-md-5'>
              {getValues().groups?.map((userGroup, index) => (
              <div key={index} className='mb-3'>
                <div className='d-flex align-items-center col-lg-12 col-sm-12 col-xs-12 col-md-12'>
                    <div className='col-10 col-lg-10 col-sm-10 col-xs-10 col-md-10'>
                    <Form.Select
                      onChange={(event) => handleGroupChange(index, event.target.value)}
                      value={userGroup.id || "Selecione o Grupo"}
                      id={`Grupo-${index}`}
                      isInvalid={!!errors.groups?.[index]}
                    >
                      <option disabled>Selecione o Grupo</option>
                      {groups?.map((group, i) => {
                        return <option disabled={getValues().groups.some(g => g.name === group.name)} key={i} value={group.id}>{group.name}</option>
                      })}
                    </Form.Select>
                  </div>
                  <Button size='sm' className={`${styles.delete}`} variant='outline-light' onClick={() => deleteGroup(userGroup)}>Remover</Button>
                </div>
              </div>
              ))}
          </div>
          <div>
            <Button size='sm' type="button" className='mb-2' variant='outline-light' onClick={() => addGroup()}>Adicionar {getValues().groups?.length > 0 ? 'outro' : ''} Grupo</Button>
          </div>

          <p className='label mt-4 mb-1 required'>FUNÇÕES</p>
          {errors.roles && <p className={`${styles.inputError} gia-message-error`}>{errors.roles.message}</p>}

          <div className={`${styles.rolesContainer} col-lg-5 col-sm-12 col-xs-12 col-md-5 mb-4`}>
          {roles?.map((userRole) => (
            <Form.Check
              key={userRole.id}
              className={`${styles.roleCheck}`}
              type='checkbox'
              id={`user-role-${userRole.id}`}
              label={roleTranslations[userRole.name] || userRole.name}
              checked={getValues().roles?.some(r => r.id === userRole.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setValue('roles', [...getValues('roles'), { id: userRole.id, name: userRole.name }]);
                  clearErrors(`roles`);
                  forceUpdate();
                } else {
                  setValue('roles', getValues().roles.filter(r => r.id !== userRole.id));
                  forceUpdate();
                }
              }}
            />
          ))}
        </div>

          <div className="row-buttons-wrapper mt-4">
            <Row>
              <Col>
                <Button type="submit" size="sm">Salvar</Button>
                <Button type="button" variant="secondary" onClick={backToManageList} size="sm">Voltar</Button>
                {isEdit && <Button type="button" variant="danger" onClick={deleteUser} size="sm">Excluir</Button>}
              </Col>
            </Row>
          </div>
        </form>
        }
      </>
    );
  }

export default User;
