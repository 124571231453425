import React, {FC, useEffect, useMemo, useState} from 'react';
import styles from './scheduler-message.module.scss';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import SchedulerMessageFormSkeleton from './schedulerMessageSkeleton';
import {zodResolver} from "@hookform/resolvers/zod";
import {schedulerMessageFormSchema, SchedulerMessageFormSchemaType} from "../schemas";
import {Button, Col, Form, Row} from "react-bootstrap";
import {GiaTextField} from "../../../components/gia-forms-components/gia-forms-components";
import {
    createSchedulerMessage, deleteSchedulerMessageById,
    getSchedulerGroups,
    getSchedulerMessageById,
    getSchedulerMessagePersonaTypes,
    getSchedulerMessageTemplates, patchSchedulerMessage
} from "../_requests";
import {SchedulerMessageInterface} from "../../../shared/interfaces/scheduler-message-interface";
import {SchedulerMessageTemplateInterface} from "../../../shared/interfaces/scheduler-message-template-interface";
import {
    SchedulerMessagePersonaTypeInterface
} from "../../../shared/interfaces/scheduler-message-persona-type-interface";
import {SchedulerGroupInterface} from "../../../shared/interfaces/scheduler-group-interface";
import {toast} from "react-toastify";
import {
    SchedulerMessageChannelTypeInterface
} from "../../../shared/interfaces/scheduler-message-channel-type-interface";
import {Cron} from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

interface SchedulerMessageProps {
}

const SchedulerMessage: FC<SchedulerMessageProps> = () => {
    const {schedulerMessageId} = useParams();
    const [schedulerMessage, setSchedulerMessage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isEdit = useMemo<boolean>((): boolean => !!schedulerMessageId, [schedulerMessageId]);
    const [plantGroups, setPlantGroups] = useState<SchedulerGroupInterface[]>([]);
    const [templates, setTemplates] = useState<SchedulerMessageTemplateInterface[]>([]);
    const [personaTypes, setPersonaTypes] = useState<SchedulerMessagePersonaTypeInterface[]>([]);
    const [channelTypes, setChannelTypes] = useState<SchedulerMessageChannelTypeInterface[]>([]);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [selectedChannel, setSelectedChannel] = useState('');
    const {
        register,
        getValues,
        setValue,
        reset,
        handleSubmit,
        watch,
        formState: {errors, isSubmitting}
    } = useForm<SchedulerMessageFormSchemaType>({
        mode: 'all',
        resolver: zodResolver(schedulerMessageFormSchema)
    });

    const channelValue = watch('channel');

    useEffect(() => {
        const fetchSupportData = async () => {
            await getSchedulerGroups().then(r => r.data)
                .then(response => setPlantGroups(response));
            await getSchedulerMessageTemplates().then(r => r.data)
                .then(response => setTemplates(response));
            await getSchedulerMessagePersonaTypes().then(r => r.data)
                .then(response => setPersonaTypes(response));
        };
        setIsLoading(true);
        fetchSupportData().then(() => {
            setChannelTypes([
                {channel: 'Email'},
                {channel: 'SMS'},
                {channel: 'Whatsapp'}
            ]);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.error(error);
        }).finally(() => {
            if (!schedulerMessageId) {
                setSchedulerMessage({
                    id: null,
                    active: false,
                    cronExpression: "",
                    dataFrom: "",
                    lastProcessDate: "",
                    messageBody: "",
                    messageSubject: "",
                    name: "",
                    plantIdField: "",
                    personaField: "",
                    powerField: "",
                    template: "",
                    groups: [],
                    channel: ""
                })
                return;
            }
            const fetchData = async () => {
                await getSchedulerMessageById(schedulerMessageId).then(r => r.data)
                    .then(response => {
                        setSchedulerMessage(response);
                    });
            }
            setIsLoading(true);
            fetchData().then(() => setIsLoading(false)).catch(error => {
                setIsLoading(false);
                console.error(error);
            });
        });
    }, [schedulerMessageId]);

    // Reset/fill form when load credential data
    useEffect(() => {
        if (schedulerMessage) {
            reset(schedulerMessage);
        }
    }, [schedulerMessage, reset]);

    const normalizeToSave = (formData: any): SchedulerMessageInterface => {
        return {
            id: formData.id,
            active: formData.active,
            cronExpression: formData.cronExpression,
            dataFrom: formData.dataFrom,
            lastProcessDate: formData.lastProcessDate,
            messageBody: formData.messageBody,
            messageSubject: formData.messageSubject,
            name: formData.name,
            plantIdField: formData.plantIdField,
            personaField: formData.personaField,
            powerField: formData.powerField,
            template: formData.template,
            groups: formData.groups,
            channel: formData.channel
        };
    };

    const onSubmit: SubmitHandler<SchedulerMessageFormSchemaType> = (data) => {

        const payload = normalizeToSave(data);

        let formSubmit = isEdit ? patchSchedulerMessage(payload) : createSchedulerMessage(payload);

        return formSubmit.then(response => {
            toast.success('Agendamento salvo com sucesso!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: "light",
            });
            if (response.data.id) {
                navigate(`/manage-scheduler-messages/scheduler-message/${response.data.id}`);
            }
        }).catch(() => {
            toast.error('Houve um problema ao salvar os dados. Por favor tente novamente.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: "light",
            });
        });
    };

    const cancel = () => {
        navigate(`/manage-scheduler-messages`);
    };

    const deleteScheduler = () => {
        const confirm = window.confirm('Confirma excluir o agendamento? Esta ação não pode ser desfeita.');
        if (confirm && schedulerMessageId) {

            let formSubmit = deleteSchedulerMessageById(schedulerMessageId)

            formSubmit.then(() => {
                toast.success('Agendamento excluido com sucesso!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    theme: "light",
                });
                navigate(`/manage-scheduler-messages`);
            }).catch(() => {
                toast.error('Houve um problema ao excluir o agendamento. Por favor tente novamente.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    theme: "light",
                });
            });
        }
    };

    const removeGroup = (group: any) => {
        const values = getValues();
        let groupsFormValues = values.groups;
        const groupsForm = groupsFormValues
            .filter(d => d.robbuSchedulerMessageGroupIdDTO.robbuSchedulerGroupId
                !== group.robbuSchedulerMessageGroupIdDTO.robbuSchedulerGroupId);
        setValue('groups', groupsForm);
        forceUpdate();
    };

    const addGroup = () => {
        const values = getValues();
        let groupsFromForm = values.groups;

        const newGroup = {
            robbuSchedulerMessageGroupIdDTO: {
                robbuSchedulerMessageId: schedulerMessageId ? Number(schedulerMessageId) : 0,
                robbuSchedulerGroupId: undefined
            }
        } as any;

        groupsFromForm.push(newGroup);
        setValue('groups', groupsFromForm);
        forceUpdate();
    };

    useEffect(() => {
        if (channelValue) {
            setSelectedChannel(channelValue);
        }
    }, [channelValue]);

    return (
        <div className={`${styles.schedulerMessageContainer} container-fluid `}>
            <div className="header">Agendamento</div>
            <div className={`row d-flex flex-column align-items-center`}>
                {isLoading && <SchedulerMessageFormSkeleton/>}
                {!isLoading &&
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="gia-form"
                          onKeyDown={(e) => {
                              e.key === 'Enter' && e.preventDefault(); }}>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <GiaTextField
                                    title="Nome"
                                    placeholder="Nome"
                                    type="text"
                                    as='input'
                                    required={true}
                                    autocomplete="one-time-code"
                                    isInvalid={!!errors.name}
                                    isInvalidText={errors?.name?.message}
                                    registerName={{...register('name')}}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <GiaTextField
                                    title="Expressão Cron"
                                    placeholder="*/5 * * * *"
                                    type="text"
                                    as='input'
                                    required={true}
                                    autocomplete="one-time-code"
                                    isInvalid={!!errors.cronExpression}
                                    isInvalidText={errors?.cronExpression?.message}
                                    registerName={{...register('cronExpression')}}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Cron value={getValues().cronExpression}
                                      humanizeLabels={true}
                                      humanizeValue={true}
                                      leadingZero={true}
                                      clockFormat={'24-hour-clock'}
                                      setValue={value => {
                                          setValue('cronExpression', value);
                                      }}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="3">
                                <div className="label required">Canal de envio</div>
                                <Form.Select
                                    title="Selecione"
                                    isInvalid={!!errors.channel}
                                    required={true}
                                    {...register('channel')}>
                                    {channelTypes.map((option, i) => {
                                        return <option key={i}
                                                       value={option.channel}>{option.channel}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} md="3">
                                <div className="label required">Situação</div>
                                <Form.Check
                                    id="active-checkbox"
                                    label="Ativo"
                                    isInvalid={!!errors.active}
                                    value={1}
                                    style={{minHeight: '1.8rem'}}
                                    {...register('active')}></Form.Check>
                                <div className="border-bottom mb-3"></div>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <GiaTextField
                                    title="Origem dos dados"
                                    placeholder=""
                                    type="text"
                                    as='textarea'
                                    required={true}
                                    autocomplete="one-time-code"
                                    isInvalid={!!errors.dataFrom}
                                    isInvalidText={errors?.dataFrom?.message}
                                    registerName={{...register('dataFrom')}}/>
                            </Form.Group>
                        </Row>

                        {selectedChannel === 'Email' && (
                            <Row>
                                <Form.Group as={Col} md="6">
                                    <GiaTextField
                                        title="Assunto da Mensagem"
                                        placeholder="Assunto"
                                        type="text"
                                        as='input'
                                        required={true}
                                        autocomplete="one-time-code"
                                        isInvalid={!!errors.messageSubject}
                                        isInvalidText={errors?.messageSubject?.message}
                                        registerName={{...register('messageSubject')}}/>
                                </Form.Group>
                            </Row>
                        )}

                        {(selectedChannel === 'Email' || selectedChannel === 'SMS') && (
                            <Row>
                                <Form.Group as={Col} md="6">
                                    <GiaTextField
                                        title="Mensagem"
                                        placeholder="Corpo da mensagem"
                                        type="text"
                                        as='textarea'
                                        required={true}
                                        autocomplete="one-time-code"
                                        isInvalid={!!errors.messageBody}
                                        isInvalidText={errors?.messageBody?.message}
                                        registerName={{...register('messageBody')}}/>
                                </Form.Group>
                            </Row>
                        )}

                        <Row>
                            <Form.Group as={Col} md="3">
                                <GiaTextField
                                    title="Identificação da usina"
                                    placeholder="identificação"
                                    type="text"
                                    as='input'
                                    required={true}
                                    autocomplete="one-time-code"
                                    isInvalid={!!errors.plantIdField}
                                    isInvalidText={errors?.plantIdField?.message}
                                    registerName={{...register('plantIdField')}}/>
                            </Form.Group>

                            <Form.Group as={Col} md="3">
                                <GiaTextField
                                    title="Identificação do campo de energia"
                                    placeholder="identificação"
                                    type="text"
                                    as='input'
                                    autocomplete="one-time-code"
                                    isInvalid={!!errors.powerField}
                                    isInvalidText={errors?.powerField?.message}
                                    registerName={{...register('powerField')}}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            {selectedChannel === 'Whatsapp' && (
                                <Form.Group as={Col} md="3">
                                    <div className="label required">Modelo de mensagem</div>
                                    <Form.Select
                                        title="Selecione"
                                        isInvalid={!!errors.template}
                                        required={true}
                                        {...register('template')}>
                                        {templates.map((option, i) => {
                                            return <option key={i}
                                                           value={option.template}>{option.template}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            )}

                            <Form.Group as={Col} md="3">
                                <div className="label required">Identificação do tipo de pessoa para o envio</div>
                                <Form.Select
                                    title="Selecione"
                                    isInvalid={!!errors.personaField}
                                    required={true}
                                    {...register('personaField')}>
                                    {personaTypes.map((option, i) => {
                                        return <option key={i}
                                                       value={option.personaType}>{option.personaType}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <div className="label-groups">Grupos de Usinas</div>

                        <Row className="mb-1">
                            {getValues().groups && getValues().groups.length > 0 &&
                                <>
                                    <Col md="2">
                                        <div className="label" title="Grupo">Grupo</div>
                                    </Col>
                                </>
                            }
                            {(!getValues().groups || !getValues().groups?.length) &&
                                <>
                                    <div className="gia-message-warning">Não há grupos</div>
                                </>
                            }
                            {errors?.groups && <div className="gia-message-error">{errors?.groups?.message}</div>}
                        </Row>

                        {getValues().groups && getValues().groups?.map((group: any, i) => (
                            <Row key={i}>
                                <Form.Group as={Col} md="2">
                                    <Form.Select
                                        title="Grupo"
                                        isInvalid={!!errors.groups?.[i]?.robbuSchedulerMessageGroupIdDTO?.robbuSchedulerGroupId}
                                        {...register(`groups.${i}.robbuSchedulerMessageGroupIdDTO.robbuSchedulerGroupId`,
                                            {valueAsNumber: true})}>
                                        {plantGroups.map((option, i) => {
                                            return <option key={i} value={option.id}>{option.name}</option>
                                        })}
                                    </Form.Select>
                                    <div className="gia-form-feedback-message"></div>
                                </Form.Group>
                                <Col md="2">
                                    <Button variant="outline-light" size="sm"
                                            onClick={() => removeGroup(group)}>Remover</Button>
                                </Col>
                            </Row>
                        ))}

                        <Row>
                            <Col md={4}>
                                <Button type="button" size="sm" variant="outline-light" onClick={addGroup}>
                                    Adicionar {(getValues().groups && getValues().groups?.length > 0) ? 'Outro' : ''} Grupo
                                </Button>
                            </Col>
                        </Row>

                        <div className="row-buttons-wrapper" style={{marginTop: "20px"}}>
                            <Row>
                                <Col>
                                    <Button type="submit" size="sm">Salvar</Button>
                                    <Button type="button" variant="secondary" onClick={cancel} size="sm"
                                            disabled={isSubmitting}>Voltar</Button>
                                    {schedulerMessageId &&
                                        <Button type="button" variant="danger" onClick={deleteScheduler}
                                                size="sm" disabled={isSubmitting}>Excluir</Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Form>
                }
            </div>
        </div>
    );
};

export default SchedulerMessage;
