import React, {FC, useState} from 'react';
import styles from './dashboards.module.scss';

interface DashboardsProps {
}

const Dashboards: FC<DashboardsProps> = () => {

  const dashboardIframeUrl = `${process.env.REACT_APP_DASHBOARD_URL}/dashboards?kiosk=tv`;
  const [iframeAuthLoaded, setIframeAuthLoaded] = useState(false);

  const onLoadIframe = () => {
    if(!iframeAuthLoaded) {
      setIframeAuthLoaded(true);
    }
    const iframeContent = document.querySelector("#dashboard-iframe") as any;
    const doc = iframeContent.contentWindow.document;
    const setStyle = () => {
      doc.querySelector('body').style.backgroundColor = 'white';
      const divScrollbarView = doc.querySelector('div.scrollbar-view');
      if(divScrollbarView) {
        divScrollbarView.style.backgroundColor = 'white';
      }
      const footer = doc.querySelector("footer.footer");
      if(footer) {
        footer.style = 'display: none';
      }
    };
    iframeContent.contentDocument.addEventListener('click keydown', function () {
      setStyle();
    });
    setStyle();
  };

  return (
    <div className={styles.Dashboards} data-testid="Dashboards">
      {dashboardIframeUrl && <iframe className={styles.Iframe} src={dashboardIframeUrl} onLoad={onLoadIframe} id="dashboard-iframe" title="Dashboards"></iframe>}
    </div>
  );
};

export default Dashboards;
