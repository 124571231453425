import axios, { AxiosResponse } from "axios";
import UserInterface from "../../shared/interfaces/user.interface";

export function getUserById(id:string):Promise<AxiosResponse<any, any>>{
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/user/${id}`;
    return axios.get(url);
}

export function createUser(data:UserInterface):Promise<AxiosResponse<any, any>>{
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/user`;
  return axios.post(url, data);
}

export function updateUser(data:UserInterface):Promise<AxiosResponse<any, any>>{
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/user`;
  return axios.put(url, data);
}

export function deleteUserById(id:string):Promise<AxiosResponse<any, any>>{
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/user/${id}`;
  return axios.delete(url);
}