import React, { FC, useState } from "react";
import { Outlet } from "react-router";
import Header from "../components/layout/header/header";
import { Menu } from "../components/layout/menu/menu";
import Footer from "../components/layout/footer/footer";
import { ToastContainer } from "react-toastify";
import { UserPictureContext } from "../shared/contexts/UserPictureContext";

interface MasterLayoutProps { }
const MasterLayout: FC<MasterLayoutProps> = () => {
  const [pictureContext, setPictureContext] = useState<string>("");

  return (
    <UserPictureContext.Provider value={{ pictureContext, setPictureContext }}>
      <div className="main-wrapper">
        <div className="header-wrapper fixed-top">
          <Header />
        </div>
        <div className="container-fluid g-0 d-flex flex-column master-layout">
          <div className="row g-0">
            <div className="col-2">
              <Menu />
            </div>

            <div className="col-10">
              <div className="main-container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </UserPictureContext.Provider>
  );
};

export default MasterLayout;
