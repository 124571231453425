import {FC, useEffect, useState} from "react";
import {SchedulerMessageInterface} from "../../../shared/interfaces/scheduler-message-interface";
import {TableColumn} from "react-data-table-component";
import {useNavigate} from "react-router-dom";
import {filterByFields} from "../../../components/filter/filter";
import Skeleton from "react-loading-skeleton";
import styles from "./manage-scheduler-messages.module.scss"
import {Button, Form} from "react-bootstrap";
import SubHeaderComponent from "../../../components/subHeader/subHeader";
import CustomDatatable from "../../../components/custom-datatable/custom-datatable";
import {getAllSchedulerMessages} from "../_requests";

interface ManageSchedulerMessagesProps {}
interface DatatableRowInterface extends SchedulerMessageInterface {}

const convertResponse = (response:DatatableRowInterface[]) => {
    return response
      .map((r: DatatableRowInterface) => {
      return {
          id: r.id,
          active: r.active,
          cronExpression: r.cronExpression,
          dataFrom: r.dataFrom,
          lastProcessDate: r.lastProcessDate,
          messageBody: r.messageBody,
          messageSubject: r.messageSubject,
          name: r.name,
          plantIdField: r.plantIdField,
          personaField: r.personaField,
          powerField: r.powerField,
          template: r.template
      } as DatatableRowInterface;
    });
  }

const columns: TableColumn<DatatableRowInterface>[] = [
    {
        name: 'Nome',
        selector: row => row.name!,
        sortable: true,
    },
    {
        name: 'Expressão Cron',
        selector: row => row.cronExpression!,
        sortable: true,
    },
    {
        name: 'Ultimo Processamento',
        selector: row => row.lastProcessDate!,
        sortable: true,
    },
    {
        name: 'Situação',
        sortable: false,
        cell: (row) =>
            <Form.Check
                id={`Ativo ${row.id}`}
                disabled={true}
                type="checkbox"
                label='Ativo'
                checked={row.active}
            />
    }
];

const ManageSchedulerMessagesComponent:FC<ManageSchedulerMessagesProps> = () => {

    const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const navigate = useNavigate();

    const filteredItems: DatatableRowInterface[] = filterByFields(datasource, filterText, ['name', 'cronExpression', 'lastProcessDate']);

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const { data } = await getAllSchedulerMessages();
          setDatasource(convertResponse(data));
          setIsLoading(false);
        } catch (error) {
          //todo inform the user that an error has occurred
          console.error('Erro ao buscar dados:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData().then();
    },[]);

    const onRowClicked = (row:SchedulerMessageInterface) => {
      navigate(`scheduler-message/${row.id}`, {state: {row}});
    };

    return(
    <div className={styles.manageSchedulerMessageContainer} data-testid="SchedulerMessagesContainer">
      <div className="gia-page-title-default">Agendamentos</div>
      {isLoading && <Skeleton height={48} />}

      {isLoading && <Skeleton height={'50vh'} />}

      {!isLoading &&
      <>
      <div className="container-fluid g-0 mb-3">
        <div className="row">
          <div className="col-sm-12 d-flex">
            <Button type="button" variant="primary" size="sm" className="me-4" onClick={() => navigate('/manage-scheduler-messages/scheduler-message')}>Criar Agendamento</Button>
            {!isLoading && <SubHeaderComponent filterText={filterText} resetPaginationToggle={resetPaginationToggle} setResetPaginationToggle={setResetPaginationToggle} setFilterText={setFilterText}/>}
          </div>
        </div>
      </div>
      <CustomDatatable columns={columns}
                       filteredItems={filteredItems}
                       filterText={filterText}
                       setFilterText={setFilterText}
                       isLoading={isLoading}
                       selectableRows={false}
                       onRowClicked={onRowClicked}
                       resetPaginationToggle={resetPaginationToggle}
                       className="gia-datatable" />
      </>
      }
    </div>
    );
}

export default ManageSchedulerMessagesComponent;
