import React, {FC, useEffect, useState} from "react";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Button, Col, Form, Row} from "react-bootstrap";
import styles from './growatt-end-user-edit.module.scss';
import {useNavigate, useParams} from "react-router-dom";
import {deleteGrowattUserById, loadCredentials, loadGrowattUser, saveGrowattUser} from "./_requests";
import {toast} from 'react-toastify';
import {GiaTextField} from "../../../components/gia-forms-components/gia-forms-components";
import {GiaDatePicker} from "../../../components/gia-forms-components/gia-datepicker/gia-datepicker";
import {maskPhone} from "../../../shared/helpers/string-utils.helper";
import {defaultToastOptions} from "../../../shared/toast/toast-default.options";

interface GrowattEndUserEditProps {
}

const GrowattEndUserEdit: FC<GrowattEndUserEditProps> = () => {

  let { id } = useParams();

  // Define as empty char code to not generate auto message from zod
  const requiredErrorMessage = String.fromCharCode(0);
  const invalidNumberRule = { invalid_type_error: requiredErrorMessage };

  const formSchema = z.object({
    cuserId: z.number().positive().min(1),
    cuserName: z.string(invalidNumberRule).min(1, requiredErrorMessage).max(255),
    cuserEmail: z.union([z.string().email(), z.literal('')]).optional().nullable(),
    cuserTel: z.string(invalidNumberRule).max(255).optional().nullable(),
    cuserRegtime: z.union([z.date(), z.undefined(), z.string().length(0)]).optional().nullable(),
    credentialId: z.number().positive().min(1),
  });
  type FormSchemaType = z.infer<typeof formSchema>;

  const [, setIsLoading] = useState(false);
  const [, updateState] = React.useState({});
  const [credentials, setCredentials] = useState<any>([]);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm<FormSchemaType>({
    mode: 'all',
    resolver: zodResolver(formSchema)
  });

  useEffect(() => {
    const fetchSupportData = async () => {
      await loadCredentials()
        .then(r => r.data)
        .then(response => normalizeCredentials(response))
        .then(response => setCredentials(response));
    };
    setIsLoading(true);
    fetchSupportData().then(() => {
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.error(error);
    }).finally(() => {
      if(!id) {
        return;
      }
      const fetchData = async () => {
        await loadGrowattUser(id).then(response => {
          const user = response.data;
          reset(user);

          const cuserRegtime = getValues().cuserRegtime;
          if (cuserRegtime) {
            setValue('cuserRegtime', parseDate(cuserRegtime));
          }

          const cuserTel = getValues().cuserTel;
          if (cuserTel) {
            setValue("cuserTel", maskPhone(cuserTel));
          }
        })
      }
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false)).catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    });
  }, [getValues, id, reset, setValue]);

  const navigate = useNavigate();

  const normalizeCredentials = (credentials) => {
    return credentials.map(c => ({...c, description: `${c.groupName} - ${c.credentialType} - ${c.id}`}));
  }

  const parseDate: (date: any) => Date | null = (date) => {
    if (date && Date.parse(date.toString()) > 0) {
      return new Date(date);
    }
    return null;
  };

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    saveGrowattUser(data).then(response => {

      if (id !== response.data.cuserId) {
        id = response.data.cuserId;
        navigate(`/growatt-end-user/edit/${id}`);
      }

      toast.success('Usuário salvo com sucesso!', defaultToastOptions);

    }).catch(() => {
      toast.error('Houve um problema ao salvar os dados. Por favor tente novamente.', defaultToastOptions);
    });
  };

  const cancel = () => {
    navigate(`/growatt-end-user`);
  };

  const deleteUser = () => {
    const confirm = window.confirm('Confirma excluir o usuário ? Esta ação não pode ser desfeita.');
    if (confirm) {
      deleteGrowattUserById(id).then(() => {
        toast.success('Usuário excluido com sucesso!', defaultToastOptions);
        navigate(`/growatt-end-user`);
      }).catch(() => {
        toast.error('Houve um problema ao excluir o usuário. Por favor tente novamente.', defaultToastOptions);
      });
    }
  };



  return (
    <div className={styles.GrowattEndUserEdit}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="gia-form">
        <div className="gia-page-title-default header">
          Usuário Growatt
        </div>
        <div>
          <Row>
            <Form.Group as={Col} md="4">
              <GiaTextField
                title="Id Growatt"
                isInvalid={!!errors?.cuserId}
                isInvalidText={errors?.cuserId?.message}
                registerName={{ ...register('cuserId', { valueAsNumber: true }) }}></GiaTextField>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4">
              <GiaTextField
                title="Nome"
                isInvalid={!!errors.cuserName}
                isInvalidText={errors?.cuserName?.message}
                required={true}
                registerName={{ ...register('cuserName') }}></GiaTextField>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="4">
              <GiaTextField
                title="E-mail"
                isInvalid={!!errors.cuserEmail}
                isInvalidText={errors?.cuserEmail?.message}
                required={false}
                registerName={{ ...register('cuserEmail') }}></GiaTextField>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="4">
              <GiaTextField
                title="Telefone"
                isInvalid={!!errors.cuserTel}
                isInvalidText={errors?.cuserTel?.message}
                required={false}
                onChange={(e) => setValue("cuserTel", maskPhone(e.target.value))}
                registerName={{ ...register('cuserTel') }}></GiaTextField>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4">
              <GiaDatePicker
                register={{ ...register('cuserRegtime') }}
                title="Data de registro"
                selected={getValues().cuserRegtime}
                required={false}
                onChange={(date: Date) => { setValue('cuserRegtime', date); updateState({}); }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                timeCaption="time"
                className="form-control datepicker-container"
                dateFormat="dd/MM/yyyy HH:mm" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <div className="label required">Credential</div>
              <Form.Select
                title="Cliente"
                isInvalid={!!errors.credentialId}
                required={true}
                {...register('credentialId', {valueAsNumber: true})}>
                {!id && <option key={'select-a-option'} value={undefined}>Selecione...</option>}
                {credentials.map((option, i) => {
                  return <option key={i} value={option.id}>{option.description}</option>
                })}
              </Form.Select>
              <div className="gia-form-feedback-message"></div>
            </Form.Group>
          </Row>

        </div>
        <div className="row-buttons-wrapper buttons-container">
          <Row>
            <Col>
              <Button type="submit" size="sm">Salvar</Button>
              <Button type="button" variant="outline-light" onClick={cancel} size="sm">Voltar</Button>
              {Number(id) > 0 && <Button type="button" variant="danger" onClick={deleteUser} size="sm">Excluir</Button>}
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default GrowattEndUserEdit;
