import axios from 'axios';
import {calculateKpiPercentageColor, convertToKpiPercentage} from '../helpers/kpi.helper';
import {
  EnergyGeneratedAtPlantChartInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-chart.interface';
import {
  EnergyGeneratedAtPlantInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant.interface';
import {KpiColorType} from '../type/kpi-color.type';
import {ChartStatisticsInterface} from '../interfaces/chart-statistics-interface';
import {
  EnergyGeneratedAtPlantSummaryInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-summary.interface';
import {EmissionReductionInterface} from '../../components/emissions-reduction/interfaces/emission-reduction.interface';
import {convertDatetimePeriodForBackend} from '../helpers/conversion.utils';
import {DatetimePeriodSelectorType} from '../type/datetime-period-selector.type';

const convertResponseLastPeriods = (response) => {
  return {
    powerTodayPct: response.power_today_pct,
    powerYesterdayPct: response.power_yesterday_pct,
    powerD15Pct: response.power_d15_pct,
    powerD30Pct: response.power_d30_pct,
  };
}

const convertResponseEmissionsReduction = (response): EmissionReductionInterface => {
  return {
    co2: response?.e_reduction_co2_value,
    co2Suffix: response?.e_reduction_co2_suffix,
    plantedTrees: response?.e_reduction_planted_trees_value,
    plantedTreesSuffix: response?.e_reduction_planted_trees_suffix,
    carTravels: response?.e_reduction_car_travels_value,
    carTravelsSuffix: response?.e_reduction_car_travels_suffix,
    busTravels: response?.e_reduction_bus_travels_value,
    busTravelsSuffix: response?.e_reduction_bus_travels_suffix,
    airplaneTravels: response?.e_reduction_airplane_travels_value,
    airplaneTravelsSuffix: response?.e_reduction_airplane_travels_suffix
  } as EmissionReductionInterface;
}

export function convertChartResponse (response) {
  return response.map(r => {
    return {
      time: r.time,
      epvValue: r.epv_value,
      epvValueLast: r.epv_value_last,
      powerBy: r.power_by || r.power_by_day || 0,
    }
  });
};

export function handleChart (period: string, chartData: any): EnergyGeneratedAtPlantChartInterface {
  const chartDataUpdate = {} as EnergyGeneratedAtPlantChartInterface;

  let labels = [0];
  let valueList = [0];
  let accumulatedValueList = [0];
  let thresholdList = [0];
  let thresholdMaxValue = 0;

  if (chartData && chartData.length) {
    labels = chartData.map(v => v.time);
    valueList = chartData.map(v => v.epvValue);
    accumulatedValueList = chartData.map(v => v.epvValueLast);
    thresholdMaxValue = Math.max.apply(Math, chartData.map(v => v.powerBy));
    thresholdList = chartData.map(() => thresholdMaxValue);

    let total = valueList.reduce((a,b) => a + b, 0);
    let threshold = Math.max.apply(Math, thresholdList);
    let min = Math.min.apply(Math, valueList);
    let max = Math.max.apply(Math, valueList);
    let avg = total / valueList.length;

    chartDataUpdate.statistics = {
      min: {value: min, color: KpiColorType.primary, name: 'Mínimo', suffix: 'kWh'},
      max: {value: max, color: KpiColorType.primary, name: 'Máximo', suffix: 'kWh'},
      avg: {value: avg, color: KpiColorType.primary, name: 'Média', suffix: 'kWh'},
      total: {value: total, color: KpiColorType.primary, name: 'Total', suffix: 'kWh'},
      threshold: {value: threshold, color: KpiColorType.warning, name: 'Threshold', suffix: 'kWh'},
    } as ChartStatisticsInterface;
  }
  const datasets: any[] = [];

  chartDataUpdate.type = 'bar';

  if(period === DatetimePeriodSelectorType.today || period === DatetimePeriodSelectorType.yesterday) {
    chartDataUpdate.type = 'line';
    datasets.push({
      label: 'kWh Acumulado',
      data: accumulatedValueList,
      borderColor: '#1E6FE8',
      backgroundColor: '#1E6FE8',
      pointRadius: 2
    });

    datasets.push({
      label: 'Threshold',
      data: thresholdList,
      borderColor: '#FF9F40',
      backgroundColor: '#FF9F40',
      pointRadius: 0
    });
  }

  datasets.push({
    label: 'kWh',
    data: valueList,
    borderColor: '#78A6EC',
    backgroundColor: '#78A6EC',
    pointRadius: 2,
    borderDash: [5, 5],
    maxBarThickness: 50
  });

  chartDataUpdate.data = {labels: labels,datasets};

  return chartDataUpdate;
}

export function handleSummary (chart: EnergyGeneratedAtPlantChartInterface) {
  const powerGenerated = chart?.statistics?.total?.value || 0;
  const threshold = chart?.statistics?.threshold?.value || 0;

  const powerGoal = threshold;
  const powerPercentage = (powerGoal <= 0) ? 0 : powerGenerated / powerGoal * 100;
  const powerPercentageColor = calculateKpiPercentageColor(powerPercentage);

  const summary = {
    power: {value: powerGenerated, color: powerPercentageColor},
    percentage: {value: powerPercentage, color: powerPercentageColor},
    goal: {value: powerGoal, color: KpiColorType.success},
  } as EnergyGeneratedAtPlantSummaryInterface;

  return summary;
};

export function handleLastPeriodsPercentage(response) {
  const responseConverted = convertResponseLastPeriods(response);
  return {
    today: convertToKpiPercentage(responseConverted.powerTodayPct),
    yesterday: convertToKpiPercentage(responseConverted.powerYesterdayPct),
    last15Days: convertToKpiPercentage(responseConverted.powerD15Pct),
    lastMonth: convertToKpiPercentage(responseConverted.powerD30Pct)
  };
}

export function loadPowerByLastPeriods() {
  const urlPowerByLastPeriods = `${process.env.REACT_APP_BACKEND_API_URL}/overview`;
  return axios.get(urlPowerByLastPeriods).then(r => r.data);
}

export function loadEnergyGeneratedAtPlant(period: string) {
  const periodConverted = convertDatetimePeriodForBackend(period);
  const urlChart = `${process.env.REACT_APP_BACKEND_API_URL}/overview/data/${periodConverted}`;
  return axios.get<[]>(urlChart).then(response => {
    const convertedChartResponse = convertChartResponse(response.data);

    const chartUpdate = handleChart(period, convertedChartResponse) as EnergyGeneratedAtPlantChartInterface;
    const summaryUpdate = handleSummary(chartUpdate);

    return {
      summary: summaryUpdate,
      chart: chartUpdate
    } as EnergyGeneratedAtPlantInterface;
  });
}

export function loadEmissionsReduction(period: string) {
  const periodConverted = convertDatetimePeriodForBackend(period);
  const urlEmissionsReduction = `${process.env.REACT_APP_BACKEND_API_URL}/overview/emissions-reduction/${periodConverted}`;
  return axios.get<any[]>(urlEmissionsReduction).then(response => handleEmissionReduction(response?.data[0]));
}

export function handleEmissionReduction (response) {
  return convertResponseEmissionsReduction(response);
}
