import {KpiColorType} from '../type/kpi-color.type';
import {KpiValueInterface} from '../interfaces/kpi-value.interface';

/**
 * Function to convert a value to kpi percentage type
 * @param value
 */
export const convertToKpiPercentage = (value) => {
  return {
    value: value,
    color: calculateKpiPercentageColor(value)
  } as KpiValueInterface;
};

/**
 * Function to convert a value to kpi percentage type
 * @param value
 */
export const calculateKpiPercentageColor = (value) => {
  return value >= 90 ? KpiColorType.success : value >= 70 ? KpiColorType.warning : KpiColorType.danger;
};
