import React, {FC, useEffect, useMemo, useState} from 'react';
import styles from './growatt-end-user.module.scss';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import {Button, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import CSS from 'csstype';
import {maskPhone, removeDiacritics} from '../../shared/helpers/string-utils.helper';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
import 'moment/locale/pt-br';
import paginationComponentOptions from "../../shared/datatable/pagination-component.options";
import {useNavigate} from "react-router-dom";
import {GrowattEndUserProps} from './growatt-end-user-interface';

moment.locale('pt-br');

/* INTERFACES */
interface FilterComponentProps {
  onFilter: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  onClear: any,
  filterText: string | number | string[] | undefined,
  className?: string | undefined
}
interface DatatableRowInterface {
  id: number,
  email: string,
  name: string,
  tel: string,
  userRegtime: string
}

const FilterComponent: FC<FilterComponentProps> = (props) => {
  return (
    <div className={props.className} style={{ maxHeight: "32px", overflow: "hidden", width: '388px' }}>
      <Form.Control
        type="search"
        size="sm"
        className="form-control ps-5"
        placeholder="Pesquisar em todos os campos"
        value={props.filterText}
        onChange={props.onFilter}
      />
      <Button variant="bg-transparent" style={{ bottom: '18px' }} className="btn bg-transparent px-2 py-0 position-relative translate-middle-y gia-search-input-icon" type="submit">
        <FontAwesomeIcon icon={faSearch}/>
      </Button>
    </div>
  );
}

const GrowattEndUser: FC<GrowattEndUserProps> = () => {

  /* DATATABLE DEFINITIONS */
  const progressComponent = <div style={{ width: '100%', paddingBottom: '.25rem' }}><Skeleton height={45} count={10} /></div>;

  const basicSortStringNoCase = (a: string, b: string) => {

    if (a == null || b == null) {
      return 0;
    }

    const aToCompare = removeDiacritics(a).toLowerCase();
    const bToCompare = removeDiacritics(b).toLowerCase();
    return (aToCompare > bToCompare) ? 1 : (bToCompare > aToCompare) ? -1 : 0;
  };


  const convertResponse = (response: GrowattEndUserProps[]) => {
    return response
      .map((r: GrowattEndUserProps) => {
        return {
          id: r.cuserId,
          email: r.cuserEmail,
          name: r.cuserName,
          tel: r.cuserTel,
          userRegtime: r.cuserRegtime
        } as DatatableRowInterface;
      });
  }

  const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onRowClicked = (row: any) => {
    navigate(`/growatt-end-user/edit/${row.id}`);
  };

  const columns = useMemo(() => {

    const defaultRowStyle: CSS.Properties = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    };

    const defaultRowStringCell = (str: string) => {
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
    }

    const PhoneRowStringCell = (str: string) => {
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{maskPhone(str)}</span>;
    }

    const datetimeRowStringCell = (str: string) => {
      const strDatetime = (str) ? moment(str).format('L HH:mm') : '';
      return <span style={defaultRowStyle} title={strDatetime}>{strDatetime}</span>;
    }

    const columnsArray = [
      {
        name: 'Nome',
        selector: (row: any) => row.name,
        sortable: true,
        width: '20%',
        cell: (row: any) => defaultRowStringCell(row.name),
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.name, rowB.name),
      },
      {
        name: 'Email',
        selector: (row: any) => row.email,
        sortable: true,
        width: '25%',
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.email, rowB.email),
        cell: row => defaultRowStringCell(row.email),
      },
      {
        name: 'Telefone',
        selector: (row: any) => row.tel,
        sortable: true,
        width: '15%',
        cell: (row: any) => PhoneRowStringCell(row.tel),
      },
      {
        name: 'Data de registro',
        selector: (row: DatatableRowInterface) => row.userRegtime,
        sortable: true,
        width: '30%',
        cell: (row: any) => datetimeRowStringCell(row.userRegtime),
      },
    ];

    return columnsArray.map(c => ({ ...c, minWidth: c.width, maxWidth: c.width }));
  }, []);

  const textMatch = (value: string, filterTextToCompare: string) => {
    if (value !== undefined && value !== null) {
      return removeDiacritics(value).toLowerCase().includes(filterTextToCompare);
    }
    return false;
  };

  const filteredItems = datasource.filter(
    item => {
      const filterTextToCompare = filterText.toLowerCase();
      const name = !filterTextToCompare || textMatch(item.name, filterTextToCompare);
      const email = !filterTextToCompare || textMatch(item.email, filterTextToCompare);
      const telefone = !filterTextToCompare || textMatch(item.tel, filterTextToCompare);
      const userRegtime = !filterTextToCompare || textMatch(item.userRegtime, filterTextToCompare);

      return name || email || telefone || userRegtime;
    },
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const goToNewUser = () => {
      navigate("/growatt-end-user/edit");
    };
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="container-fluid g-0 mb-3" key={`filterBar`}>
        <div className="row">
          <div className="col-sm-12 d-flex">
            {
              <Button type="button" variant="primary" size="sm" className="me-4" onClick={goToNewUser}>Novo Usuário</Button>
            }
            <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
          </div>
        </div>
      </div>
    );
  }, [navigate, filterText, resetPaginationToggle]);

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/growatt-end-user`;
    axios.get(url).then(response => setDatasource(convertResponse(response.data))).then(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.GrowattEndUser} data-testid="GrowattEndUser">

      <div className="gia-page-title-default">Usuários Growatt</div>

      {isLoading && <Skeleton height={48} />}
      {!isLoading && subHeaderComponentMemo}

      {isLoading && <Skeleton height={'50vh'} />}
      {!isLoading &&
        <div className={`${styles.datatableWrapper} gia-datatable-wrapper`}>
          <DataTable
            pointerOnHover={true}
            onRowClicked={onRowClicked}
            columns={columns}
            data={filteredItems}
            noDataComponent={<><span className="gia-datatable-no-results">Nenhum registro</span></>}
            fixedHeader={true}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            className="gia-datatable"
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            persistTableHead
            progressPending={isLoading}
            progressComponent={progressComponent}
            theme="gia-default"
          />
        </div>
      }
    </div>
  );
};

export default GrowattEndUser;
