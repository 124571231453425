import {FC, useCallback, useEffect, useState} from "react";
import PersonaInterface from "../../../shared/interfaces/persona.interface";
import {useNavigate, useParams} from "react-router-dom";
import {getOnlyNumbers, maskDocument, maskPhone, zipCodeOnlyNumbers} from "../../../shared/helpers/string-utils.helper";
import cpfValidator from "../../../shared/validators/cpf-validator";
import cnpjValidator from "../../../shared/validators/cnpj-validator";
import {z} from "zod";
import {Button, Col, Form, FormControl, Row} from "react-bootstrap";
import {phoneValidator} from "../../../shared/validators/phone-validator";
import {cepValidator} from "../../../shared/validators/cep-validator";
import {useForm, UseFormRegisterReturn} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import LegalEntityInterface from "../../../shared/interfaces/legal-entity.interface";
import {PersonaTypeEnum} from "../../../shared/interfaces/persona-type-interface";
import {findById, findLegalEntityByDocument, findType, save} from "../_requests";
import {toast} from "react-toastify";
import {defaultToastOptions} from "../../../shared/toast/toast-default.options";
import {DocumentType} from "../../../shared/type/document.type";
import axios from "axios";
import styles from './customer-edit.module.scss';
import CustomerEditSkeleton from "./customer-edit-skeleton";
import {GiaTextField} from "../../../components/gia-forms-components/gia-forms-components";
import {ufEnum} from "../../../shared/type/uf.type";
import ManufacturerInterface from "../../../shared/interfaces/manufacturer.interface";
import AddressCityInterface from "../../../shared/interfaces/address-city-interface";
import ParamInterface from "../../../shared/interfaces/param.interface";
import { getParamByKey } from "../../../shared/services/params.service";


const CustomerEdit: FC<PersonaInterface> = (props) => {

  let { id } = useParams();
  const navigate = useNavigate();

  const [stateZipcodeForm, setStateZipcodeForm] = useState(true);
  const [stateAdressField, setStateAddressField] = useState(true);
  const [stateDistrictField, setStateDistrictField] = useState(true);
  const [cities, setCities] = useState<AddressCityInterface[]>([]);
  const [paramInsertAddressForm, setParamInsertAddressForm] = useState<ParamInterface>();
  const [zipcodeValid, setZipcodeValid] = useState(true);
  const [textErrorZipcode, setTextErrorZipcode] = useState("");
  const [validErrorZipcode, setValidErrorZipcode] = useState(false);

  // Define as empty char code to not generate auto message from zod
  const requiredErrorMessage = String.fromCharCode(0);
  const invalidNumberRule = { invalid_type_error: requiredErrorMessage };
  const minLengthMessage = "Insira no mínimo 3 caracteres";

  const documentValidator = (document: string) => {
    const numbers = getOnlyNumbers(document)
    if (numbers.length === 11) {
      return cpfValidator.isValid(document, true);
    }
    if (numbers.length === 14) {
      return cnpjValidator.isValid(document, true)
    }
    return false;
  }

  const formSchema = z.object({
    id: z.number().positive().min(1).optional().nullable(),
    uuid: z.string(invalidNumberRule).max(255).optional().nullable(),
    type: z.string(invalidNumberRule).min(1, requiredErrorMessage).max(255),
    legalEntity: z.object({
      id: z.number().positive().min(1).optional().nullable(),
      name: z.string(invalidNumberRule).min(3, minLengthMessage).max(255),
      email: z.union([z.string().email(), z.literal('')]).optional().nullable(),
      phone: z.string(invalidNumberRule).max(255).optional().nullable().refine(value => value ? phoneValidator(value) : true, "Insira um telefone válido").optional().nullable(),
      address: z.union([z.string(invalidNumberRule).min(3, minLengthMessage).max(255), z.literal('')]).optional().nullable(),
      city: z.union([z.string(invalidNumberRule).min(3, minLengthMessage).max(255), z.literal('')]).optional().nullable(),
      uf: z.string(invalidNumberRule).max(255).optional().nullable(),
      document: z.string(invalidNumberRule).max(255).refine(value => value ? documentValidator(value) : true, "Insira um Documento válido").optional().nullable(),
      addressNumber: z.string(invalidNumberRule).optional().nullable(),
      complement: z.string(invalidNumberRule).optional().nullable(),
      district: z.string().max(255).optional().nullable(),
      zipCode: z.object({
        zipCode: z.string().optional().nullable(),
        address: z.string().optional().nullable(),
        complement: z.string().optional().nullable(),
        district: z.string().optional().nullable(),
        addressCityZipcode: z.object({
          cityId: z.number().optional().nullable(),
          name: z.string(invalidNumberRule).max(255).optional().nullable()
        }).optional().nullable(),
        addressStateZipcode: z.object({
          stateId: z.number().optional().nullable(),
          state: z.string(invalidNumberRule).max(255).optional().nullable()
        }).optional().nullable()
      }).optional().nullable(),
    }),
    typeValue: z.object({
      id: z.number().positive().min(1).optional().nullable(),
      value: z.string(invalidNumberRule).max(255).optional().nullable()
    }).optional().nullable()
  });

  type FormSchemaType = z.infer<typeof formSchema>;
  const { register, setValue, handleSubmit, reset, getValues, setError, formState: { errors } } = useForm<FormSchemaType>({
    mode: 'all',
    resolver: zodResolver(formSchema)
  });

  const [persona, setPersona] = useState<PersonaInterface>();
  const [, updateState] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [documentPreviousValue, setDocumentPreviousValue] = useState<string | undefined>();

  const convertResponse = (data: any): PersonaInterface => {
    return {
      id: data.id,
      uuid: data.uuid,
      type: data.description,
      legalEntity: data.legalEntity as LegalEntityInterface
    }
  }

  const getKeyOfPersonaType = (type) => {
    const index = Object.values(PersonaTypeEnum).indexOf(type);
    return Object.keys(PersonaTypeEnum)[index];
  }

  const getPersonaType = useCallback(() => {
    if (!persona?.id || !persona?.type) {
      return;
    }
    setIsLoading(true);
    return findType(persona.id);
  }, [persona, setIsLoading])

  const normalizeResponse = useCallback((data: FormSchemaType) => {
    setValue("typeValue", data);
    setValue("legalEntity.document", maskDocument(persona?.legalEntity?.document));
    setValue("legalEntity.phone", maskPhone(persona?.legalEntity?.phone));
  }, [persona, setValue]);

  const onChangeDocument = (e) => {
    setValue("legalEntity.document", maskDocument(e.target.value));
  };

  const onBlurDocument = (e) => {
    const currentDocument = e.target.value.replace(/\D/g, "");
    if(documentPreviousValue !== currentDocument) {
      setDocumentPreviousValue(currentDocument);
      findLegalEntityByDocument(e.target.value)
        .then(r => fillLegalEntityFieldsForm(r.data))
        .catch(e => console.error(e));
    }
  };

  const fillLegalEntityFieldsForm = (legalEntity) => {
    setValue('legalEntity.city', legalEntity.city);
    setValue('legalEntity.email', legalEntity.email);
    setValue('legalEntity.id', legalEntity.id);
    setValue('legalEntity.name', legalEntity.name);
    setValue('legalEntity.phone', maskPhone(legalEntity.phone));
    setValue('legalEntity.uf', legalEntity.uf);
    setValue('legalEntity.addressNumber', legalEntity.addressNumber);
    setValue('legalEntity.complement', legalEntity.complement);
    setValue('legalEntity.zipCode', legalEntity.zipCode);

    if(legalEntity?.zipCode?.address == ""){
      legalEntity!.zipCode.address = String(legalEntity!.address);
      setValue('legalEntity.zipCode.address', String(legalEntity!.address));
      setStateAddressField(false);
    }else{
      setStateAddressField(true);
    }
    if(legalEntity?.zipCode?.district == ""){
      legalEntity!.zipCode.district = String(legalEntity!.district);
      setValue('legalEntity.zipCode.district', String(legalEntity!.district));
      setStateDistrictField(false);
    }else{
      setStateDistrictField(true);
    }

    toast.info('Dados foram pré-preenchidos automaticamente!', defaultToastOptions);
  }

  useEffect(() => {

    const fetchParam = async () => {
      await getParamByKey("INSERT_ADDRESS_FORM").then(r => r.data).then(response => setParamInsertAddressForm(response));
    }

    fetchParam().then(() => setIsLoading(false)).catch(error => {
      setIsLoading(false);
      console.error(error);
    });

    if (!id) {
      const customerDefaultValues = {
        type: 'Cliente'
      };
      reset(customerDefaultValues)
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      await findById(Number(id))
      .then(response => convertResponse(response.data))
      .then(response => {

        if(response.legalEntity?.zipCode != null){
          if(response.legalEntity?.zipCode?.address == ""){
            response.legalEntity!.zipCode.address = String(response.legalEntity!.address);
            setStateAddressField(false);
          }
          if(response.legalEntity?.zipCode?.district == ""){
            response.legalEntity!.zipCode.district = String(response.legalEntity!.district);
            setStateDistrictField(false);
          }
        }

        setPersona(response);
      })
    }

    fetchData().then(() => setIsLoading(false)).catch(error => {
      setIsLoading(false);
      console.error(error);
    });
  }, [id, reset])

  useEffect(() => {
    if (!persona) {
      return;
    }
    // TODO review if the promise all is really necessary for one request only
    Promise.all([
      getPersonaType()
    ]).then(response => {
      normalizeResponse(response[0]?.data as FormSchemaType);
      setIsLoading(false);
    });

    reset(persona)
  }, [persona, reset, getPersonaType, setValue, normalizeResponse])

  const normalizeToSend = (data) => {
    data.description = data.type;
    data.legalEntity.phone = getOnlyNumbers(data.legalEntity.phone);
    data.legalEntity.document = getOnlyNumbers(data.legalEntity.document)

    if ((data.typeValue.value === DocumentType.PF && !cpfValidator.isValid(data.legalEntity.document)) ||
      (data.typeValue.value === DocumentType.PJ && !cnpjValidator.isValid(data.legalEntity.document))) {
      setError("legalEntity.document", { message: `Insira um documento válido para ${data.typeValue.value}` });
      return null;
    }

    return data;
  }

  const submitForm = (data: FormSchemaType) => {

    const normalizedData = normalizeToSend(data)
    if (!normalizedData) {
      return;
    }
    
    let valueZipcode:string = normalizedData.legalEntity.zipCode.zipCode!;
    if(Number.parseInt(valueZipcode) <= 1000000){
      return;
    }

    if(!zipcodeValid){
      return;
    }

    save(getKeyOfPersonaType(normalizedData.type), Number(normalizedData.id ?? 0), normalizedData, normalizedData.typeValue)
      .then(response => {
        if (id !== response.data.id) {
          id = response.data.id;
          navigate(`/customer/edit/${id}`);
        }

        setStateZipcodeForm(true);
        toast.success('Cliente salvo com sucesso!', defaultToastOptions);
      }).catch(err => {
      console.error(err.request.status);
      if(err.request.status === 409){
        const response = JSON.parse(err.request.response);
        let message = 'Dado inconsistente, reveja os campos e tente novamente.';
        if(response.error === 'ConstraintDocumentException') {
          message = 'Documento não pode ser utilizado, por favor entre em contato com o administrador.';
        }
        toast.error(message, defaultToastOptions);
      }else{
        toast.error('Houve um problema ao salvar os dados. Por favor tente novamente.', defaultToastOptions);
      }
    });
  }

  const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;
  const findByZipCode = (value:string) => {

    let zipcodeValue = zipCodeOnlyNumbers(value);
    setValue("legalEntity.zipCode.zipCode", zipcodeValue);

    if(zipcodeValue.length === 8){

      let apiURL = `${baseURL}/address-zipcode/${zipcodeValue}`;

      Promise.all([
        axios.get(apiURL)
      ]).then(response => {

        const zipCode = response[0]?.data;

        if(zipCode.address != null){
          setValidErrorZipcode(false);
          setTextErrorZipcode("");
          setZipcodeValid(true);
          setStateZipcodeForm(true);
          setValue("legalEntity.zipCode.address", zipCode.address);

          if(zipCode.address != '')
            setStateAddressField(true);
          else
            setStateAddressField(false);

          if(zipCode.district != '')
            setStateDistrictField(true);
          else
            setStateDistrictField(false);

          setValue("legalEntity.zipCode.district", zipCode.district);
          setValue("legalEntity.zipCode.addressCityZipcode.name", zipCode.addressCityZipcode.name);
          setValue("legalEntity.zipCode.addressStateZipcode.state", zipCode.addressStateZipcode.state);
        }else{

          if(paramInsertAddressForm?.value == "0"){
            setValidErrorZipcode(true);
            setTextErrorZipcode("CEP inválido");
            setZipcodeValid(false);
            return;
          }

          setStateZipcodeForm(false);
          setStateAddressField(false);
          setStateDistrictField(false);
          setValue("legalEntity.zipCode.address", "");
          setValue("legalEntity.zipCode.district", "");
          setValue("legalEntity.zipCode.addressCityZipcode.name", "");
          setValue("legalEntity.zipCode.addressStateZipcode.state", "");
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Erro ao carregar cep");
      });
    } else {
      
      if(zipcodeValue.length == 0){
        setValue("legalEntity.zipCode.zipCode", "");
      }

      setZipcodeValid(true);
      setStateZipcodeForm(true);
      setStateAddressField(true);
      setStateDistrictField(true);
      setValue("legalEntity.zipCode.address", "");
      setValue("legalEntity.zipCode.district", "");
      setValue("legalEntity.zipCode.addressCityZipcode.name", "");
      setValue("legalEntity.zipCode.addressStateZipcode.state", "");
      setValue("legalEntity.addressNumber", "");
      setValue("legalEntity.complement", "");
    }
    
  }

  const validateCep = (value) => {

    let zipcodeValue = zipCodeOnlyNumbers(value);

    if(zipcodeValue.length > 0 && zipcodeValue.length < 8){
      setValidErrorZipcode(true);
      setTextErrorZipcode("Digite os 8 números do CEP");
      return;
    }else{
      if(zipcodeValid){
        setValidErrorZipcode(false);
        setTextErrorZipcode("");
      }
    }

    if(Number.parseInt(zipcodeValue) <= 1000000){
      setValidErrorZipcode(true);
      setTextErrorZipcode("CEP inválido");
      return;
    }
  }

  const setCitiesList = () => {

    if(getValues('legalEntity.zipCode.addressStateZipcode.state') != ''){

      let apiURL = `${baseURL}/address-zipcode/citylist/${getValues('legalEntity.zipCode.addressStateZipcode.state')}`;
        
      Promise.all([
        axios.get(apiURL)
      ]).then(response => {

        const types = response[0]?.data;
        setCities(types);

      })
      .catch(err => {
        console.log(err);
        toast.error("Erro ao carregar lsita de cidade");
      });
    }
  }

  return (
    <div className={styles.CustomerEdit}>
      {isLoading && <CustomerEditSkeleton />}
      {!isLoading &&
        <Form onSubmit={handleSubmit(submitForm)} className="gia-form">
          <div className="gia-page-title-default header">
            Cliente
          </div>
          <div>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Nome"
                  required
                  isInvalid={!!errors?.legalEntity?.name}
                  isInvalidText={errors?.legalEntity?.name?.message}
                  showMessage={true}
                  registerName={{ ...register('legalEntity.name') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Documento"
                  isInvalid={!!errors?.legalEntity?.document}
                  isInvalidText={errors?.legalEntity?.document?.message}
                  showMessage={true}
                  required={false}
                  registerName={{ ...register('legalEntity.document', {onBlur: (e) => onBlurDocument(e)})}}
                  onChange={(e) => onChangeDocument(e)}
                ></GiaTextField>
              </Form.Group>
            </Row>
            <PersonaTypeValueComponent
              register={{ ...register("typeValue.value") }}
              errors={!!errors.type}
              type={getValues('type')}> </PersonaTypeValueComponent>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="E-mail"
                  isInvalid={!!errors?.legalEntity?.email}
                  isInvalidText={errors?.legalEntity?.email?.message}
                  required={false}
                  registerName={{ ...register('legalEntity.email') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Telefone"
                  isInvalid={!!errors?.legalEntity?.phone}
                  isInvalidText={errors?.legalEntity?.phone?.message}
                  showMessage={true}
                  required={false}
                  onChange={(e) => setValue("legalEntity.phone", maskPhone(e.target.value))}
                  registerName={{ ...register('legalEntity.phone') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="3">
                {/* <GiaTextField
                  title="CEP"
                  isInvalid={validErrorZipcode}
                  isInvalidText={textErrorZipcode}
                  required={false}
                  showMessage={true}
                  onChange={(e) => findByZipCode(e.target.value)}
                  //onBlur={e => validateCep(e.target.value)}
                  registerName={{ ...register('legalEntity.zipCode.zipCode') }}></GiaTextField> */}
                  <div className={`${styles.giaTextField}`}>
                    <div className={`label required`} title="CEP">CEP</div>
                    <FormControl {...register('legalEntity.zipCode.zipCode')}
                                isInvalid={validErrorZipcode}
                                onChange={(e) => findByZipCode(e.target.value)}
                                onBlur={e => validateCep(e.target.value)}
                                />
                    {<div className={`${styles.feedbackMessage} gia-message-error`}>
                      {true && <span>{textErrorZipcode}</span>}
                      </div>}
                  </div>
              </Form.Group>
              <div className={stateZipcodeForm ? 'label col-md-8 hiddenCepMessage' : 'label col-md-8 showCepMessage'}>CEP não encontrado<br/>Por favor reveja o número do CEP ou entre com os dados de endereço abaixo</div>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Endereço"
                  isInvalid={!!errors.legalEntity?.address}
                  isInvalidText={errors?.legalEntity?.address?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateAdressField}
                  registerName={{ ...register('legalEntity.zipCode.address') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Bairro"
                  isInvalid={!!errors.legalEntity?.district}
                  isInvalidText={errors?.legalEntity?.district?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateDistrictField}
                  registerName={{ ...register('legalEntity.zipCode.district') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="2">
                <div className="label">UF</div>
                <Form.Select
                  title="UF"
                  isInvalid={!!errors.legalEntity?.zipCode?.addressStateZipcode?.state}
                  required={false}
                  disabled={stateZipcodeForm}
                  {...register('legalEntity.zipCode.addressStateZipcode.state')}
                  onChange={e => { setValue("legalEntity.zipCode.addressStateZipcode.state", e.target.value); updateState({}); setCitiesList();}}>
                  <option key={'select-a-option'} value={''}>Selecione...</option>
                  {Object.values(ufEnum).sort((a, b) => a.localeCompare(b)).map((option) => {
                    return <option key={option} value={option}>{option}</option>
                  })}
                </Form.Select>
                <div className="gia-form-feedback-message"></div>
              </Form.Group>
            </Row>
            {stateZipcodeForm && 
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Cidade"
                  isInvalid={!!errors.legalEntity?.zipCode?.addressCityZipcode?.name}
                  isInvalidText={errors?.legalEntity?.zipCode?.addressCityZipcode?.name?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateZipcodeForm}
                  registerName={{ ...register('legalEntity.zipCode.addressCityZipcode.name') }}></GiaTextField>
              </Form.Group>
            </Row>
            }
            {!stateZipcodeForm &&
            <Row>
              <Form.Group as={Col} md="4">
                <div className="label required">Cidade</div>
                <Form.Select
                  title="Cidade"
                  isInvalid={!!errors.legalEntity?.zipCode?.addressCityZipcode?.name}
                  required={true}
                  {...register('legalEntity.zipCode.addressCityZipcode.name')}>
                  {cities.map((option, i) => {
                    return <option key={i} value={option.name}>{option.name}</option>
                  })}
                </Form.Select>
                  <div className="gia-form-feedback-message"></div>
              </Form.Group>
            </Row>
            }
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Número"
                  isInvalid={!!errors.legalEntity?.addressNumber}
                  isInvalidText={errors?.legalEntity?.addressNumber?.message}
                  showMessage={true}
                  required={false}
                  registerName={{ ...register('legalEntity.addressNumber') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Complemento"
                  isInvalid={!!errors.legalEntity?.complement}
                  isInvalidText={errors?.legalEntity?.complement?.message}
                  showMessage={true}
                  required={false}
                  registerName={{ ...register('legalEntity.complement') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
            </Row>
          </div>
          <div className="row-buttons-wrapper buttons-container">
            <Row>
              <Col>
                <Button type="submit" size="sm">Salvar</Button>
                <Button type="button" variant="outline-light" onClick={() => { navigate("/customer") }} size="sm">Voltar</Button>
              </Col>
            </Row>
          </div>
        </Form>
      }
    </div>
  );
}

const PersonaTypeValueComponent: FC<any> = (
  props: {
    type: PersonaTypeEnum,
    errors: boolean,
    register: UseFormRegisterReturn,
    manufacturers: ManufacturerInterface[]
  }) => {

  return (
    <Row>
      <Form.Group as={Col} md="4">
        <div className="label required">Cliente</div>
        <Form.Select
          title="Cliente"
          isInvalid={props.errors}
          required={true}
          {...props.register}>
          {<option key={'select-a-option'} value={''}>Selecione...</option>}
          <option value={DocumentType.PF}>PF</option>
          <option value={DocumentType.PJ}>PJ</option>
        </Form.Select>
        <div className="gia-form-feedback-message"></div>
      </Form.Group>
    </Row>
  )
}

export default CustomerEdit;
