import axios, {AxiosResponse} from 'axios';
import PlantValidateDevicesRequestInterface from "../../shared/interfaces/plant-validate-devices-request.interface";
import PlantValidateDevicesResponseInterface from "../../shared/interfaces/plant-validate-devices-response.interface";

export function loadPlantWithDevices(plantId) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/plant/withDevices/${plantId}`;
  return axios.get(url);
}

export function savePlantWithDevices(plant) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/plant/withDevices`;
  return axios.put(url, plant);
}

export function deletePlantById(plantId) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/plant/${plantId}`;
  return axios.delete(url);
}

export function loadCustomers() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/customer`;
  return axios.get(url);
}
export function loadIntegrators() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/integrator`;
  return axios.get(url);
}

export function loadManufacturers() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/manufacturer`;
  return axios.get(url);
}

export function loadDistributors() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/distributor`;
  return axios.get(url);
}

export function loadFinanciers() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/financier`;
  return axios.get(url);
}

export function validateDevices(validateDevicesRequest: PlantValidateDevicesRequestInterface): Promise<AxiosResponse<PlantValidateDevicesResponseInterface>> {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/device/validate-devices`;
  return axios.post(url, validateDevicesRequest);
}

export function hasDeviceData(deviceId) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/plant-device-data/hasDeviceData/${deviceId}`;
  return axios.get(url);
}