import React, {FC} from 'react';
import styles from './emissions-reduction-kpi.module.scss';
import {toAbsoluteUrl} from '../../../shared/helpers/AssetHelpers';

interface EmissionsReductionKpiProps {
  title: string,
  img: string,
  value: string | undefined,
  valueSuffix: string | undefined
}

const EmissionsReductionKpi: FC<EmissionsReductionKpiProps> = ({title, img, value, valueSuffix}, context) => {

  return (
    <div className={`${styles.EmissionsReductionKpi} emissions-reduction-kpi`} data-testid="EmissionsReductionKpi">
      <div>
        <div className="title">{title}</div>
        <div className="content" title={`${value} ${valueSuffix}`}>
          <div><img src={toAbsoluteUrl(img)} alt="Icon"/></div>
          <div className="value">
            <>{value}</>
            <span className="suffix">{valueSuffix}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmissionsReductionKpi;
