import { FC, useCallback, useMemo } from "react";
import FilterComponent from "../filter/filter";


interface SubHeaderProps {
    filterText: string,
    setFilterText: React.Dispatch<React.SetStateAction<string>>,
    resetPaginationToggle: boolean
    setResetPaginationToggle: any
    placeholder?: string
  }
  
  const SubHeaderComponent: FC<SubHeaderProps> = ({
    filterText,
    setFilterText,
    resetPaginationToggle,
    setResetPaginationToggle,
    placeholder
  }) => {
    const handleClear = useCallback(() => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    }, [filterText, setFilterText, resetPaginationToggle, setResetPaginationToggle]);
  
    const subHeaderComponentMemo = useMemo(() => {
      return (
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={placeholder}
        />
      );
    }, [filterText, handleClear, setFilterText]);
  
    return <>{subHeaderComponentMemo}</>;
  };
  export default SubHeaderComponent;