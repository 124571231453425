import React, {useEffect, useState} from "react";
import {PersonaTypeEnum} from "../../shared/interfaces/persona-type-interface";
import {Badge, Button, Stack} from "react-bootstrap";
import SimplePersonaInterface from "../../shared/interfaces/simple-persona.interface";
import PersonaSelectModal from "../persona-select-modal/persona-select-modal";

interface PersonaSelectProps {
  persona?: SimplePersonaInterface
  onSelectPersona: (persona: SimplePersonaInterface) => void
  type?: PersonaTypeEnum
  isInvalidPersona?: boolean
  hasAllPerson?: boolean
}

export default function PersonaSelect({ persona, onSelectPersona, type, isInvalidPersona = false, hasAllPerson = false }: Readonly<PersonaSelectProps>) {
  const [showPersonaModal, setShowPersonaModal] = useState<boolean>(false);
  const [selectedPersona, setSelectedPersona] = useState<SimplePersonaInterface | undefined>();

  useEffect(() => {
    
    if (hasAllPerson && persona?.id === -1){ handleSelectPersona(undefined);} 
    
    if (!persona || !type || persona.id === -1) return;
    
    setSelectedPersona(persona);

  }, [type, persona]);

  const handleSelectPersona = (persona: SimplePersonaInterface | undefined) => {
    setSelectedPersona(persona);
    onSelectPersona(persona ?? ({} as SimplePersonaInterface));
  }

  return (
    <>
      {type && PersonaTypeEnum[type] !== PersonaTypeEnum.ALL && (
        <div className='col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-4'>
          <label className='label mb-1 required' style={{display: "block"}}>{PersonaTypeEnum[type]}</label>
          {isInvalidPersona && <p className="gia-message-error">Necessário selecionar um {`${PersonaTypeEnum[type]}`.toLowerCase()}</p>}
          {!selectedPersona && (
            <Button
              size='sm'
              type="button"
              className='mt-2'
              variant='outline-light'
              onClick={() => setShowPersonaModal(true)}
            >
              Selecione o {PersonaTypeEnum[type]}
            </Button>
          )}
          {selectedPersona && (
            <Stack direction="horizontal" gap={2} className="mt-3">
              <Badge bg="light" text="muted" style={{fontSize: ".9rem"}}>{selectedPersona.name}</Badge>
              <Button
                size='sm'
                type="button"
                className='pt-0 pb-0'
                variant='outline-light'
                onClick={() => handleSelectPersona(undefined)}
              >
                Remover
              </Button>
            </Stack>
          )}

          <PersonaSelectModal
            show={showPersonaModal}
            title={`Selecione o ${PersonaTypeEnum[type]}`}
            personaType={type}
            onHide={() => setShowPersonaModal(false)}
            onSelectPersona={handleSelectPersona}
          />
        </div>
      )}
    </>
  );
}
