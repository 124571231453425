import { FC, useEffect, useState } from 'react';
import styles from './manage-users.module.scss';
import Skeleton from 'react-loading-skeleton';
import { TableColumn } from 'react-data-table-component';
import CustomDatatable from '../../../components/custom-datatable/custom-datatable';
import { useNavigate } from 'react-router-dom';
import SubHeaderComponent from '../../../components/subHeader/subHeader';
import { Button, Form } from 'react-bootstrap';
import UserInterface from '../../../shared/interfaces/user.interface';
import { filterByFields } from '../../../components/filter/filter';
import { getAllUser } from './_requests';

interface ManageUsersProps {}
interface DatatableRowInterface extends UserInterface {
  isActive: boolean
}

const convertResponse = (response:DatatableRowInterface[]) => {
    return response
      .map((r: DatatableRowInterface) => {
      return {
        id: r.id,
        firstName: r.firstName,
        lastName: r.lastName,
        email: r.email,
        isActive: r.active ? true : false
      } as DatatableRowInterface;
    });
  }

const columns: TableColumn<DatatableRowInterface>[] = [
    {
        name: 'E-mail',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Nome',
        selector: row => row.firstName,
        sortable: true,
    },
    {
      name: 'Sobrenome',
      selector: row => row.lastName,
      sortable: true,
    },
    {
      name: 'Situação',
      sortable: true,
      cell: (row) => 
      <Form.Check
      id={`Ativo ${row.id}`}
      disabled={true}
      type="checkbox"
      label='Ativo'
      checked={row.isActive}
      />
    },
];


const ManageUsers:FC<ManageUsersProps> = () => {

    const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const navigate = useNavigate();

    const filteredItems:DatatableRowInterface[] = filterByFields(datasource, filterText, ['firstName', 'lastName', 'email']);

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await getAllUser();
          setDatasource(convertResponse(response.data));
          setIsLoading(false);
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }finally{
          setIsLoading(false);
        }
      };

      fetchData();
    },[]);


    const onRowClicked = (row) => {
      navigate(`user/${row.id}`, {state: { row }});
    };

    return(
    <div className={styles.UsersContainer} data-testid="UsersContainer">
      <div className="gia-page-title-default">Usuários</div>
      {isLoading && <Skeleton height={48} />}
      
      {isLoading && <Skeleton height={'50vh'} />}
      {!isLoading && 
        <>
            <div className="container-fluid g-0 mb-3">
              <div className="row">
              <div className="col-sm-12 d-flex">
                  <Button type="button" variant="primary" size="sm" className="me-4" onClick={() => navigate('/manage-users/user')}>Criar Usuário</Button>
                  {!isLoading && <SubHeaderComponent filterText={filterText} resetPaginationToggle={resetPaginationToggle} setResetPaginationToggle={setResetPaginationToggle} setFilterText={setFilterText}/>}
              </div>
              </div>
            </div>
            <CustomDatatable columns={columns} filteredItems={filteredItems} filterText={filterText} setFilterText={setFilterText} isLoading={isLoading} selectableRows={false} onRowClicked={onRowClicked} resetPaginationToggle={resetPaginationToggle}/>
        </>
      }
    </div>
    );
}

export default ManageUsers;   