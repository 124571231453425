import React, {FC} from 'react';
import styles from './plant-info.module.scss';
import {PlantInfoInterface} from './plant-info.interface';
import GiaSpacer from '../../shared/gia-spacer/gia-spacer';
import GiaNumberFormat from '../../shared/components/number-format/gia-number-format';
import {toAbsoluteUrl} from '../../shared/helpers/AssetHelpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSolarPanel} from '@fortawesome/free-solid-svg-icons'

interface PlantInfoProps {
  plantInfo: PlantInfoInterface
}

const PlantInfo: FC<PlantInfoProps> = ({plantInfo}) => (
  <div className={styles.PlantInfo} data-testid="PlantInfo">

    <div className="card">

      <div className="plant-header" style={{paddingBottom: '16px'}}>
        <div className="plant-name"><FontAwesomeIcon icon={faSolarPanel} title="USINA" /> {plantInfo.name}</div>
      </div>

      <div className="body">
        <div className="item">
          <div className="title">FABRICANTE</div>
          <div className="content">
            {plantInfo.manufacturer &&
                <img src={toAbsoluteUrl(`/media/manufacturer/logo-${plantInfo.manufacturer.toLowerCase()}-16.png`)}
                     alt={plantInfo.manufacturer}
                     title={plantInfo.manufacturer}
                     style={{maxHeight: '16px'}} />
            }
          </div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">INTEGRADOR</div>
          <div className="content">{plantInfo.integrator}</div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">POTÊNCIA</div>
          <div className="content">
            <GiaNumberFormat value={plantInfo.peakPower} decimalPlaces={2} suffix={'kWp'}/>
          </div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">CIDADE</div>
          <div className="content">{plantInfo.city}</div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">UF</div>
          <div className="content">{plantInfo.state}</div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">INVERSOR</div>
          <div className="content">{plantInfo.deviceSerialNumber}</div>
        </div>
        <GiaSpacer/>
        <div className="item">
          <div className="title">DATALOGGER</div>
          <div className="content">{plantInfo.dataLoggerSerialNumber}</div>
        </div>
      </div>
    </div>

  </div>
);

export default PlantInfo;
