import React from "react";
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import 'moment/locale/pt-br';

moment.locale('pt-br');
registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');


export function GiaDatePicker({ register, required, ...props }) {
  return (
    <div>
      <div className={`label${required ? ' required' : ''}`} title={props.title}>{props.title}</div>
      <input type="hidden" {...register} />
      <DatePicker {...props} />
    </div>
  );
}
