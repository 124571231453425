import { FC, useCallback, useEffect, useState } from "react";
import RobbuSchedulerGroupInterface from "../../../shared/interfaces/robbu-scheduler-group-interface";
import { useNavigate, useParams } from "react-router-dom";
import RobbuSchedulerGroupRequests from "../_requests";
import styles from "./robbu-scheduler-group-edit.module.scss";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RobbuGroupFormSchemaType, robbuGroupFormSchema } from "../schemas";
import { toast } from "react-toastify";
import { defaultToastOptions } from "../../../shared/toast/toast-default.options";
import { GiaTextField } from "../../../components/gia-forms-components/gia-forms-components";
import { Button, Col, Row } from "react-bootstrap";
import RobbuGroupSkeleton from "./robbuGroupSkeleton";
import MultiplePlantsSelect from "../../../components/multiple-plants-select/multiple-plants-select";
import RobbuGroupWithPlantsContacts from "../../../shared/interfaces/robbu-group-with-plants-interface";
import SimplePlantInterface from "../../../shared/interfaces/simple-plant-interface";

interface RobbuSchedulerGroupEditProps{
    robbuGroup: RobbuSchedulerGroupInterface;
    plants: SimplePlantInterface[];
    selectedPlants: RobbuGroupWithPlantsContacts[] | undefined;
    isLoading: boolean | undefined;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    isEdit: boolean;
}

const RobbuSchedulerGroupEdit:FC = () => { 
    const { id:robbuGroupId } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [robbuGroup, setRobbuGroup] = useState<RobbuSchedulerGroupInterface>({
        id: -1,
        name: '',
        plantIds: [],
      });
    const [plants, setPlants] = useState<SimplePlantInterface[]>([]);
    const isEdit:boolean = robbuGroupId ? true : false;
    const [selectedPlants, setSelectedPlants] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const fetchRobbuGroupData = async () => {
          try {
            const { data } = await RobbuSchedulerGroupRequests.getRobbuGroupById(String(robbuGroupId));
            setRobbuGroup(data);
          } catch (error) {
            console.log("Erro ao obter dados do Grupo de Mensagens Robbu: " + error);
          }
        };
      
        const fetchAllPlantsData = async () => {
          try {
            const { data } = await RobbuSchedulerGroupRequests.getAllPlants();
            setPlants(data);
          } catch (error) {
            console.log("Erro ao obter dados das Plantas: " + error);
          }
        };

        const findAllSelectedPlantsContacts = async () => {
          try {
            const { data } = await RobbuSchedulerGroupRequests.findAllPlantContactsByRobbuId(robbuGroupId);
            setSelectedPlants(data);
          } catch (error) {
            console.log(error);
            console.log("Erro ao obter Contatos das Plantas: " + error);
          }
        };
    
        fetchAllPlantsData();
  
        if(isEdit){
          fetchRobbuGroupData().then();
          findAllSelectedPlantsContacts();
          findAllSelectedPlantsContacts();
        }else{
          setIsLoading(false);
        }
  
      },[isEdit, robbuGroupId]);
  
      return (
              <div className={`${styles.robbuGroupContainer} container-fluid `}>
                      <div className="gia-page-title-default">Grupos de Mensagens</div>
                      <div className={`${styles.formContainer} row d-flex flex-column align-items-center`}>
                     <RobbuSchedulerGroupForm robbuGroup={robbuGroup} plants={plants} selectedPlants={selectedPlants} setIsLoading={setIsLoading} isLoading={isLoading} isEdit={isEdit}/>
                  </div>
              </div>
      );
};

const RobbuSchedulerGroupForm:FC<RobbuSchedulerGroupEditProps> = ({ robbuGroup, plants, selectedPlants, setIsLoading, isLoading, isEdit }) => {

    const { register, handleSubmit, setValue, formState: { errors }, clearErrors, reset } = useForm<RobbuGroupFormSchemaType>({
      mode: 'all',
      resolver: zodResolver(robbuGroupFormSchema)
    });
    const navigate = useNavigate();
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
      reset(robbuGroup);
      setIsLoading(false);
    }, [robbuGroup, plants, selectedPlants, reset, setIsLoading]);

    const saveData = async (formData:RobbuGroupFormSchemaType) => {
      const data:RobbuSchedulerGroupInterface = {
        id: robbuGroup.id,
        name: formData.name,
        plantIds: formData.plantIds
      }

      const successMessage = (isEdit) ? "Grupo de Mensagens atualizado com sucesso" : "Grupo de Mensagens criado com sucesso";
      const request = (isEdit) ? RobbuSchedulerGroupRequests.updateRobbuGroup(data).then() : RobbuSchedulerGroupRequests.createRobbuGroup(data).then();

      try{
        const { data:responseData } = await request;
        toast.success(successMessage, defaultToastOptions);
        if(!isEdit){
          navigate(`/robbu-scheduler-group/edit/${responseData.id}`);
        }
      }catch(error){
        console.log(error);
        toast.error("Não foi possível salvar o Grupo de Mensagens. \n"
            + "Por favor tente novamente.", defaultToastOptions);
      }
    }

    const deleteGroup = async () => {
      //TODO make this a modal
      const confirm = window.confirm('Confirma excluir o Grupo de Mensagens? Esta ação não pode ser desfeita.');
      if(confirm) {
        try {
          await RobbuSchedulerGroupRequests.deleteRobbuGroupById(String(robbuGroup.id));
          toast.success("Grupo deletado com sucesso.", defaultToastOptions);
          backToManageList();
        } catch (error) {
          console.log("Erro ao deletar Grupo de Mensagens: " + error);
          toast.error("Não foi possível deletar o Grupo de Mensagens. \n"
            + "Por favor tente novamente", defaultToastOptions);
        }
      }
    };

    const backToManageList = () => {
      navigate("/robbu-scheduler-group");
    };
  
    return (
      <>
        {isLoading && <RobbuGroupSkeleton/>}
        {!isLoading && 
        <form className={`${styles.form} d-flex flex-column gia-form`} onSubmit={handleSubmit(saveData)}>
          <GiaTextField 
            title="Nome"
            type="text"
            registerName={register("name")} 
            placeholder="Nome" 
            max={30} 
            isInvalid={!!errors.name}
            isInvalidText={errors?.name?.message}
            required={true}
            className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-4" />

          <MultiplePlantsSelect
            data={plants}
            selectedData={selectedPlants} //Passa um array com os objetos de plantas completos
            onSelectData={(plantsSelected:RobbuGroupWithPlantsContacts[] | undefined) => {
                  setValue('plantIds', plants
                  .filter(plant => plantsSelected?.some(selected => selected.plantId === plant.plantId))
                  .map(plant => plant.plantId)); //Pega só os ids (plantId)
                  clearErrors('plantIds');
                  forceUpdate();
            }}  
            isInvalidData={!!errors.plantIds}
            entityName="Usinas" />

          <div className="row-buttons-wrapper mt-4">
            <Row>
              <Col>
                  <Button type="submit" size="sm">Salvar</Button>
                  <Button type="button" variant="secondary" onClick={backToManageList} size="sm">Voltar</Button>
                  {isEdit && <Button type="button" variant="danger" onClick={deleteGroup} size="sm">Excluir</Button>}
              </Col>
            </Row>
          </div>
        </form>
        }
      </>
    );
  }


export default RobbuSchedulerGroupEdit;