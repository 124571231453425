import {z} from "zod";
import {CredentialType} from "../../../shared/type/credential.type";

const credentialManagerSchema = z.object({
    id: z.number().nullable().optional(),
    personaId: z.string().min(1),
    manufacturerId: z.string().min(1),
    credentialType: z.string().min(1),
    credential: z.string().optional(),
    token: z.string().optional(),
    user: z.string().optional(),
    password: z.string().optional(),
    appId: z.string().optional(),
    appSecret: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.credentialType === CredentialType.TOKEN && !values.token) {
      ctx.addIssue({
        message: 'Token precisa ser preenchido',
        code: z.ZodIssueCode.custom,
        path: ['token'],
      });
    } else if (values.credentialType === CredentialType.USER_PASSWORD) {
      if(!values.user) {
        ctx.addIssue({
          message: 'Usuário precisa ser preenchido',
          code: z.ZodIssueCode.custom,
          path: ['user'],
        });
      }
      if(!values.password) {
        ctx.addIssue({
          message: 'Senha precisa ser preenchida',
          code: z.ZodIssueCode.custom,
          path: ['password'],
        });
      }
    } else if (values.credentialType === CredentialType.APP_ID) {
      if (!values.token) {
        ctx.addIssue({
          message: 'Token precisa ser preenchido',
          code: z.ZodIssueCode.custom,
          path: ['token'],
        });
      }
      if (!values.appId) {
        ctx.addIssue({
          message: 'App Id precisa ser preenchido',
          code: z.ZodIssueCode.custom,
          path: ['appId'],
        });
      }
      if (!values.appSecret) {
        ctx.addIssue({
          message: 'App Secret precisa ser preenchida',
          code: z.ZodIssueCode.custom,
          path: ['appSecret'],
        });
      }
    }
  });

export default credentialManagerSchema;
