import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import {AppRoutes} from './routing/AppRoutes';
import {setupAxios} from './modules/auth/axios';
import UserService from './modules/auth/UserService';
import createThemeGiaDefault from "./shared/datatable/gia-default.theme";

import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const renderApp = () => root.render(
  <React.StrictMode>
  <AppRoutes />
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}></PostHogProvider>
  </React.StrictMode>
);

setupAxios(axios);
UserService.initKeycloak(renderApp);

/* Init default datatable theme */
createThemeGiaDefault();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
