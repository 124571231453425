import {Button, Modal, Stack} from "react-bootstrap";
import CustomDatatable from "../custom-datatable/custom-datatable";
import React, {useEffect, useState} from "react";
import {TableColumn} from "react-data-table-component";
import SubHeaderComponent from "../subHeader/subHeader";
import { filterByFields } from "../filter/filter";
import SimplePlantInterface from "../../shared/interfaces/simple-plant-interface";

const columns: TableColumn<SimplePlantInterface>[] = [
  {
    name: 'Nome',
    selector: row => row.plantName,
    sortable: true,
    sortField: "plantName"
  },
  {
    name: 'Cliente',
    selector: row => row.customerName,
    sortable: true,
    sortField: "customerName"
  },
  {
    name: 'Documento',
    selector: row => row.document,
    sortable: true,
    sortField: "document"
  },
];

interface SelectModalTableProps {
  data: SimplePlantInterface[]
  selectedData: any[]
  show: boolean
  title: string
  entityName?: string | undefined
  onHide: () => void
  onSelectData: (selectedData: any[] | undefined) => void
  setSelectedRows: React.Dispatch<React.SetStateAction<any[] | undefined>>
  filterPlaceholder?: string
}

interface DatatableRowInterface extends SimplePlantInterface {} ;

export default function MultiplePlantsSelectModal({
                                             data,
                                             show,
                                             title,
                                             entityName,
                                             onHide,
                                             onSelectData,
                                             selectedData,
                                             setSelectedRows,
                                             filterPlaceholder
                                           }: SelectModalTableProps)
{
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [datasource, setDatasource] = useState<SimplePlantInterface[]>(data);
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const filteredItems:DatatableRowInterface[] = filterByFields(datasource, filterText, ["plantName", "customerName", "document"]);

  const [updateSelect, setUpdateSelect] = useState<SimplePlantInterface[]>(selectedData);

  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [sortField, setSortField] = useState('name');
  // const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {    
    const remainingData = data.filter(item => !selectedData.some(selectedItem => selectedItem.plantId === item.plantId));
    if (!show) return;
    setIsLoading(true);
    setDatasource(remainingData);
    //setTotalRows(data.length);
    setIsLoading(false);

  }, [data, selectedData, show, entityName, perPage, currentPage]);


  const handleSelectData = (selectedItems: SimplePlantInterface[]) => {
    setUpdateSelect(selectedItems);
    setShowValidationMessage(false);
  }
  
  const handleApply = () => {
    if (updateSelect && updateSelect.length > 0) {
      onSelectData([...selectedData, ...updateSelect]);
      close();
    }
    else {
      setShowValidationMessage(true);
    }
  }

  const close = () => {
    //setDatasource(remainingData());
    setFilterText('');
    // setCurrentPage(currentPage);
    // setTotalRows(data.length);

    onHide();
  }

  return (
    <Modal
      show={show}
      size="lg"
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: "1rem"}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <SubHeaderComponent
            filterText={filterText}
            resetPaginationToggle={resetPaginationToggle}
            setResetPaginationToggle={setResetPaginationToggle}
            setFilterText={setFilterText}
            placeholder={filterPlaceholder}
          />
        </div>
        <CustomDatatable
          columns={columns}
          filteredItems={filteredItems}
          filterText={filterText}
          setFilterText={setFilterText}
          isLoading={isLoading}
          selectableRows={true}
          //selectableRowSelected={defaultSelectedRows}
          onSelectedRowsChange={({selectedRows}) => handleSelectData(selectedRows as SimplePlantInterface[])}
          noDataText="Nenhuma usina restante"
          resetPaginationToggle={resetPaginationToggle}
          paginationDefaultPage={currentPage}
          onChangePage={setCurrentPage}
          paginationPerPage={perPage}
          onChangeRowsPerPage={setPerPage}
          onRowClicked={(row, e) => 
              {
                //Se clicar fora da checkbox tambem seleciona
                if (e.target.getAttribute('role') === 'cell') {
                  e.target
                    .parentElement 
                    ?.firstChild 
                    ?.firstChild 
                    ?.click();
                }
                else {
                  e.target
                    .parentElement 
                    ?.parentElement 
                    ?.firstChild 
                    ?.firstChild 
                    ?.click();
                }                
              }
          }
        />

        {showValidationMessage && (
          <Stack direction="horizontal" className="justify-content-end pt-4">
            <p className="gia-message-error">Necessário selecionar pelo menos um item</p>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer style={{borderTop: "unset"}} className="mt-1">
        <Button variant="primary" onClick={handleApply}>
          Selecionar
        </Button>
        <Button variant="secondary" onClick={close}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}