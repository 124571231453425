import axios from "axios";
import {SchedulerMessageInterface} from "../../shared/interfaces/scheduler-message-interface";

const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;

export function getAllSchedulerMessages() {
    return axios.get(`${baseURL}/schedulerMessage/managedSchedulerMessage`);
}

export function getSchedulerMessageById(id: string) {
    return axios.get(`${baseURL}/schedulerMessage/${id}`);
}

export function getSchedulerMessageTemplates() {
    return axios.get(`${baseURL}/schedulerMessage/templates`);
}

export function getSchedulerMessagePersonaTypes() {
    return axios.get(`${baseURL}/schedulerMessage/personaTypes`);
}

export function getSchedulerGroups() {
    return axios.get(`${baseURL}/schedulerMessage/schedulerGroups`);
}

export function createSchedulerMessage(data: SchedulerMessageInterface) {
    return axios.post(`${baseURL}/schedulerMessage`, data);
}

export function patchSchedulerMessage(data: SchedulerMessageInterface) {
    return axios.patch(`${baseURL}/schedulerMessage`, data);
}

export function deleteSchedulerMessageById(id: string) {
    return axios.delete(`${baseURL}/schedulerMessage/${id}`);
}
