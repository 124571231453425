import React, {FC} from 'react';
import styles from './progress-bar-with-percentage.module.scss';
import {ProgressBar} from 'react-bootstrap';
import {KpiColorType} from '../type/kpi-color.type';

interface ProgressBarWithPercentageProps {
  color: KpiColorType | undefined,
  percentage: number| undefined,
  width?: string
}

const ProgressBarWithPercentage: FC<ProgressBarWithPercentageProps> = ({color, percentage, width}) => (
  <div className={styles.ProgressBarWithPercentage}
       style={(width) ? {width} : undefined}
       data-testid="ProgressBarWithPercentage">
    <div className={`percentage-value text-${color}`}>{percentage}%</div>
    <div className={`progress-bar-wrapper`}>
      <ProgressBar now={percentage} variant={color}></ProgressBar>
    </div>
  </div>
);

export default ProgressBarWithPercentage;
