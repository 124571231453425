import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import MultiplePlantsSelectModal from "./multiple-plants-select-modal";
import CustomDatatable from "../custom-datatable/custom-datatable";
import SubHeaderComponent from "../subHeader/subHeader";
import { TableColumn } from "react-data-table-component";
import { filterByFields } from "../filter/filter";
import RobbuSchedulerGroupRequests from "../../pages/robbu-scheduler-group/_requests";
import CSS from 'csstype';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-regular-svg-icons";
import RobbuGroupWithPlantsContacts from "../../shared/interfaces/robbu-group-with-plants-interface";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface SelectModalProps {
  data: any[]
  selectedData?: RobbuGroupWithPlantsContacts[] | undefined
  onSelectData: (selectedData : RobbuGroupWithPlantsContacts[] | undefined) => void
  entityName?: string
  isInvalidData?: boolean
}

interface DatatableRowInterface extends RobbuGroupWithPlantsContacts {} ;

export default function MultiplePlantsSelect({ data, selectedData, onSelectData, entityName, isInvalidData = false }: Readonly<SelectModalProps>) {
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<RobbuGroupWithPlantsContacts[] | undefined>(selectedData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filterText, setFilterText] = useState<string>('');
  const filteredItems:DatatableRowInterface[] = filterByFields(selectedRows!!, filterText, ['plantName']);
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  const defaultRowStyle: CSS.Properties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '8px',
    WebkitLineClamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  };

  //Para centralizar as celulas
  const customStyles = {
    headCells: {
      style: {
        '&:first-child':{
            paddingLeft: "24px",
        },

        '&:nth-child(n + 3)': {
            paddingLeft: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "unset",
            margin: "unset",
            border: "unset",
        },
    },
    },
    cells: {
        style: {
            '&:nth-child(n + 3)': {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "unset",
                margin: "unset",
                border: "unset"
            },
        },
    },
};

  const defaultRowStringCell = (str: string) => {
    return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
  }

  const columns: TableColumn<DatatableRowInterface>[] = [
    {
        name: '',
        cell: row => <FontAwesomeIcon icon={faSquareMinus as IconProp} onClick={() => deleteRow(row)} />,
        width: '1%',  
        style: {
            '&:hover': {
              cursor: 'pointer',
            },
        }
    },
    {
        name: 'Usina',
        selector: row => row.plantName,
        sortable: true,
        cell: (row :any) => defaultRowStringCell(row.plantName),
    },
    {
        name: 'Email Cliente',
        selector: row => row.customerEmail ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.customerEmail,
              style: {
                  color: 'red',
              },
          },
      ],
    },
    {
        name: 'Tel. Cliente',
        selector: row => row.customerPhone ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.customerPhone,
              style: {
                  color: 'red',
              }
          },
      ],
    },
    {
        name: 'Email Integrador',
        selector: row => row.integratorEmail ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.integratorEmail,
              style: {
                  color: 'red',
              }
          },
      ],
    },
    {
        name: 'Tel. Integrador',
        selector: row => row.integratorPhone ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.integratorPhone,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Email Financiador',
        selector: row => row.financierEmail ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.financierEmail,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Tel. Financiador',
        selector: row => row.financierPhone ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.financierPhone,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Email Fabricante',
        selector: row => row.manufacturerEmail ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.manufacturerEmail,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Tel. Fabricante',
        selector: row => row.manufacturerPhone ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.manufacturerPhone,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Email Distribuidor',
        selector: row => row.distributorEmail ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.distributorEmail,
              style: {
                  color: 'red',
              }
          },
        ]
    },
    {
        name: 'Tel. Distribuidor',
        selector: row => row.distributorPhone ? 'OK' : 'NA',
        sortable: true,
        conditionalCellStyles: [
          {
              when: row => !row.distributorPhone,
              style: {
                  color: 'red',
              }
          },
        ]
    }
];

  const deleteRow = (row) => {
    const plantsWithoutRemoved:RobbuGroupWithPlantsContacts[] | undefined = selectedRows?.filter(plant => plant.plantId !== row.plantId);

    setSelectedRows(plantsWithoutRemoved)
    onSelectData(plantsWithoutRemoved);
  }

  useEffect(() => {
    setSelectedRows(selectedData);
  }, [data, selectedData]);


  const findPlantsContactsByPlantsIds = async (plantsIds) => {
    try {
      const { data } = await RobbuSchedulerGroupRequests.findPlantsContactsByIds(plantsIds);
      return data;
    } catch (error) {
      console.log(error);
      console.log("Erro ao obter dados das Plantas: " + error);
    }
  };

  const handleSelectData = async (selected: any[] | undefined) => {
    setIsLoading(true);
    const selectedPlantsIds = selected?.map(plant => plant.plantId);
    const plantsWithContacts = await findPlantsContactsByPlantsIds(selectedPlantsIds);

    setSelectedRows(plantsWithContacts);
    onSelectData(plantsWithContacts);

    setIsLoading(false);
  }

  return (
    <>
        <div className='col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-2'>
          <label className='label mb-1 required' style={{display: "block"}}>{`${entityName}`}</label>
          {isInvalidData && <p className="gia-message-error">{`Necessário selecionar um item`}</p>}

            <Button
              size='sm'
              type="button"
              className='mt-2'
              variant='outline-light'
              onClick={() => {
                setShowSelectModal(true)
              }}
            >
                {`Selecione as ${entityName}`}
            </Button>

          <MultiplePlantsSelectModal
            data={data}
            show={showSelectModal}
            title={`Selecione o item`}
            entityName={entityName}
            onHide={() => setShowSelectModal(false)}
            onSelectData={handleSelectData}
            selectedData={selectedRows!!}
            setSelectedRows={setSelectedRows}
            filterPlaceholder="Pesquisar Usinas"
          />
        </div>

        {selectedRows && 
          <>
          <div className="container-fluid g-0 mb-3">
            <div className="row">
              <div className="col-sm-12 d-flex">
                {selectedRows && <SubHeaderComponent placeholder="Pesquisar Usinas" filterText={filterText} resetPaginationToggle={resetPaginationToggle} setResetPaginationToggle={setResetPaginationToggle} setFilterText={setFilterText}/>}
              </div>
            </div>
          </div>
          <CustomDatatable 
            columns={columns} 
            filteredItems={filteredItems}
            filterText={filterText} 
            setFilterText={setFilterText} 
            isLoading={isLoading} 
            selectableRows={false} 
            resetPaginationToggle={resetPaginationToggle}
            noDataText="Nenhuma usina selecionada"
            customStyles={customStyles}
            pointerOnHover={false}
            />
          </>
          }
    </>
  );
}
