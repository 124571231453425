import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './persona.module.scss'
import { FilterOptionsInterface, MultipleFilterComponent } from '../../components/filter/filter';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import CustomDatatable from '../../components/custom-datatable/custom-datatable';
import { maskDocument } from '../../shared/helpers/string-utils.helper';
import { PersonaTypeEnum, PersonaTypeInterface } from '../../shared/interfaces/persona-type-interface';
import Tags, { TagsSelectedInterface } from '../../components/tags/tags';
import { loadPersonaList } from './_request';
import PersonaInterface from '../../shared/interfaces/persona.interface';


export default function Persona() {

    const filterOptions: FilterOptionsInterface[] = useMemo(() => [
        {
            "text": "Nome",
            "field": "name"
        },
        {
            "text": "Documento",
            "field": "document"
        },
        {
            "text": "E-mail",
            "field": "email"
        },
    ], []);

    const columns: TableColumn<any>[] = [
        {
            name: 'Nome',
            selector: row => row.name,
            sortable: true,
            sortField: "legalEntity.name"
        },
        {
            name: 'Tipo',
            selector: row => row.type,
            sortable: true,
            sortField: "description"
        },
        {
            name: 'Documento',
            selector: row => maskDocument(row.document),
            sortable: true,
            sortField: "legalEntity.document"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            sortField: "legalEntity.email"
        },

    ];

    type sortDirectionType = 'asc' | 'desc';

    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [sortField, setSortField] = useState<string>('legalEntity.name');
    const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc');

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [filterText, setFilterText] = useState('');
    const [currentType, setCurrentType] = useState<keyof typeof PersonaTypeEnum>('ALL');
    const [filterSelect, setFilterSelect] = useState<string>('name');
    const [applyFilter, setApplyFilter] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState<TagsSelectedInterface[]>([]);
    const [filteredItems, setFilteredItems] = useState<PersonaInterface[]>([]);
    const [notifyChange, setNotifyChange] = useState(false);


    const personaTypes: PersonaTypeInterface[] = useMemo(() => [
        {
            type: "CUSTOMER",
            name: PersonaTypeEnum.CUSTOMER
        },
        {
            type: "DISTRIBUTOR",
            name: PersonaTypeEnum.DISTRIBUTOR
        },
        {
            type: "MANUFACTURER",
            name: PersonaTypeEnum.MANUFACTURER
        },
        {
            type: "FINANCIER",
            name: PersonaTypeEnum.FINANCIER
        },
        {
            type: "INTEGRATOR",
            name: PersonaTypeEnum.INTEGRATOR
        },
    ], []);

    const normalizeDocument = useCallback((document) => {
        if (document !== undefined && document !== null) {
            return document.replace(/[.,\-/*_]/g, "").toLowerCase();
        }
        return "";
    }, []);

    const normalizeFilters = useCallback((filters) => {
        let filterParam = {} as any;

        filters.forEach((item) => {
            if (item.option === 'document') {
                item.value = normalizeDocument(item.value)
            }
            filterParam[item.option] = item.value;
        });

        const filterOptions = {
          legalEntity: filterParam
        };

        return '&filterOptions=' + encodeURI(JSON.stringify(filterOptions));
    }, [normalizeDocument]);

    const normalizePagination = useCallback((currentPage: number, size: number, sort: string, sortDirection: sortDirectionType) => {

        if(sort == undefined){
            sort = "name";
        }

        return `page=${currentPage}&size=${size}&sort=${sort},${sortDirection}`;
    }, []);

    const normalizeType = useCallback((type: string) => {
        return `&type=${type}`
    }, [])

    const convertResponse = (data: any[]): PersonaInterface[] => {
        return data.map(r => {
            return {
                "id": r.id,
                "name": r.legalEntity.name,
                "type": r.description,
                "document": r.legalEntity.document,
                "email": r.legalEntity.email,
                "zipCode": r.zipCode
            }
        });
    }

    //TODO this useEffect is triggered twice or more because changes on currentPage and other dependencies
    // this need to be fixed to improve performance
    useEffect(() => {
        const sort = sortField == undefined ? "name" : sortField;
        const filtersParam = normalizeFilters(appliedFilters);
        const paginationParam = normalizePagination(currentPage - 1, perPage, sort, sortDirection);
        const typeParam = normalizeType(currentType);

        loadPersonaList(paginationParam, typeParam, filtersParam).then(response => {
            const data = response.data;

            setFilteredItems(convertResponse(data['content']));
            setTotalPages(data['totalElements'])
            setIsLoading(false);

        })

    }, [applyFilter, appliedFilters, currentPage, perPage, sortDirection, sortField, currentType, notifyChange, normalizeFilters, normalizePagination, normalizeType])

    const navigate = useNavigate();


    const subHeaderComponentMemo = useMemo(() => {
        const goToNewPersona = () => {
            navigate(`/persona/edit/`);
        };
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
            }
        };

        const handleText = (event) => {
            setApplyFilter(false);
            setFilterText(event);
        }

        const normalizeOption = (option) => {
            const index = appliedFilters.findIndex(af => {
                return af.option === option.field
            });

            if (index > -1) {
                appliedFilters.splice(index, 1)
            }

            return appliedFilters
        }

        const submitFilter = () => {
            if (filterText.length === 0) {
                return;
            }

            const option = filterOptions.filter(op => op.field === filterSelect);
            const newFilter = normalizeOption(option[0]);
            newFilter.push({ option: option[0].field, text: option[0].text, value: filterText });


            setAppliedFilters(newFilter)

            setApplyFilter(true);
            setFilterText("")
        }


        const changeTags = (tag) => {
            setNotifyChange(!notifyChange);
            setApplyFilter(false);
            setAppliedFilters(tag);

        }

        const onHandleType = (e: ChangeEvent<HTMLSelectElement>) => {
            setCurrentType(e.target.value as keyof typeof PersonaTypeEnum)
        }

        return (
            <div className="container-fluid g-0 mb-3" key={`filterBar`}>
                <div className="row">
                    <div className="d-flex">
                        {
                            <div className='me-4' >
                                <Button type="button" variant="primary" size="sm" onClick={goToNewPersona}>Nova Persona</Button>
                            </div>
                        }
                        <div className='me-3' style={{ minWidth: '130px' }}>
                            <Form.Select value={currentType} onChange={onHandleType}>
                                <option value={'ALL'} >{currentType === 'ALL' ? 'Filtrar por tipo' : 'Todos'}</option>
                                {personaTypes.map((value, i) => {
                                    return <option key={i} value={value.type}>{value.name}</option>
                                })}
                            </Form.Select>
                        </div>
                        <MultipleFilterComponent
                            onFilter={(e: any) => handleText(e.target.value)}
                            onClear={handleClear} filterText={filterText}
                            options={filterOptions}
                            OnFilterSelect={(e) => setFilterSelect(e.target.value)}
                            filterSelect={filterSelect}
                            submitFitler={submitFilter} />
                    </div>
                    {appliedFilters.length > 0 && <div className='mt-3'>
                        <Tags selected={appliedFilters} changeTags={(tag) => changeTags(tag)}></Tags>
                    </div>}
                </div>
            </div>
        );
    }, [navigate, resetPaginationToggle, filterText, filterSelect, filterOptions, appliedFilters, currentType, personaTypes, notifyChange]);


    const onSort = (rows, direction: sortDirectionType) => {
      if(rows.sortField) {
        setSortField(rows.sortField)
      }
      setSortDirection(direction)
    }

    const onClicked = (row:PersonaInterface) => {
        navigate(`/persona/edit/${row.id}`)
    }

    return (
        <div className={styles.Persona}>
            <div className="gia-page-title-default">Persona</div>
            {subHeaderComponentMemo}
            {<CustomDatatable
                columns={columns}
                filteredItems={filteredItems}
                filterText={filterText}
                setFilterText={setFilterText}
                isLoading={isLoading}
                selectableRows={false}
                selectableRowsSingle={false}
                resetPaginationToggle={resetPaginationToggle}
                paginationServer
                paginationTotalRows={totalPages}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={setPerPage}
                onChangePage={setCurrentPage}
                sortServer={true}
                onSort={onSort}
                onRowClicked={onClicked}
            />}
        </div>
    );
}
