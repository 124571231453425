import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './managed-plants.module.scss';
import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import ProgressBarWithPercentage from '../../shared/progress-bar-with-percentage/progress-bar-with-percentage';
import {calculateKpiPercentageColor} from '../../shared/helpers/kpi.helper';
import axios from 'axios';
import GiaNumberFormat from '../../shared/components/number-format/gia-number-format';
import EnergyGeneratedAtPlantSummaryLastPeriodsInterface
  from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-summary-last-periods.interface';
import {Button, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilterCircleXmark, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import CSS from 'csstype';
import {removeDiacritics} from '../../shared/helpers/string-utils.helper';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
import 'moment/locale/pt-br';
import paginationComponentOptions from "../../shared/datatable/pagination-component.options";
import {useNavigate} from "react-router-dom";
import UserService from "../../modules/auth/UserService";
import {Roles} from "../../modules/auth/roles";
import {FilterOptionsInterface, MultipleFilterComponent} from '../../components/filter/filter';
import {Tags, TagsSelectedInterface} from '../../components/tags/tags';
import { rmSync } from 'fs';


moment.locale('pt-br');

/* INTERFACES */
interface ManagedPlantsProps { }

interface FiltersInterface {
  onlyActive?: boolean,
  onlyShutDown?: boolean,
  tags?: TagsSelectedInterface[],
  tableState: TableState
}

interface DatatableRowInterface {
  id: number,
  plantName: string,
  client: string,
  integrator: string,
  distributor?: string,
  manufacturer?: string,
  datalogger?: string,
  device?: string,
  active: boolean
  city: string,
  state: string,
  peakPower: number,
  weather: {
    temperature: number,
    icon: string
  },
  status: number,
  statusMonitoring: string,
  subStatusMonitoring: string,
  situationMonitoring: string,
  shutdown: number,
  shutdownReason: string,
  document: string,
  lastPeriodsPercentage: EnergyGeneratedAtPlantSummaryLastPeriodsInterface
}

interface TableState {
  paginationDefaultPage: number,
  defaultSortAsc: boolean,
  defaultSortFieldId: string,
  paginationPerPage: number
}

const defaultTableState = {
  paginationDefaultPage: 1,
  defaultSortAsc: false,
  defaultSortFieldId: 'today',
  paginationPerPage: 10
} as TableState;

/* FILTER STORAGE */
const FILTER_STORAGE_KEY = 'gia.mp.filters';
export function clearManagedPlantsFilters() {
  window.localStorage.removeItem(FILTER_STORAGE_KEY);
}

const ManagedPlants: FC<ManagedPlantsProps> = () => {

  /* DATATABLE DEFINITIONS */
  const progressComponent = <div style={{ width: '100%', paddingBottom: '.25rem' }}><Skeleton height={45} count={10} /></div>;
  const basicSort = (a: number, b: number) => {
    return (a > b) ? 1 : (b > a) ? -1 : 0;
  };

  const basicSortStringNoCase = (a: string, b: string) => {
    const aToCompare = removeDiacritics(a).toLowerCase();
    const bToCompare = removeDiacritics(b).toLowerCase();
    return (aToCompare > bToCompare) ? 1 : (bToCompare > aToCompare) ? -1 : 0;
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => row.lastPeriodsPercentage.today.value === 0 || row.status === 0,
      style: {
        backgroundColor: '#FEF0F0',
        color: '#D82424'
      }
    },
    {
      when: row => !row.active,
      style: {
        backgroundColor: '#dbdbdb',
      },
    },

  ];

  const convertResponse = (response: any) => {
    return response
      .map((r: any) => {
        return {
          id: r.plant_id,
          plantName: r.plant_name,
          projectId: r.project_id,
          client: r.name,
          integrator: r.integrator,
          distributor: r.distributor,
          active: r.active,
          datalogger: r.datalogger_sn,
          device: r.device_sn,
          manufacturer: r.manufacturer,
          city: r.city,
          document: r.document,
          state: r.uf,
          lastCollected: r.last_collected,
          statusMonitoring: r.status_monitoring,
          subStatusMonitoring: r.sub_status_monitoring,
          situationMonitoring: r.situation_monitoring,
          peakPower: +r.peak_power,
          weather: {
            temperature: r.weather_temp,
            icon: r.weather_icon
          },
          status: r.status,
          shutdown: r.status_monitoring == "Indisponível" ? 1 : 0,
          shutdownReason: r.shutdownReason,
          lastPeriodsPercentage: {
            today: {
              color: calculateKpiPercentageColor(r.power_today_pct),
              value: r.power_today_pct,
            },
            yesterday: {
              color: calculateKpiPercentageColor(r.power_yesterday_pct),
              value: r.power_yesterday_pct,
            },
            last15Days: {
              color: calculateKpiPercentageColor(r.power_d15_pct),
              value: r.power_d15_pct,
            },
            lastMonth: {
              color: calculateKpiPercentageColor(r.power_d30_pct),
              value: r.power_d30_pct,
            },
          }
        } as DatatableRowInterface;
      });
  }

  const filterOptions: FilterOptionsInterface[] = useMemo(() => [
    {
      "text": "Cliente",
      "field": "client"
    },
    {
      "text": "Documento",
      "field": "document"
    },
    {
      "text": "Integrador",
      "field": "integrator"
    },
    {
      "text": "Fabricante",
      "field": "manufacturer"
    },
    {
      "text": "Distribuidor",
      "field": "distributor"
    },
    {
      "text": "Inversor",
      "field": "device"
    },
    {
      "text": "Datalogger",
      "field": "datalogger"
    },
    {
      "text": "Cidade",
      "field": "city"
    },
    {
      "text": "Estado/UF",
      "field": "state"
    },
    {
      "text": "Status",
      "field": "statusMonitoring"
    },
    {
      "text": "Sub-status",
      "field": "subStatusMonitoring"
    },
    {
      "text": "Situação",
      "field": "situationMonitoring"
    },
    {
      "text": "ID do Projeto",
      "field": "projectId"
    },
  ], []);

  const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onlyActive, setOnlyActive] = useState(true);
  const [onlyShutDown, setOnlyShutDown] = useState(false);
  const [tableState, setTableState] = useState<TableState>({...defaultTableState});
  const [filterSelect, setFilterSelect] = useState<string>(filterOptions[0].field);
  const [applyFilter, setApplyFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<TagsSelectedInterface[]>([]);
  const [filteredItems, setFilteredItems] = useState<DatatableRowInterface[]>([]);
  const [notifyChange, setNotifyChange] = useState(false);

  const hasRolePlantEditor = UserService.hasRole([Roles.PLANT_EDITOR]);
  const hasRolePlantsShutdown = UserService.hasRole([Roles.PLANTS_SHUTDOWN]);
  const navigate = useNavigate();

  const onChangePage = useCallback((page: number, totalRows: number)  => {
    setTableState(prevState => ({...prevState, paginationDefaultPage: page} as TableState));
  },[setTableState]);

  const onSort = useCallback((selectedColumn: TableColumn<DatatableRowInterface>, sortDirection: SortOrder, sortedRows: DatatableRowInterface[]) => {
    setTableState(prevState => ({
      ...prevState,
      defaultSortAsc: sortDirection === 'asc',
      defaultSortFieldId: selectedColumn.id
    } as TableState));
  },[setTableState]);

  const onChangeRowsPerPage = useCallback((currentRowsPerPage: number, currentPage: number) => {
    setTableState(prevState => ({
      ...prevState,
      paginationPerPage: currentRowsPerPage,
      paginationDefaultPage: currentPage,
    } as TableState));
  },[setTableState]);

  // Load stored filters
  useEffect(() => {
    const filtersString = window.localStorage.getItem(FILTER_STORAGE_KEY);
    if(filtersString) {
      const filters = JSON.parse(filtersString) as FiltersInterface;
      setOnlyActive(filters.onlyActive === true);
      setOnlyShutDown(filters.onlyShutDown === true);
      if(filters.tableState) {
        setTableState(filters.tableState);
      }
      filters?.tags && setAppliedFilters(filters.tags);
    }
  }, []);

  const onRowClicked = (row: any) => {
    window.open(`managed-plants/plant/${row.id}`, '_blank');
  };

  const columns = useMemo(() => {

    const defaultRowStyle: CSS.Properties = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '8px',
      WebkitLineClamp: 1,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    };

    const defaultRowStringCell = (str: string) => {
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
    }

    const defaultRowStringCellTooltip = (str: string, tooltip: string) => {
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={tooltip}>{str}</span>;
    }

    const datetimeRowStringCell = (str: string) => {
      const strDatetime = (str) ? moment(str).format('L HH:mm') : '-';
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={strDatetime}>{strDatetime}</span>;
    }

    const edit = (row: any) => {
      navigate(`/managed-plants/plant/edit/${row.id}`);
    };

    const columnsArray = [
      {
        id: 'client',
        name: 'Cliente',
        selector: (row: any) => row.client,
        sortable: true,
        width: hasRolePlantEditor ? '15%' : '16%',
        cell: (row: any) => defaultRowStringCell(row.client),
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.client, rowB.client),
      },
      {
        id: 'integrator',
        name: 'Integrador',
        selector: (row: any) => row.integrator,
        sortable: true,
        width: hasRolePlantEditor ? '11%' : '12%',
        cell: (row: any) => defaultRowStringCell(row.integrator),
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.integrator, rowB.integrator),
      },
      {
        id: 'city',
        name: 'Cidade',
        selector: (row: any) => row.city,
        sortable: true,
        width: '8%',
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.city, rowB.city),
        cell: row => defaultRowStringCell(row.city),
      },
      {
        id: 'state',
        name: 'UF',
        selector: (row: any) => row.state,
        sortable: true,
        width: '5%',
        cell: (row: any) => defaultRowStringCell(row.state),
      },
      {
        id: 'lastCollected',
        name: 'Últ. Coleta',
        selector: (row: any) => row.lastCollected,
        sortable: true,
        width: '8%',
        cell: (row: any) => datetimeRowStringCell(row.lastCollected),
      },
      {
        id: 'statusMonitoring',
        name: 'Status',
        selector: (row: any) => row.statusMonitoring,
        sortable: true,
        width: '7%',
        cell: (row: any) => row.statusMonitoring != 'Indisponível' ? defaultRowStringCell(row.statusMonitoring) : defaultRowStringCellTooltip(row.statusMonitoring, row.shutdownReason),
      },
      {
        id: 'subStatusMonitoring',
        name: 'Sub-status',
        selector: (row: any) => row.subStatusMonitoring,
        sortable: true,
        width: '7%',
        cell: (row: any) => row.subStatusMonitoring != 'Indisponível' ? defaultRowStringCell(row.subStatusMonitoring) : defaultRowStringCellTooltip(row.subStatusMonitoring, row.shutdownReason),
      },
      {
        id: 'situationMonitoring',
        name: 'Situação',
        selector: (row: any) => row.situationMonitoring,
        sortable: true,
        width: '7%',
        cell: (row: any) => row.situationMonitoring != 'Indisponível' ? defaultRowStringCell(row.situationMonitoring) : defaultRowStringCellTooltip(row.situationMonitoring, row.shutdownReason),
      },
      {
        id: 'peakPower',
        name: 'Potência',
        selector: (row: any) => row.peakPower,
        sortable: true,
        style: {
          justifyContent: 'flex-start'
        },
        cell: (row: any) => <span data-tag="allowRowEvents" style={defaultRowStyle}><GiaNumberFormat value={row.peakPower} decimalPlaces={2} suffix="kWp" /></span>,
        width: '8%'
      },
      {
        id: 'today',
        name: 'Hoje',
        selector: (row: any) => row.lastPeriodsPercentage,
        cell: (row: any) => <ProgressBarWithPercentage width="42px" color={row.lastPeriodsPercentage.today.color} percentage={row.lastPeriodsPercentage.today.value} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => basicSort(rowA.lastPeriodsPercentage.today.value, rowB.lastPeriodsPercentage.today.value),
        width: '5%'
      },
      {
        id: 'yesterday',
        name: 'Ontem',
        selector: (row: any) => row.lastPeriodsPercentage.yesterday,
        cell: (row: any) => <ProgressBarWithPercentage width="42px" color={row.lastPeriodsPercentage.yesterday.color} percentage={row.lastPeriodsPercentage.yesterday.value} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => basicSort(rowA.lastPeriodsPercentage.yesterday.value, rowB.lastPeriodsPercentage.yesterday.value),
        width: '5%'
      },
      {
        id: 'last15Days',
        name: 'Quinzena',
        selector: (row: any) => row.lastPeriodsPercentage.last15Days,
        cell: (row: any) => <ProgressBarWithPercentage width="42px" color={row.lastPeriodsPercentage.last15Days.color} percentage={row.lastPeriodsPercentage.last15Days.value} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => basicSort(rowA.lastPeriodsPercentage.last15Days.value, rowB.lastPeriodsPercentage.last15Days.value),
        width: '5%'
      },
      {
        id: 'lastMonth',
        name: '30 dias',
        selector: (row: any) => row.lastPeriodsPercentage.lastMonth,
        cell: (row: any) => <ProgressBarWithPercentage width="42px" color={row.lastPeriodsPercentage.lastMonth.color} percentage={row.lastPeriodsPercentage.lastMonth.value} />,
        sortable: true,
        sortFunction: (rowA: any, rowB: any) => basicSort(rowA.lastPeriodsPercentage.lastMonth.value, rowB.lastPeriodsPercentage.lastMonth.value),
        width: '5%'
      }
    ];

    if (hasRolePlantEditor) {
      columnsArray.push({
        name: '',
        cell: (row: any) => <FontAwesomeIcon size="lg" icon={faPenToSquare} onClick={() => edit(row)} />,
        width: '2%'
      } as any);
    }

    return columnsArray.map(c => ({ ...c, minWidth: c.width, maxWidth: c.width, compact: true }));
  }, [navigate, hasRolePlantEditor]);

  const textMatch = useCallback((value, filterTextToCompare) => {
    if (value !== undefined && value !== null) {
      return removeDiacritics(value).toLowerCase().includes(filterTextToCompare);
    }
    return false;
  }, []);

  const normalizeDocument = useCallback((document) => {
    if (document !== undefined && document !== null) {
      return document.replace(/[.,\-/*_]/g, "").toLowerCase();
    }
    return "";
  }, []);

  const documentMatch = useCallback((document, filterTextToCompare) => {
    if (document !== undefined && document !== null) {
      const normalizedDocument = normalizeDocument(document);
      const normalizedFilteredText = normalizeDocument(filterTextToCompare);
      return removeDiacritics(normalizedDocument).toLowerCase().includes(normalizedFilteredText);
    }
    return false;
  }, [normalizeDocument]);

  const statusMatch = useCallback((item) => {
    return onlyActive ? !!item : true;
  }, [onlyActive]);

  const shutDownMatch = useCallback((item) => {
    return onlyShutDown ? !!item : true;
  }, [onlyShutDown]);

  const withOptions = useCallback(() => {
    return datasource.filter(item => {
      const matchList = appliedFilters.map(element => {
        let match = false;
        switch (element.option) {
          case 'document':
            match = statusMatch(item.active) && shutDownMatch(item.shutdown) && documentMatch(item[element.option], element.value.toLowerCase());
            break;
          default:
            let textFilter = element.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");//Remove acentos para busca
            match = statusMatch(item.active) && shutDownMatch(item.shutdown) && textMatch(item[element.option], textFilter);
            break;
        }
        return match;
      });
      return matchList.every(m => m === true);
    });
  }, [appliedFilters, datasource, documentMatch, statusMatch, shutDownMatch, textMatch]);

  const onlyActiveFilter = useCallback(() => {
    return datasource.filter(item => {
      return statusMatch(item.active);
    });
  }, [datasource, statusMatch]);

  const onlyShutDownFilter = useCallback(() => {
    return datasource.filter(item => {
      return shutDownMatch(item.shutdown);
    });
  }, [datasource, shutDownMatch]);

  useEffect(() => {
    if (applyFilter || appliedFilters.length > 0) {
      setFilteredItems(withOptions());
    } else {
      setFilteredItems(onlyActiveFilter());
      setFilteredItems(onlyShutDownFilter());
    }
  }, [appliedFilters, applyFilter, withOptions, onlyActiveFilter, onlyShutDownFilter, notifyChange]);

  // Store filters when modified
  useEffect(() => {
    const filtersToStore = {
      onlyActive: onlyActive,
      onlyShutDown: onlyShutDown,
      tags: appliedFilters,
      tableState: tableState
    } as FiltersInterface;
    window.localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filtersToStore));
  }, [appliedFilters, onlyActive, onlyShutDown, tableState]);

  const clearFilters = () => {
    const filters = {
      onlyActive: true,
      onlyShutDown: false,
      tags: [],
      tableState: {...defaultTableState}
    } as FiltersInterface;
    setOnlyActive(filters.onlyActive === true);
    setOnlyShutDown(filters.onlyShutDown === true);
    setTableState(filters.tableState);
    filters?.tags && setAppliedFilters(filters.tags);
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 250);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const goToNewPlant = () => {
      navigate(`/managed-plants/plant/edit`);
    };
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    const handleText = (event) => {
      setApplyFilter(false);
      setFilterText(event);
    }

    const normalizeOption = (option) => {
      const index = appliedFilters.findIndex(af => {
        return af.option === option.field
      });

      if (index > -1) {
        appliedFilters.splice(index, 1);
      }

      return appliedFilters;
    }

    const submitFilter = () => {
      if (filterText.length === 0) {
        return;
      }
      const options = filterOptions.filter(op => op.field === filterSelect);
      const option = options[0];
      const filters = [...normalizeOption(option)];
      filters.push({ 'option': option.field, 'text': option.text, 'value': filterText });
      setAppliedFilters(filters)

      setApplyFilter(true);
      setFilterText('');
    }


    const changeTags = (tags: TagsSelectedInterface[]) => {
      setNotifyChange(!notifyChange);
      setApplyFilter(false);
      setAppliedFilters([...tags]);
    }

    return (
      <div className="container-fluid g-0 mb-3" key={`filterBar`}>
        <div className="row">
          <div className="d-flex">
            {hasRolePlantEditor &&
              <div className='me-3'>
                <Button type="button" variant="primary" size="sm" onClick={goToNewPlant}>Nova Usina</Button>
              </div>
            }
            <div className={`${styles.containerActive} me-3`} >
              <Form.Select value={Number(onlyActive)} onChange={() => setOnlyActive(!onlyActive)} >
                <option value={1}>Ativas</option>
                <option value={0}>Todas</option>
              </Form.Select>
            </div>
            {hasRolePlantsShutdown &&
            <div className={`${styles.containerActive} me-3`} >
              <Form.Select value={Number(onlyShutDown)} onChange={() => setOnlyShutDown(!onlyShutDown)} >
                <option value={0}>Todas</option>
                <option value={1}>Indisponível</option>
              </Form.Select>
            </div>
            }
            <MultipleFilterComponent
              onFilter={(e: any) => handleText(e.target.value)}
              onClear={handleClear} filterText={filterText}
              options={filterOptions}
              OnFilterSelect={(e) => setFilterSelect(e.target.value)}
              filterSelect={filterSelect}
              submitFitler={submitFilter} />
            <div className='ms-3'>
              <Button type="button" variant="outline-secondary" size="sm" onClick={clearFilters} title="Limpar Filtros"><FontAwesomeIcon icon={faFilterCircleXmark} fixedWidth={true} size="lg" /> Limpar</Button>
            </div>
          </div>
          {appliedFilters.length > 0 && <div className='mt-3'>
            <Tags selected={appliedFilters} changeTags={(tag) => changeTags(tag)}></Tags>
          </div>}
        </div>
      </div>
    );
  }, [navigate, resetPaginationToggle, filterText, hasRolePlantEditor, hasRolePlantsShutdown, onlyActive, onlyShutDown, filterSelect, filterOptions, appliedFilters, notifyChange]);


  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/plant/managedPlants`;
    axios.get(url).then(response => setDatasource(convertResponse(response.data))).then(() => setIsLoading(false));
  }, []);

  let paginationRowsPerPageOptions = [10, 25, 50, 100];

  if(filteredItems.length > 200 && filteredItems.length <= 500){

    paginationRowsPerPageOptions = [10, 25, 50, 100, 200];
  }

  if(filteredItems.length > 500 && filteredItems.length <= 1000){

    paginationRowsPerPageOptions = [10, 25, 50, 100, 200, 500];
  }

  if(filteredItems.length > 1000 && filteredItems.length <= 5000){

    paginationRowsPerPageOptions = [10, 25, 50, 100, 200, 500, 1000];
  }

  if(filteredItems.length > 5000){

    paginationRowsPerPageOptions = [10, 25, 50, 100, 200, 500, 1000, 5000];
  }

  return (
    <div className={styles.ManagedPlants} data-testid="ManagedPlants">

      <div className="gia-page-title-default">Usinas</div>

      {isLoading && <Skeleton height={48} />}
      {!isLoading && subHeaderComponentMemo}

      {isLoading && <Skeleton height={'50vh'} />}
      {!isLoading &&
        <div className={`${styles.datatableWrapper} gia-datatable-wrapper`}>
          <DataTable
            pointerOnHover={true}
            onRowClicked={onRowClicked}
            columns={columns}
            data={filteredItems}
            noDataComponent={<><span className="gia-datatable-no-results">Nenhum registro</span></>}
            fixedHeader={true}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            className="gia-datatable"
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            persistTableHead
            conditionalRowStyles={conditionalRowStyles}
            progressPending={isLoading}
            progressComponent={progressComponent}
            theme="gia-default"
            paginationDefaultPage={tableState.paginationDefaultPage}
            defaultSortAsc={tableState.defaultSortAsc}
            defaultSortFieldId={tableState.defaultSortFieldId}
            paginationPerPage={tableState.paginationPerPage}
            onChangePage={onChangePage}
            onSort={onSort}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      }
    </div>
  );
};

export default ManagedPlants;
