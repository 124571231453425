import React, {FC, useCallback, useState,} from "react";
import styles from "./image-modal.module.scss";
import {Button, Modal} from "react-bootstrap";
import {ImageModalProps} from "./image-modal-interface";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import FormRange from "react-bootstrap/esm/FormRange";

const ImageModal: FC<ImageModalProps> = ({ ...props }) => {
  const [showModal, setShowModal] = useState(props.show);
  const [img, setImg] = useState(props.srcImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const showCroppedImage = useCallback(async () => {
    try {
      if (!img || !props.notifyClose) {
        return;
      }
      const croppedImage = await getCroppedImg(img, croppedAreaPixels, 0);

      props.notifyClose({
        show: false,
        srcImage: croppedImage ? croppedImage : props.srcImage,
      });
      setShowModal(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const closeModal = () => {
    setShowModal(false);
    if (!props.notifyClose) {
      return;
    }
    props.notifyClose({
      show: false,
    });
  };

  return (
    <Modal
      show={showModal}
      centered
      className={styles.ImageModal}
      onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Ajustar imagem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.modalBody}>
          <Cropper
            image={img}
            crop={crop}
            zoom={zoom}
            aspect={1}
            objectFit="horizontal-cover"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            classes={{
              containerClassName: styles.container,
            }}
          />
        </div>
        <div>
          <div className={`mt-4 ${styles.containerZoom}`}>
            <span>Zoom</span>
            <FormRange
              onChange={(e) => setZoom(Number(e.target.value))}
              min={1}
              value={zoom}
              max={3}
              step={0.1}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} style={{ backgroundColor: "#efefef" }}>Fechar</Button>
        <Button variant="primary" onClick={showCroppedImage}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
