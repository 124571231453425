import {Button, Modal, Stack} from "react-bootstrap";
import CustomDatatable from "../custom-datatable/custom-datatable";
import React, {useCallback, useEffect, useRef, useState} from "react";
import SimplePersonaInterface from "../../shared/interfaces/simple-persona.interface";
import {SortOrder, TableColumn} from "react-data-table-component";
import SubHeaderComponent from "../subHeader/subHeader";
import {PersonaTypeEnum} from "../../shared/interfaces/persona-type-interface";
import axios from "axios";
import {toast} from "react-toastify";

const columns: TableColumn<SimplePersonaInterface>[] = [
  {
    name: 'Nome',
    selector: row => row.name,
    sortable: true,
    sortField: "legalEntity.name"
  }
];

interface PersonaSelectModalProps {
  show: boolean
  title: string
  personaType: PersonaTypeEnum
  onHide: () => void
  onSelectPersona: (persona: SimplePersonaInterface | undefined) => void
}

export default function PersonaSelectModal({
                                             show,
                                             title,
                                             personaType,
                                             onHide,
                                             onSelectPersona
                                           }: PersonaSelectModalProps)
{
  const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [datasource, setDatasource] = useState<SimplePersonaInterface[]>([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
  const [selectedPersona, setSelectedPersona] = useState<SimplePersonaInterface>();
  const [filterText, setFilterText] = useState<string>('');
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  // Estados relacionados com a paginação
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Estados relacionados com a ordenação de campos
  const [sortField, setSortField] = useState('legalEntity.name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  // Armazena a referência do timer que executará a função de busca assim que o usuário terminar de digitar
  const searchDebounceRef = useRef<{timeout: NodeJS.Timeout | undefined}>({ timeout: undefined });

  const fetchPersona = useCallback((filter: string | undefined = undefined) => {
    setIsLoading(true);

    const page = currentPage - 1;
    let apiURL = `${baseURL}/persona/${personaType}?page=${page}&size=${perPage}&sort=${sortField},${sortDirection}`;

    if (filter && filter.trim() !== "") {
      apiURL = `${apiURL}&name=${encodeURI(filter.trim())}`;
    }

    axios.get(apiURL)
      .then(response => {
        setDatasource(response.data["content"]);
        setTotalRows(response.data["totalElements"])
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        toast.error("Erro ao carregar tipos");
      });
  }, [currentPage, perPage, sortField, sortDirection, baseURL, personaType]);

  // Usado no fetch inicial do modal
  useEffect(() => {
    if (!show) return;
    fetchPersona();
  }, [show, currentPage, perPage, sortField, sortDirection, baseURL, personaType, fetchPersona]);

  // Usado quando há um filtro
  useEffect(() => {
    if (!show) return;

    if (searchDebounceRef.current.timeout)
      clearTimeout(searchDebounceRef.current.timeout);

    // Somente chama a função de pesquisa 1 segundo após o usuário terminar de digitar
    // Assim evitamos a sobrecarga do backend
    searchDebounceRef.current.timeout = setTimeout(() => {
      fetchPersona(filterText);
    }, 1000);

    return () => clearTimeout(searchDebounceRef.current.timeout);
  }, [filterText]);

  const onSort = (selectedColumn: TableColumn<any>, sortDirection: SortOrder, _: any[]) => {
    setSortField(previous => selectedColumn.sortField ?? previous)
    setSortDirection(sortDirection);
    setCurrentPage(1);
  };

  const handleSelectPersona = (selectedPersonas: SimplePersonaInterface[]) => {
    setSelectedPersona(selectedPersonas[0]);
    setShowValidationMessage(false);
  }

  const handleApply = () => {
    if (selectedPersona) {
      onSelectPersona(selectedPersona);
      close();
    }
    else {
      setShowValidationMessage(true);
    }
  }

  const close = () => {
    setDatasource([]);
    setFilterText('');
    setCurrentPage(1);
    setSelectedPersona(undefined);
    onHide();
  }

  return (
    <Modal
      show={show}
      size="lg"
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: "1rem"}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <SubHeaderComponent
            filterText={filterText}
            resetPaginationToggle={resetPaginationToggle}
            setResetPaginationToggle={setResetPaginationToggle}
            setFilterText={setFilterText}
          />
        </div>
        <CustomDatatable
          columns={columns}
          filteredItems={datasource}
          filterText={filterText}
          setFilterText={setFilterText}
          isLoading={isLoading}
          selectableRows={true}
          selectableRowsSingle={true}
          onSelectedRowsChange={({selectedRows}) => handleSelectPersona(selectedRows as SimplePersonaInterface[])}
          resetPaginationToggle={resetPaginationToggle}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={setPerPage}
          onChangePage={setCurrentPage}
          sortServer={true}
          onSort={onSort}
          selectableRowsHighlight={true}
          onRowClicked={(_, e) => {
            // Clicou na linha, fora do texto
            if (e.target.getAttribute('role') === 'cell') {
              e.target
                .parentElement // O div pai
                ?.firstChild // O div onde está o checkbox
                ?.firstChild // O checkbox
                ?.click();
            }
            // Clicou no texto, tem mais um nível pra subir
            else {
              e.target
                .parentElement // O div ao redor do texto
                ?.parentElement // O div pai
                ?.firstChild // O div onde está o checkbox
                ?.firstChild // O checkbox
                ?.click();
            }
          }}
        />

        {showValidationMessage && (
          <Stack direction="horizontal" className="justify-content-end pt-4">
            <p className="gia-message-error">Necessário selecionar um {`${PersonaTypeEnum[personaType]}`.toLowerCase()}</p>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer style={{borderTop: "unset"}} className="mt-1">
        <Button variant="primary" onClick={handleApply}>
          Selecionar
        </Button>
        <Button variant="secondary" onClick={close}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
