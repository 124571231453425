import React, {FC} from 'react';
import styles from './emissions-reduction-kpi-variant.module.scss';
import {toAbsoluteUrl} from '../../../shared/helpers/AssetHelpers';

interface EmissionsReductionKpiVariantProps {
  img: string,
  value: string | undefined,
  valueSuffix: string | undefined
}

const EmissionsReductionKpiVariant: FC<EmissionsReductionKpiVariantProps> = ({img, value, valueSuffix}) => {
  return (
    <div className={styles.EmissionsReductionKpiVariant} data-testid="EmissionsReductionKpiVariant">
      <div>
        <div className="content" title={`${value} ${valueSuffix}`}>
          <div><img src={toAbsoluteUrl(img)} alt="Icon"/></div>
          <div className="value">
            <>{value}</>
            <span className="suffix">{valueSuffix}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmissionsReductionKpiVariant;
