import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './group.module.scss';
import {toast} from 'react-toastify';
import {GiaTextField} from '../../../components/gia-forms-components/gia-forms-components';
import {useForm} from 'react-hook-form';
import {Button, Col, Form, Row} from 'react-bootstrap';
import RoleInterface from '../../../shared/interfaces/role-interface';
import {useNavigate, useParams} from 'react-router-dom';
import {groupFormSchema, GroupFormSchemaType} from '../schemas';
import {zodResolver} from "@hookform/resolvers/zod";
import {createGroup, deleteGroupById, getGroupById, updateGroup} from '../_requests';
import {getAllRoles} from '../../../shared/services/role.service';
import {GroupInterface} from '../../../shared/interfaces/group-interface';
import GroupFormSkeleton from './groupSkeleton';
import {defaultToastOptions} from '../../../shared/toast/toast-default.options';
import {roleTranslations} from '../../../modules/auth/roles';
import PersonaSelect from "../../../components/persona-select/persona-select";
import {PersonaTypeEnum, PersonaTypeInterface} from "../../../shared/interfaces/persona-type-interface";

interface GroupProps { }
interface GroupData extends GroupInterface { }

interface GroupFormProps{
    group: GroupData;
    roles: RoleInterface[];
    isLoading: boolean | undefined;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    isEdit: boolean;
}

const Group:FC<GroupProps> = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [group, setGroup] = useState<GroupData>({
        name: '',
        description: '',
        roles: []
      });
    const [roles, setRoles] = useState<RoleInterface[]>([]);
    const isEdit:boolean = id ? true : false;

    useEffect(() => {
      setIsLoading(true);
      const fetchGroupData = async () => {
        try {
          const { data } = await getGroupById(String(id));
          setGroup(data);
        } catch (error) {
          console.log("Erro ao obter dados do Grupo: " + error);
          navigate('/manage-groups') ;
        }
      };

      const fetchRolesData = async () => {
        try {
          const { data }: { data: RoleInterface[] } = await getAllRoles();
          setRoles(data);
        } catch (error) {
          console.log("Erro ao obter dados das Funções: " + error);
          navigate('/manage-groups') ;
        }
      };

      fetchRolesData().then();

      if(id){
        fetchGroupData().then();
      }else{
        setIsLoading(false);
      }

    },[id, navigate]);

    return (
            <div className={`${styles.groupContainer} container-fluid `}>
                    <div className="gia-page-title-default">Grupos</div>
                    <div className={`${styles.formContainer} row d-flex flex-column align-items-center`}>
                   <GroupForm group={group} roles={roles} setIsLoading={setIsLoading} isLoading={isLoading} isEdit={isEdit}/>
                </div>
            </div>
    );
}

const GroupForm:FC<GroupFormProps> = ({ group, roles, setIsLoading, isLoading, isEdit }) => {

    const { register, handleSubmit, setValue, formState: { errors }, clearErrors, reset, getValues } = useForm<GroupFormSchemaType>({
      mode: 'all',
      resolver: zodResolver(groupFormSchema)
    });
    const navigate = useNavigate();
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [personaType, setPersonaType] = useState<PersonaTypeEnum | undefined>(group.type as PersonaTypeEnum);

    const personaTypes: PersonaTypeInterface[] = [
      {
        type: "ALL",
        name: PersonaTypeEnum.ALL
      },
      {
        type: "CUSTOMER",
        name: PersonaTypeEnum.CUSTOMER
      },
      {
        type: "DISTRIBUTOR",
        name: PersonaTypeEnum.DISTRIBUTOR
      },
      {
        type: "MANUFACTURER",
        name: PersonaTypeEnum.MANUFACTURER
      },
      {
        type: "FINANCIER",
        name: PersonaTypeEnum.FINANCIER
      },
      {
        type: "INTEGRATOR",
        name: PersonaTypeEnum.INTEGRATOR
      },
    ];

    useEffect(() => {
      if (group && PersonaTypeEnum[group.type as string] === PersonaTypeEnum.ALL) {
        group.persona = { id: 0, name: '' };
      }

      reset(group);
      setPersonaType(group.type as PersonaTypeEnum);
      setIsLoading(false);
    }, [group, reset, setIsLoading]);

    const saveData = async (formData: GroupFormSchemaType) => {
      const data:GroupData = {
        id: group.id,
        name: formData.name,
        description: formData?.description,
        type: formData.type,
        persona: formData.persona,
        roles: formData.roles
      }

      const successMessage = (isEdit) ? "Grupo atualizado com sucesso" : "Grupo criado com sucesso";
      const request = (isEdit) ? updateGroup(data).then() : createGroup(data).then();

      try{
        const { data:responseData } = await request;
        toast.success(successMessage, defaultToastOptions);
        if(!isEdit){
          navigate(`/manage-groups/group/${responseData.id}`);
        }
      }catch(error){
        console.log(error);
        toast.error("Não foi possível salvar o Grupo. \n"
            + "Por favor tente novamente.", defaultToastOptions);
      }
    }

    //TODO remove async
    const deleteGroup = async () => {
      //TODO make this a modal
      const confirm = window.confirm('Confirma excluir o grupo? Esta ação não pode ser desfeita.');
      if(confirm) {
        try {
          await deleteGroupById(String(group.id));
          toast.success("Grupo deletado com sucesso.", defaultToastOptions);
          backToManageList();
        } catch (error) {
          console.log("Erro ao deletar Grupo: " + error);
          toast.error("Não foi possível deletar o grupo. \n"
            + "Por favor tente novamente", defaultToastOptions);
        }
      }
    };

    const backToManageList = () => {
      navigate("/manage-groups");
    };

    const changePersonaType = (personaType) => {
      
      if(personaType == "ALL"){
        group.persona = { id: 0, name: '' };  
      }else{
        group.persona = { id: -1, name: '' };  
      }
      
      clearErrors('persona');
      forceUpdate();
      
    }

    return (
      <>
        {isLoading && <GroupFormSkeleton/>}
        {!isLoading &&
        <form className={`${styles.form} d-flex flex-column gia-form`} onSubmit={handleSubmit(saveData)}>
          <GiaTextField
            title="Nome"
            type="text"
            registerName={register("name")}
            placeholder="Nome"
            max={128}
            isInvalid={!!errors.name}
            isInvalidText={errors?.name?.message}
            required={true}
            className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-4" />

          <GiaTextField
            title="Descrição"
            type="text"
            id='description'
            registerName={register("description")}
            placeholder="Descrição"
            max={300}
            isInvalid={!!errors.description}
            isInvalidText={errors?.description?.message}
            as='textarea'
            rows={1}
            required={false}
            className={`${styles.descriptionTextField} col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-2`}
          />

          <div className='col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4 mt-2'>
            <label className='label required'>Tipo</label>
            <Form.Select
              {...register('type')}
              isInvalid={!!errors.type}
              onChange={(e) => {
                const value = e.target.value;
                const selectedType = value as PersonaTypeEnum;
                setPersonaType(value === '' ? undefined : selectedType);
                setValue('persona', { id: -1, name: '' });
                changePersonaType(selectedType);
              }}
            >
              <option value={""}>Selecione o Tipo</option>
              {personaTypes.map(type => <option value={type.type} key={type.type}>{type.name}</option>)}
            </Form.Select>
          </div>

          <PersonaSelect
              type={personaType}
              persona={group.persona}
              onSelectPersona={(persona) => {
                setValue('persona', persona);
                clearErrors('persona');
                forceUpdate();
              }}
              isInvalidPersona={!!errors.persona}
              hasAllPerson={true}
          />

          <p className='label mb-1 mt-4 required'>Funções</p>

          {errors.roles && <p className={`${styles.inputError} gia-message-error`}>{errors.roles.message}</p>}

          <div className={`${styles.rolesContainer} col-lg-5 col-sm-12 col-xs-12 col-md-5 mb-4`}>
          {roles?.map((groupRole) => (
            <Form.Check
              key={groupRole.id}
              className={`${styles.roleCheck}`}
              type='checkbox'
              id={`group-role-${groupRole.id}`}
              label={roleTranslations[groupRole.name] || groupRole.name}
              checked={getValues().roles?.some(r => r.id === groupRole.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setValue('roles', [...getValues('roles'), { id: groupRole.id, name: groupRole.name }]);
                  clearErrors(`roles`);
                  forceUpdate();
                } else {
                  setValue('roles', getValues().roles.filter(r => r.id !== groupRole.id));
                  forceUpdate();
                }
              }}
            />
          ))}
        </div>

          <div className="row-buttons-wrapper mt-4">
            <Row>
              <Col>
                  <Button type="submit" size="sm">Salvar</Button>
                  <Button type="button" variant="secondary" onClick={backToManageList} size="sm">Voltar</Button>
                  {isEdit && <Button type="button" variant="danger" onClick={deleteGroup} size="sm">Excluir</Button>}
              </Col>
            </Row>
          </div>
        </form>
        }
      </>
    );
  }

export default Group;
