import React, {FC, useEffect, useState} from 'react';
import styles from './energy-generated-at-plant-summary.module.scss';
import {ProgressBar} from 'react-bootstrap';
import ProgressBarWithPercentage from '../../../shared/progress-bar-with-percentage/progress-bar-with-percentage';
import GiaDatetimePeriodSelector
  from '../../../shared/components/datetime-period-selector/gia-datetime-period-selector';
import GiaNumberFormat from '../../../shared/components/number-format/gia-number-format';
import {EnergyGeneratedAtPlantSummaryInterface} from '../interfaces/energy-generated-at-plant-summary.interface';
import EnergyGeneratedAtPlantSummaryLastPeriodsInterface
  from '../interfaces/energy-generated-at-plant-summary-last-periods.interface';
import EnergyGeneratedAtPlantSummaryFactory from './energy-generated-at-plant-summary.factory';

interface EnergyGeneratedAtPlantSummaryProps {
  summary: EnergyGeneratedAtPlantSummaryInterface,
  handleChartPeriodChange: any,
  isLoading?: boolean
}

const EnergyGeneratedAtPlantSummary: FC<EnergyGeneratedAtPlantSummaryProps> = ({summary, handleChartPeriodChange}) => {

  const [lastPeriod, setLastPeriod] = useState<EnergyGeneratedAtPlantSummaryLastPeriodsInterface>(EnergyGeneratedAtPlantSummaryFactory.makeEmptyLastPeriods());

  useEffect(() => {
    setLastPeriod(summary.lastPeriodsPercentage);
  }, [summary]);

  return (
    <div className={`${styles.EnergyGeneratedAtPlantSummary} container-fluid g-0`} data-testid="EnergyGeneratedAtPlantSummary">
      <div className="row">
        <div className="col-12">
          <div className="container-fluid g-0">
            <div className="row">
              <div className="col-9">
                <div className="container-fluid g-0">
                  <div className="row g-0">
                    <div className="col-12 mt-2 mb-4">
                      <GiaDatetimePeriodSelector onChange={handleChartPeriodChange} />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className={`col-md-4 col-sm-12 g-value text-${summary.power.color}`}>
                      <GiaNumberFormat value={summary?.power.value} decimalPlaces={1}/><span>kWh</span>
                    </div>
                    <div className={`col-md-4 col-sm-12 text-md-center g-value text-${summary.percentage.color}`}>
                      <GiaNumberFormat value={summary?.percentage.value} decimalPlaces={1}/><span>%</span>
                    </div>
                    <div className="col-md-4 col-sm-12 text-md-end g-value">
                      <GiaNumberFormat value={summary?.goal.value} decimalPlaces={1}/><span>kWh</span>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-12 progress-bar-summary-wrapper">
                      <ProgressBar variant={summary?.percentage.color} color={summary.percentage.color} now={summary.percentage.value} />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-md-4 col-sm-12 g-label">Energia gerada (kWh)</div>
                    <div className="col-md-4 col-sm-12 text-md-center g-label">Energia gerada (%)</div>
                    <div className="col-md-4 col-sm-12 text-md-end g-label">Meta do período selecionado</div>
                  </div>
                </div>
              </div>
              <div className="col-3 border-start last-period-summary">
                <div className="last-period-summary-item">
                  <div className="progress-bar-wrapper">
                    <ProgressBarWithPercentage color={lastPeriod?.today.color} percentage={lastPeriod.today.value}/>
                  </div>
                  <div className="description">HOJE</div>
                </div>
                <div className="last-period-summary-item">
                  <div className="progress-bar-wrapper">
                    <ProgressBarWithPercentage color={lastPeriod?.yesterday.color} percentage={lastPeriod.yesterday.value}/>
                  </div>
                  <div className="description">ONTEM</div>
                </div>
                <div className="last-period-summary-item">
                  <div className="progress-bar-wrapper">
                    <ProgressBarWithPercentage color={lastPeriod?.last15Days.color} percentage={lastPeriod.last15Days.value}/>
                  </div>
                  <div className="description">QUINZENA</div>
                </div>
                <div className="last-period-summary-item">
                  <div className="progress-bar-wrapper">
                    <ProgressBarWithPercentage color={lastPeriod?.lastMonth.color} percentage={lastPeriod.lastMonth.value}/>
                  </div>
                  <div className="description">MÊS</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyGeneratedAtPlantSummary;
