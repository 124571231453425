import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import Overview from '../pages/overview/overview';
import ManagedPlants from '../pages/managed-plants/managed-plants';
import Plant from '../pages/plant/plant';
import Dashboards from '../pages/dashboards/dashboards';
import MasterLayout from '../layout/master-layout';
import PlantManager from "../pages/plant-manager/plant-manager";
import UserConfiguration from '../pages/user-configuration/user-configuration';
import ManageUsers from '../pages/users/manage-users/manage-users';
import User from '../pages/users/user/user';
import ManageGroups from '../pages/groups/manage-groups/manage-groups';
import Group from '../pages/groups/group/group';
import CredentialManager from "../pages/credential/credential-manager";
import CredentialManagerEdit from "../pages/credential/edit/credential-manager-edit";
import GrowattEndUser from '../pages/growatt-end-user/growatt-end-user';
import GrowattEndUserEdit from '../pages/growatt-end-user/growatt-end-user-edit/growatt-end-user-edit';
import EventConfiguration from '../pages/rules/event-configuration';
import EventConfigurationEdit from '../pages/rules/event-configuration-edit/event-configuration-edit';
import ManageSchedulerMessages from "../pages/scheduler-messages/manage-scheduler-messages/manage-scheduler-messages";
import SchedulerMessage from "../pages/scheduler-messages/scheduler-message/scheduler-message";
import RobbuSchedulerGroup from '../pages/robbu-scheduler-group/robbu-scheduler-group';
import RobbuSchedulerGroupEdit from '../pages/robbu-scheduler-group/robbu-scheduler-group-edit/robbu-scheduler-group-edit';
import Persona from '../pages/persona/persona';
import PersonaEdit from '../pages/persona/edit/person-edit';
import Customer from "../pages/customer/customer";
import CustomerEdit from "../pages/customer/edit/customer-edit";
import UserService from "../modules/auth/UserService";
import {Roles} from "../modules/auth/roles";
import {Home} from "../pages/home/home";

const PrivateRoutes = () => {

  const AllowAccessByRoles = ({ roles = [] as Roles[] }) => {
    return UserService.hasRole(roles) ? <Outlet /> : <Navigate to="/home" replace />;
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route index element={<Navigate to='overview' />} />
        {/* Redirect to Managed PlantView after success login */}
        <Route path='auth/*' element={<Navigate to='/managed-plants' />} />
        <Route path='home' element={<Home />} />
        {/* Pages */}
        <Route element={<AllowAccessByRoles roles={[Roles.OVERVIEW_VIEWER]} />}>
          <Route path="/overview" element={<Overview />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.MANAGED_PLANTS_VIEWER]} />}>
          <Route path="/managed-plants" element={<ManagedPlants />} />
          <Route path="/managed-plants/plant/:plantId" element={<Plant />}></Route>
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.PLANT_EDITOR]} />}>
          <Route path="/managed-plants/plant/edit/:plantId?" element={<PlantManager />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.USER_EDITOR]} />}>
          <Route path='/manage-users' element={<ManageUsers />}></Route>
          <Route path="/manage-users/user" element={<User />} />
          <Route path="/manage-users/user/:id" element={<User />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.DASHBOARD_VIEWER]} />}>
          <Route path="/dashboards" element={<Dashboards />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.PERSONA_CREDENTIAL_EDITOR]} />}>
          <Route path="/credential" element={<CredentialManager />} />
          <Route path="/credential/edit/:personaCredentialId?" element={<CredentialManagerEdit />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.GROWATT_END_USER_EDITOR]} />}>
          <Route path="/growatt-end-user" element={<GrowattEndUser />} />
          <Route path="/growatt-end-user/edit/:id?" element={<GrowattEndUserEdit />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.ROBBU_GROUP_EDITOR]} />}>
          <Route path="/robbu-scheduler-group" element={<RobbuSchedulerGroup />} />
          <Route path="/robbu-scheduler-group/edit/:id?" element={<RobbuSchedulerGroupEdit />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.GROUP_EDITOR]} />}>
          <Route path='/manage-groups' element={<ManageGroups />}></Route>
          <Route path='/manage-groups/group' element={<Group />}></Route>
          <Route path="/manage-groups/group/:id" element={<Group />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.ALERT_EDITOR]} />}>
          <Route path="/event-configuration" element={<EventConfiguration />} />
          <Route path="/event-configuration/detail/:eventId" element={<EventConfigurationEdit />} />
          <Route path="/event-configuration/detail/" element={<EventConfigurationEdit />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.SCHEDULER_MESSAGE_EDITOR]} />}>
          <Route path='/manage-scheduler-messages' element={<ManageSchedulerMessages />}></Route>
          <Route path='/manage-scheduler-messages/scheduler-message' element={<SchedulerMessage />}></Route>
          <Route path='/manage-scheduler-messages/scheduler-message/:schedulerMessageId' element={<SchedulerMessage />}></Route>
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.PERSONA_EDITOR]} />}>
          <Route path="/persona" element={<Persona />} />
          <Route path="/persona/edit/:id?" element={<PersonaEdit />} />
          <Route path="/persona/edit/" element={<PersonaEdit />} />
        </Route>

        <Route element={<AllowAccessByRoles roles={[Roles.CUSTOMER_EDITOR]} />}>
          <Route path="/customer" element={<Customer />} />
          <Route path="/customer/edit/:id?" element={<CustomerEdit />} />
        </Route>

        <Route path='/user-configuration' element={<UserConfiguration />}></Route>
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
