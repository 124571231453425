import {createTheme} from "react-data-table-component";

const createThemeGiaDefault = () => {
    createTheme('gia-default', {
        text: {
            primary: '#3C4043',
        }
    }, 'light');
}

export default createThemeGiaDefault;
