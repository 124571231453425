import axios from "axios";
import { GroupInterface } from "../../shared/interfaces/group-interface";

const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;

export function getGroupById(id:string){
  return axios.get(`${baseURL}/group/${id}`);
}

export function createGroup(data: GroupInterface){
  return axios.post(`${baseURL}/group`, data);
}

export function updateGroup(data:GroupInterface){
  return axios.put(`${baseURL}/group`, data);
}

export function deleteGroupById(id:string){
  return axios.delete(`${baseURL}/group/${id}`);
}

export function getAllGroups(){
  return axios.get(`${baseURL}/group`);
}