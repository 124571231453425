import axios from 'axios';
import {convertDatetimePeriodForBackend} from '../../shared/helpers/conversion.utils';
import {
  EnergyGeneratedAtPlantChartInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-chart.interface';
import {
  EnergyGeneratedAtPlantInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant.interface';
import {
  convertChartResponse,
  handleChart,
  handleEmissionReduction,
  handleSummary
} from '../../shared/services/gia.service';

export function loadPlantDetails(plantId) {
  const urlPowerByLastPeriods = `${process.env.REACT_APP_BACKEND_API_URL}/plant/plantDetails/${plantId}`;
  return axios.get(urlPowerByLastPeriods);
}

export function loadPlantDetailsData(plantId, period) {
  const periodConverted = convertDatetimePeriodForBackend(period);
  const urlChart = `${process.env.REACT_APP_BACKEND_API_URL}/plant/plantDetails/${plantId}/data/${periodConverted}`;
  return axios.get<[]>(urlChart).then((response: any) => {
    const convertedChartResponse = convertChartResponse(response.data);
    const chartUpdate = handleChart(period, convertedChartResponse) as EnergyGeneratedAtPlantChartInterface;
    const summaryUpdate = handleSummary(chartUpdate);

    return {
      summary: summaryUpdate,
      chart: chartUpdate
    } as EnergyGeneratedAtPlantInterface;
  });
}

export function loadPlantDetailsEmissionsReduction(plantId, period) {
  const periodConverted = convertDatetimePeriodForBackend(period);
  const urlEmissionsReduction = `${process.env.REACT_APP_BACKEND_API_URL}/plant/plantDetails/${plantId}/emissions-reduction/${periodConverted}`;
  return axios.get<[]>(urlEmissionsReduction).then((response: any) => handleEmissionReduction(response?.data[0]));
}
