import React from 'react';
import {numberFormatLocale} from '../../helpers/conversion.utils';

interface GiaNumberFormatProps {
  value?: number,
  decimalPlaces?: number,
  suffix?: string
}

function GiaNumberFormat({value, decimalPlaces, suffix}: GiaNumberFormatProps): JSX.Element {
  const maxDigits = decimalPlaces || 0;
  let formattedValue = '-';
  if(value !== undefined && value !== null) {
    formattedValue = numberFormatLocale(value, maxDigits);
  }
  return (
    <>
      {formattedValue}
      {suffix && suffix}
    </>
  );
}

export default GiaNumberFormat;

