import Skeleton from "react-loading-skeleton";
import {Col} from "react-bootstrap";

const title = <Skeleton height={30} width={150}></Skeleton>;
const label = <Skeleton height={15} width={100}></Skeleton>;
const field = <Skeleton height={34}></Skeleton>;
const button = <Skeleton height={30} width={60} style={{marginRight: '1rem'}}></Skeleton>;
const buttons = (<div style={{display: 'inline-flex'}}>{button} {button} {button}</div>);

const CredentialManagerEditSkeleton = () => (
  <Col xxl="5" xl="6" lg="8" md="10" sm="12">
    {title}
    {label}{field}
    {label}{field}
    {label}{field}
    {label}{field}
    {label}{field}
    {buttons}
  </Col>
)

export default CredentialManagerEditSkeleton;
