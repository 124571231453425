import React, {FC, useEffect, useMemo, useState} from "react";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Button, Col, Form, Row} from "react-bootstrap";
import styles from './credential-manager-edit.module.scss';
import {useNavigate, useParams} from "react-router-dom";
import {toast} from 'react-toastify';
import {GiaTextField} from "../../../components/gia-forms-components/gia-forms-components";
import {
  deletePersonaCredentialById, loadGroups,
  loadManufacturers,
  loadPersonaCredentialById,
  patchPersonaCredential,
  savePersonaCredential,
  loadManufacturerCredentialTypesByManufacturerId
} from "../_requests";
import ManufacturerInterface from "../../../shared/interfaces/manufacturer.interface";
import {CredentialType} from "../../../shared/type/credential.type";
import PersonaCredentialInterface from "../../../shared/interfaces/persona-credential.interface";
import credentialManagerSchema from "./credential-manager.schema";
import CredentialManagerEditSkeleton from "./credential-manager-edit.skeleton";
import GroupInterface from "../../../shared/interfaces/group.interface";

interface CredentialManagerEditProps {
}

const CredentialManagerEdit: FC<CredentialManagerEditProps> = () => {

  let {personaCredentialId} = useParams();

  const [personaCredential, setPersonaCredential] = useState<any>(null);
  const [credentialTypes, setCredentialTypes] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [groups, setGroups] = useState<GroupInterface[]>([]);
  const [manufacturers, setManufacturers] = useState<ManufacturerInterface[]>([]);

  type FormSchemaType = z.infer<typeof credentialManagerSchema>;

  const {
    register,
    unregister,
    reset,
    watch,
    handleSubmit,
    formState: {errors, isSubmitting}
  } = useForm<FormSchemaType>({
    mode: 'all',
    resolver: zodResolver(credentialManagerSchema)
  });

  // Load credential data
  useEffect(() => {
    const fetchSupportData = async () => {
      await loadGroups().then(r => r.data).then(response => setGroups(response));
      await loadManufacturers().then(r => r.data).then(response => setManufacturers(response));
    };
    setIsLoading(true);
    fetchSupportData().then(() => {
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.error(error);
    }).finally(() => {
      if(personaCredentialId) {
        const fetchData = async () => {
          await loadPersonaCredentialById(personaCredentialId).then(r => r.data).then(response => setPersonaCredential(response))
        };
        setIsLoading(true);
        fetchData().then(() => setIsLoading(false)).catch(error => {
          setIsLoading(false);
          console.error(error);
        });
      }
    });
  }, [personaCredentialId]);

  // Reset/fill form when load credential data
  useEffect(() => {
    if(personaCredential) {
      reset(personaCredential);
    }
  }, [personaCredential, reset]);

  useEffect(() => {
    if(watchManufacturerId) {
      const fetchData = async () => {
        await loadManufacturerCredentialTypesByManufacturerId(watchManufacturerId).then(r => r.data).then(response => {
          const types = response.map(item => item.credentialType);
          setCredentialTypes(types);
        })
      };
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false)).catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    }
  }, [watch('manufacturerId')]);

  const navigate = useNavigate();

  const watchManufacturerId = watch('manufacturerId');
  const watchCredentialType = watch('credentialType');

  const isEdit = useMemo<boolean>((): boolean => !!personaCredentialId, [personaCredentialId]);
  const isTokenType = useMemo((): boolean => watchCredentialType === CredentialType.TOKEN, [watchCredentialType]);
  const isUserPasswordType = useMemo((): boolean => watchCredentialType === CredentialType.USER_PASSWORD, [watchCredentialType]);
  const isAppIdType = useMemo((): boolean => watchCredentialType === CredentialType.APP_ID, [watchCredentialType]);

  // register and unregister optional fields
  useEffect(() => {
    if(isTokenType) {
      register('token');
      unregister('user');
      unregister('password');
      unregister('appId');
      unregister('appSecret');
    }
    if(isUserPasswordType) {
      register('user');
      register('password');
      unregister('token');
      unregister('appId');
      unregister('appSecret');
    }
    if(isAppIdType) {
      register('appId');
      register('appSecret');
      register('token');
      unregister('user');
      unregister('password');
    }
  }, [watchCredentialType, register, unregister, isAppIdType, isTokenType, isUserPasswordType]);

  const normalizeToSave = (formData: any): PersonaCredentialInterface => {
    return {
      id: formData.id,
      persona: {
        id: formData.personaId
      },
      manufacturer: {
        id: formData.manufacturerId
      },
      credentialType: formData.credentialType,
      user: formData.user,
      password: formData.password,
      token: formData.token,
      appId: formData.appId,
      appSecret: formData.appSecret
    };
  };

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {

    const payload = normalizeToSave(data);

    let formSubmit = isEdit ? patchPersonaCredential(payload) : savePersonaCredential(payload);

    return formSubmit.then(response => {
      toast.success('Credencial salva com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      if(!personaCredentialId) {
        navigate(`/credential/edit/${response.data.id}`);
      }
    }).catch(() => {
      toast.error('Houve um problema ao salvar os dados. Por favor tente novamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const cancel = () => {
    navigate(`/credential`);
  };

  const deletePersonaCredential = () => {
    const confirm = window.confirm('Confirma excluir a credencial? Esta ação não pode ser desfeita.');
    if (confirm) {
      setIsDeleting(true);
      deletePersonaCredentialById(personaCredentialId).then(() => {
        toast.success('Credencial excluida com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        navigate(`/credential`);
      }).catch(() => {
        toast.error('Houve um problema ao excluir a credencial. Por favor tente novamente.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }).finally(() => setIsDeleting(false));
    }
  };

  return (
    <div className={`${styles.CredentialManagerEdit} gia-manager-edit`}>
      <>
      {isLoading && <CredentialManagerEditSkeleton/>}

      {!isLoading &&
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="gia-form">
        <div className="gia-page-title-default header">Credencial</div>
        <div>
          <Row>
              <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <div className="label required">Grupo</div>
              <Form.Select
                title="Grupo"
                disabled={isEdit}
                isInvalid={!!errors.personaId}
                required={true}
                {...register('personaId')}>
                {groups.map((option, i) => {
                  return <option key={i} value={option.personaId}>{option.name}</option>
                })}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <div className="label required">Fabricante</div>
              <Form.Select
                title="Fabricante"
                disabled={isEdit}
                isInvalid={!!errors.manufacturerId}
                required={true}
                {...register('manufacturerId')}>
                {manufacturers.map((option, i) => {
                  return <option key={i} value={option.id}>{option.name}</option>
                })}
              </Form.Select>
              <div className="gia-form-feedback-message"></div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <div className="label required">Tipo de Credencial</div>
              <Form.Select
                title="Tipo de Credencial"
                isInvalid={!!errors.credentialType}
                required={true}
                {...register('credentialType')}>
                {credentialTypes.map((option, i) => {
                  return <option key={i} value={option}>{option}</option>
                })}
              </Form.Select>
                <div className="gia-form-feedback-message"></div>
            </Form.Group>
          </Row>

          {(isTokenType || isAppIdType) &&
            <Row>
              <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
                <GiaTextField
                  title="Token"
                  placeholder="**********"
                  type="password"
                  required={true}
                  autocomplete="one-time-code"
                  isInvalid={!!errors.token}
                  isInvalidText={errors?.token?.message}
                  registerName={{...register('token')}}></GiaTextField>
              </Form.Group>
            </Row>
          }

          {isUserPasswordType &&
            <>
              <Row>
                <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
                  <GiaTextField
                    title="Usuário"
                    placeholder="**********"
                    type="password"
                    required={true}
                    autocomplete="one-time-code"
                    isInvalid={!!errors.user}
                    isInvalidText={errors?.user?.message}
                    registerName={{...register('user')}}></GiaTextField>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
                  <GiaTextField
                    title="Senha"
                    placeholder="**********"
                    type="password"
                    required={true}
                    autocomplete="one-time-code"
                    isInvalid={!!errors.password}
                    isInvalidText={errors?.password?.message}
                    registerName={{...register('password')}}></GiaTextField>
                </Form.Group>
              </Row>
            </>
          }

          {isAppIdType &&
            <>
              <Row>
                <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
                  <GiaTextField
                    title="App ID"
                    placeholder="**********"
                    type="password"
                    required={true}
                    autocomplete="one-time-code"
                    isInvalid={!!errors.appId}
                    isInvalidText={errors?.appId?.message}
                    registerName={{...register('appId')}}></GiaTextField>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
                  <GiaTextField
                    title="App Secret"
                    placeholder="**********"
                    type="password"
                    required={true}
                    autocomplete="one-time-code"
                    isInvalid={!!errors.appSecret}
                    isInvalidText={errors?.appSecret?.message}
                    registerName={{...register('appSecret')}}></GiaTextField>
                </Form.Group>
              </Row>
            </>
          }
        </div>
        <div className="row-buttons-wrapper">
          <Row>
            <Col>
              <Button type="submit" size="sm" disabled={isSubmitting || isDeleting}>Salvar</Button>
              <Button type="button" variant="secondary" onClick={cancel} size="sm" disabled={isSubmitting || isDeleting}>Voltar</Button>
              {personaCredentialId &&
                  <Button type="button" variant="danger" onClick={deletePersonaCredential} size="sm" disabled={isSubmitting || isDeleting}>Excluir</Button>
              }
            </Col>
          </Row>
        </div>
      </Form>
      }
      </>
    </div>
  );
};

export default CredentialManagerEdit;

