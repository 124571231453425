import {EnergyGeneratedAtPlantInterface} from './interfaces/energy-generated-at-plant.interface';
import {EnergyGeneratedAtPlantSummaryInterface} from './interfaces/energy-generated-at-plant-summary.interface';
import {EnergyGeneratedAtPlantChartInterface} from './interfaces/energy-generated-at-plant-chart.interface';
import EnergyGeneratedAtPlantSummaryFactory from './summary/energy-generated-at-plant-summary.factory';

class EnergyGeneratedAtPlantFactory {

  static makeInitialStateEnergyGeneratedAtPlant = (): EnergyGeneratedAtPlantInterface => {
    const summary = {
      power: {value: undefined, color: undefined},
      percentage: {value: undefined, color: undefined},
      goal: {value: undefined, color: undefined},
      lastPeriodsPercentage: EnergyGeneratedAtPlantSummaryFactory.makeEmptyLastPeriods()
    } as EnergyGeneratedAtPlantSummaryInterface;

    const chart = {
      data: undefined,
      statistics: {}
    } as EnergyGeneratedAtPlantChartInterface;

    return {
      summary,
      chart
    };
  }
}

export default EnergyGeneratedAtPlantFactory;
