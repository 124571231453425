import axios from "axios";

const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;

const getAllScheduleGroups = () => {  
  return axios.get(`${baseURL}/robbuSchedulerGroup`);
}

const getRobbuGroupById = (id) => {
  return axios.get(`${baseURL}/robbuSchedulerGroup/${id}`);
}

const getAllPlants = () => {
  return axios.get(`${baseURL}/plant/allPlantNames`);
}

const updateRobbuGroup = (data) => {
  return axios.put(`${baseURL}/robbuSchedulerGroup/${data.id}`, data);
}

const createRobbuGroup = (data) => {
  return axios.post(`${baseURL}/robbuSchedulerGroup/create-with-plants`, data);
}
 
const deleteRobbuGroupById = (id) => {
  return axios.delete(`${baseURL}/robbuSchedulerGroup/${id}`);
}

const findAllPlantContactsByRobbuId = (robbuGroupId) => {
  return axios.get(`${baseURL}/robbuSchedulerGroup/groupWithAllPlantContacts/${robbuGroupId}`);
}

const findPlantsContactsByIds = (plantsIds:[]) => {
  return axios.post(`${baseURL}/robbuSchedulerGroup/plantsContacts`, plantsIds);
}

const RobbuSchedulerGroupRequests = {
  getAllScheduleGroups,
  getRobbuGroupById,
  getAllPlants,
  updateRobbuGroup,
  createRobbuGroup,
  deleteRobbuGroupById,
  findAllPlantContactsByRobbuId,
  findPlantsContactsByIds
};

export default RobbuSchedulerGroupRequests;