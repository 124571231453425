import React, { FC, useEffect, useState } from 'react';
import styles from './event-configuration.module.scss';
import { Button, Form } from 'react-bootstrap';
import CSS from 'csstype';
import Skeleton from 'react-loading-skeleton';
import { EventConfigurationProps } from './event-configuration-interface';
import { useNavigate } from 'react-router-dom';
import CustomDatatable from '../../components/custom-datatable/custom-datatable';
import { listEvents } from './event-configuration-edit/_request';
import { filterByFields } from '../../components/filter/filter';
import SubHeaderComponent from '../../components/subHeader/subHeader';

/* DATATABLE DEFINITIONS */
const defaultRowStyle: CSS.Properties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '8px'
};

const defaultRowStringCell = (str: string) => {
  return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
}

const columns = [
  {
    name: 'Nome',
    selector: row => row.name,
    sortable: true,
    width: '25%',
    cell: row => defaultRowStringCell(row.name),
  },
  {
    name: 'Expressão da Cron',
    selector: row => row.cronExpression,
    sortable: true,
    width: '20%',
    cell: row => defaultRowStringCell(row.cronExpression),
  },
  {
    name: 'Raiz do JSON',
    selector: row => row.jsonRootName,
    sortable: true,
    width: '15%',
    cell: row => defaultRowStringCell(row.jsonRootName),
  },
  {
    name: 'Corpo do JSON',
    selector: row => row.jsonBody,
    sortable: true,
    width: '15%',
    cell: row => defaultRowStringCell(row.jsonBody),
  },
  {
    name: 'Situação',
    selector: row => row.active,
    sortable: true,
    width: '10%',
    cell: row => (
      <Form.Check
        id={`Ativo ${row.id}`}
        disabled={true}
        type="checkbox"
        label='Ativo'
        checked={row.active}
      />
    ),
  },

].map(c => ({ ...c, minWidth: c.width, maxWidth: c.width }));

const convertResponse = (response) => {
  return response
    .map(r => {
      return {
        id: r.id,
        name: r.name,
        active: r.active,
        cronExpression: r.cronExpression,
        dataFrom: r.dataFrom,
        jsonRootName: r.jsonRootName,
        jsonBody: r.jsonBody,
        objectFieldId: r.objectFieldId,
        dmn: r.dmn,
      } as EventConfigurationProps;
    });
}

const EventConfiguration: FC<EventConfigurationProps> = () => {

  const [datasource, setDatasource] = useState<EventConfigurationProps[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    listEvents().then(response => setDatasource(convertResponse(response.data))).then(() => setIsLoading(false));
  }, []);

  const filteredItems = filterByFields(datasource, filterText, ['name', 'cronExpression', 'jsonBody', 'jsonBody', 'dmnName']);

  const onRowClicked = (row, event) => {
    navigate(`/event-configuration/detail/${row.id}`);
  }

  return (

    <div className={styles.EventConfiguration} data-testid="EventConfiguration">
      <div className="gia-page-title-default">Alertas</div>
      {isLoading && <Skeleton height={48} />}
      {!isLoading &&
        <div className="container-fluid g-0 mb-3">
          <div className="row">
            <div className="col-sm-12 d-flex">
              <Button type="button" variant="primary" size="sm" className="me-4" onClick={() => navigate('/event-configuration/detail')}>Criar Alerta</Button>
              {!isLoading && <SubHeaderComponent filterText={filterText} resetPaginationToggle={resetPaginationToggle} setResetPaginationToggle={setResetPaginationToggle} setFilterText={setFilterText} />}
            </div>
          </div>
        </div>
      }
      {isLoading && <Skeleton height={25} width={150} style={{ marginBottom: '.25rem' }} />}

      {isLoading && <Skeleton height={'50vh'} />}
      {!isLoading &&
        <div className={`${styles.datatableWrapper} gia-datatable-wrapper`}>
          <CustomDatatable
            columns={columns}
            filteredItems={filteredItems}
            filterText={filterText}
            setFilterText={setFilterText}
            isLoading={isLoading}
            selectableRows={false}
            onRowClicked={onRowClicked}
            resetPaginationToggle={resetPaginationToggle}
          />
        </div>
      }
    </div>
  );
};

export default EventConfiguration;
