import React, {FC} from 'react';
import styles from './energy-generated-at-plant.module.scss';
import EnergyGeneratedAtPlantSummary from './summary/energy-generated-at-plant-summary';
import EnergyGeneratedAtPlantChart from './chart/energy-generated-at-plant-chart';
import {EnergyGeneratedAtPlantInterface} from './interfaces/energy-generated-at-plant.interface';
import Skeleton from 'react-loading-skeleton';

interface EnergyGeneratedAtPlantProps {
  onChangeFilter: any,
  energyGeneratedAtPlant: EnergyGeneratedAtPlantInterface,
  isLoading?: boolean
}

const EnergyGeneratedAtPlant: FC<EnergyGeneratedAtPlantProps> = ({energyGeneratedAtPlant, onChangeFilter, isLoading }) => {

  return (
    <div className={styles.EnergyGeneratedAtPlant} data-testid="EnergyGeneratedAtPlant">
      {isLoading && <Skeleton height={690}/>}
      <div className={`${isLoading ? 'd-none' : 'card'}`}>
        <div className="title">
          Energia Gerada na Usina
        </div>
        <div className="body">
          <EnergyGeneratedAtPlantSummary summary={energyGeneratedAtPlant.summary} handleChartPeriodChange={onChangeFilter} isLoading={isLoading} />
          <EnergyGeneratedAtPlantChart chart={energyGeneratedAtPlant.chart}  isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default EnergyGeneratedAtPlant;
