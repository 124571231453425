import { z } from "zod";
import { Roles } from "../../modules/auth/roles";

export const userFormSchema = z.object({
    id: z.number().nullable().optional(),
    email: z.string().email(),
    firstName: z.string().min(1).max(20),
    lastName: z.string().min(1).max(20),
    active: z.union([z.number(), z.preprocess(value => value === '1', z.boolean())]),
    roles: z.object({
      id: z.number(),
      name: z.custom<Roles>(),
    }).array().min(1, "Necessário que seja incluído no mínimo uma Função"),
    groups: z.object({
      id: z.number().optional(),
      name: z.string(),
      description: z.string().optional()
    }).array().min(1, "Necessário que seja incluído no mínimo um Grupo")
  });
export type UserFormSchemaType = z.infer<typeof userFormSchema>;