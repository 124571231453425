import React, {FC, useEffect, useMemo, useState} from "react";
import styles from './credential-manager.module.scss';
import {removeDiacritics} from "../../shared/helpers/string-utils.helper";
import Skeleton from "react-loading-skeleton";
import Form from "react-bootstrap/esm/Form";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import paginationComponentOptions from "../../shared/datatable/pagination-component.options";
import axios from "axios";
import CSS from 'csstype';
import {CredentialType} from "../../shared/type/credential.type";

interface CredentialManagerProps{}
interface FilterComponentProps {
  onFilter: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  onClear: any,
  filterText: string | number | string[] | undefined,
  className?: string | undefined
}
interface DatatableRowInterface {
  id: number,
  name: string,
  manufacturer: string,
  manufacturerName: string,
  groupName: string,
  credentialType: CredentialType,
  credential?: string,
  token?: string,
  user?: string,
  password?: string,
  appId?: string,
  appSecret?: string,
}

const FilterComponent: FC<FilterComponentProps> = (props) => {
  return (
    <div className={props.className} style={{maxHeight: "32px", overflow: "hidden", width: '388px'}}>
      <Form.Control
        type="search"
        size="sm"
        className="form-control ps-5"
        placeholder="Pesquisar em todos os campos"
        value={props.filterText}
        onChange={props.onFilter}
      />
      <Button variant="bg-transparent" style={{bottom: '18px'}} className="btn bg-transparent px-2 py-0 position-relative translate-middle-y gia-search-input-icon" type="submit">
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    </div>
  );
}

const CredentialManager: FC<CredentialManagerProps> = () => {

  const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* DATATABLE DEFINITIONS */
  const progressComponent = <div style={{ width: '100%', paddingBottom: '.25rem' }}><Skeleton height={45} count={10} /></div>;
  const basicSortStringNoCase = (a: string, b: string) => {
    const aToCompare = removeDiacritics(a).toLowerCase();
    const bToCompare = removeDiacritics(b).toLowerCase();
    return (aToCompare > bToCompare) ? 1 : (bToCompare > aToCompare) ? -1 : 0;
  };

  const navigate = useNavigate();

  const onRowClicked = (row: any) => {
    navigate(`/credential/edit/${row.id}`);
  };

  const columns = useMemo(() => {

    const defaultRowStyle: CSS.Properties = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    };

    const defaultRowStringCell = (str: string) => {
      return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
    }

    const columnsArray = [
      {
        name: 'Fabricante',
        selector: (row :any) => row.manufacturerName,
        sortable: true,
        width: '30%',
        cell: (row :any) => defaultRowStringCell(row.manufacturerName),
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.manufacturerName, rowB.manufacturerName),
      },
      {
        name: 'Tipo',
        selector: (row :any) => row.credentialType,
        sortable: true,
        width: '30%',
        cell: (row :any) => defaultRowStringCell(row.credentialType),
        sortFunction: (rowA: any, rowB: any) => basicSortStringNoCase(rowA.credentialType, rowB.credentialType),
      }
    ];
    return columnsArray.map(c => ({ ...c, minWidth: c.width, maxWidth: c.width }));
  }, []);

  const textMatch = (value: string, filterTextToCompare: string) => {
    if(value !== undefined && value !== null) {
      return removeDiacritics(value).toLowerCase().includes(filterTextToCompare);
    }
    return false;
  };

  const filteredItems = datasource.filter(
    item => {
      const filterTextToCompare = filterText.toLowerCase();

      const credentialType    = !filterTextToCompare || textMatch(item.credentialType, filterTextToCompare);
      const groupName         = !filterTextToCompare || textMatch(item.groupName, filterTextToCompare);
      const manufacturerName  = !filterTextToCompare || textMatch(item.manufacturerName, filterTextToCompare);

      return manufacturerName || groupName || credentialType;
    },
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const goToNewCredential = () => {
      navigate(`/credential/edit`);
    };
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <div className="container-fluid g-0 mb-3" key={`filterBar`}>
        <div className="row">
          <div className="col-sm-12 d-flex">
            <Button type="button" variant="primary" size="sm" className="me-4" onClick={goToNewCredential}>Nova Credencial</Button>
            <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
          </div>
        </div>
      </div>
    );
  }, [navigate, filterText, resetPaginationToggle]);

  const convertResponse = (response: any) => {
    return response
      .map((r: any) => {
        return {
          id: r.id,
          groupName: r.groupName,
          manufacturerName: r.manufacturerName,
          credentialType: r.credentialType
        } as DatatableRowInterface;
      });
  }

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential`;
    axios.get(url).then(response => setDatasource(convertResponse(response.data))).then(() => setIsLoading(false));
  },[]);

  return (
    <div className={`${styles.CredentialManager} gia-manager`}>

      <div className="gia-page-title-default header">Credenciais</div>

      {isLoading && <Skeleton height={48} />}
      {!isLoading && subHeaderComponentMemo}

      {isLoading && <Skeleton height={'50vh'} />}
      {!isLoading &&
          <div className={`${styles.datatableWrapper} gia-datatable-wrapper`}>
              <DataTable
                  pointerOnHover={true}
                  onRowClicked={onRowClicked}
                  columns={columns}
                  data={filteredItems}
                  noDataComponent={<><span className="gia-datatable-no-results">Nenhum registro</span></>}
                  fixedHeader={true}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  className="gia-datatable"
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  persistTableHead
                  progressPending={isLoading}
                  progressComponent={progressComponent}
                  theme="gia-default"
              />
          </div>
      }

    </div>
  );
}

export default CredentialManager;


