import Skeleton from "react-loading-skeleton";
import styles from "./user.module.scss";

const UserFormSkeleton = () => {


return(
    <div className={`${styles.skeletonContainer}`}>
        <Skeleton className="mt-4" height={12} width={50}/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

        <Skeleton className="mt-3" height={12} width={50}/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

        <Skeleton className="mt-3" height={12} width={50}/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

        <Skeleton className="mt-3" height={12} width={50}/>
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-2" height={32}/>

        <Skeleton className="mt-2" height={18} width={60}/>        
        <Skeleton className="mt-2 mb-2" height={12} width={50}/>
        <div className="col-lg-6 col-sm-12 col-xs-6 col-md-6 mb-2 d-flex">
            <div style={{marginRight: '1rem'}} className="col-lg-10 col-sm-12 col-xs-10 col-md-10">
                <Skeleton height={30} />
            </div>
            <div>
                <Skeleton width={80} height={30} />
            </div>
        </div>
        <Skeleton className="mb-4" width={170} height={30} />

        <Skeleton className="mt-2 mb-2" height={18} width={60}/>        
        <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-2" height={105} />

        <div className={`d-flex align-items-center row mt-2`}>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
            <div style={{width:'auto'}}><Skeleton width={70} height={30}/></div>
        </div>
    </div>
);
}

export default UserFormSkeleton;