import React, {FC, useEffect, useState} from "react";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {GiaTextField} from "../../components/gia-forms-components/gia-forms-components";
import styles from './plant-manager.module.scss';
import {useNavigate, useParams} from "react-router-dom";
import {
  deletePlantById,
  hasDeviceData,
  loadDistributors,
  loadFinanciers,
  loadManufacturers,
  loadPlantWithDevices,
  savePlantWithDevices,
  validateDevices
} from "./_requests";
import {toast} from 'react-toastify';
import {GiaDatePicker} from "../../components/gia-forms-components/gia-datepicker/gia-datepicker";
import ManufacturerInterface from "../../shared/interfaces/manufacturer.interface";
import PlantManagerSkeleton from "./plant-manager.skeleton";
import PersonaSelect from "../../components/persona-select/persona-select";
import {PersonaTypeEnum} from "../../shared/interfaces/persona-type-interface";
import PlantValidateDevicesRequestInterface from "../../shared/interfaces/plant-validate-devices-request.interface";
import {DeviceValidationStatusType} from "../../shared/type/device-validation-status.type";
import Skeleton from "react-loading-skeleton";
import {PlantDeviceValidationStatusType} from "../../shared/type/plant-device-validation-status.type";
import { projectIdRegex, zipCodeOnlyNumbers } from '../../shared/helpers/string-utils.helper';
import UserService from "../../modules/auth/UserService";
import { Roles } from "../../modules/auth/roles";
import axios from "axios";
import {ufEnum} from "../../shared/type/uf.type";
import {cepValidator} from "../../shared/validators/cep-validator";
import AddressCityInterface from "../../shared/interfaces/address-city-interface";
import ParamInterface from "../../shared/interfaces/param.interface";
import { getParamByKey } from "../../shared/services/params.service";
import {FormControl} from "react-bootstrap";

interface PlantManagerProps {
}

//TODO change this to plant-edit
const PlantManager: FC<PlantManagerProps> = () => {

  let { plantId } = useParams();

  const [stateZipcodeForm, setStateZipcodeForm] = useState(true);
  const [stateAdressField, setStateAddressField] = useState(true);
  const [stateDistrictField, setStateDistrictField] = useState(true);
  const [cities, setCities] = useState<AddressCityInterface[]>([]);
  const [paramInsertAddressForm, setParamInsertAddressForm] = useState<ParamInterface>();
  const [zipcodeValid, setZipcodeValid] = useState(true);
  const [textErrorZipcode, setTextErrorZipcode] = useState("");
  const [validErrorZipcode, setValidErrorZipcode] = useState(false);

  // Define as empty char code to not generate auto message from zod
  const requiredErrorMessage = String.fromCharCode(0);
  const invalidNumberRule = {invalid_type_error: requiredErrorMessage};
  const numberRule = z.union([z.number(invalidNumberRule).positive(), z.nan()]).nullable();
  const numberRuleRequired = z.number(invalidNumberRule).positive().min(1, requiredErrorMessage);

  const hasRolePlantDeviceDetele = UserService.hasRole([Roles.PLANT_DEVICE_DELETE]);

  const formSchema = z.object({
      plantId: z.number().nullable().optional(),
      projectId: z.string(invalidNumberRule).min(1, requiredErrorMessage).max(37).refine(value => projectIdValidate(value.trim()), { message: "ID do projeto inválido" }),
      financierId: z.number().min(-1),
      plantName: z.string(invalidNumberRule).min(1, requiredErrorMessage).max(255),
      customer: z.object({
        id: z.number().min(-1),
        name: z.string().max(255).nullable()
      }),
      integrator: z.object({
        id: z.number().min(-1),
        name: z.string().max(255).nullable()
      }),
      active: z.preprocess(value => (value === '1' || value === 1 || value === true), z.boolean()),
      peakPower: numberRuleRequired,
      powerByMonth: numberRuleRequired,
      latitude: z.union([z.number(invalidNumberRule), z.nan()]).nullable(),
      longitude: z.union([z.number(invalidNumberRule), z.nan()]).nullable(),
      zipCode: z.object({
        zipCode: z.string().optional().nullable(),
        address: z.string().optional().nullable(),
        complement: z.string().optional().nullable(),
        district: z.string().max(255).optional().nullable(),
        addressCityZipcode: z.object({
          cityId: z.number().optional().nullable(),
          name: z.string(invalidNumberRule).max(255).optional().nullable()
        }).optional().nullable(),
        addressStateZipcode: z.object({
          stateId: z.number().optional().nullable(),
          state: z.string(invalidNumberRule).max(255).optional().nullable()
        }).optional().nullable()
      }).optional().nullable(),
      addressNumber: z.string().optional().nullable(),
      complement: z.string().optional().nullable(),
      
      dtStartProduction: z.union([z.date(), z.undefined(), z.string().length(0)]).nullable(),
      deviceValidationStatus: z.string().nullable().optional(),

      devices: z.object({
        idx: z.number().optional().nullable(),
        deviceId: z.number().optional(),
        plantId: z.number().optional(),
        manufacturer: z.object({
          id: z.number().min(1),
          type: z.string().optional()
        }),
        brandId: z.number().optional().nullable(),
        distributor: z.object({
          id: z.number().min(1)
        }),
        dataloggerSn: z.string().min(1, requiredErrorMessage).max(255),
        deviceSn: z.string().min(1, requiredErrorMessage).max(255),
        active: z.boolean().default(true),
        validationStatusSn: z.string().optional().nullable(),
        validationStatusDl: z.string().optional().nullable(),
        existsInManufacturer: z.number().optional().nullable()
      }).array().min(1, "É necessário que seja incluído no mínimo 1 dispositivo")
    });
  type FormSchemaType = z.infer<typeof formSchema>;

  const [plant, setPlant] = useState<any>(null);
  const [integrator, setIntegrator] = useState<any>([]);
  const [customer, setCustomer] = useState<any>([]);
  const [distributors, setDistributors] = useState<any>([]);
  const [financiers, setFinanciers] = useState<any>([]);
  const [manufacturers, setManufacturers] = useState<ManufacturerInterface[]>([]);
  const [brands, setBrands] = useState<ManufacturerInterface[]>([]);

  const [isBrand, setIsBrand] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);
  const [isValidatingDevices, setIsValidatingDevices] = useState(false);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const {
    register,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: {errors}
  } = useForm<FormSchemaType>({
    mode: 'all',
    resolver: zodResolver(formSchema)
  });

  useEffect(() => {
    const fetchSupportData = async () => {
      await loadManufacturers().then(r => r.data).then(response => {
        setManufacturers(response);
        setBrands(response.filter(manufacturer => manufacturer.isBrand === true));
      });
      await loadDistributors().then(r => r.data).then(response => setDistributors(response));
      await loadFinanciers().then(r => r.data).then(response => setFinancierList(response));
      await getParamByKey("INSERT_ADDRESS_FORM").then(r => r.data).then(response => setParamInsertAddressForm(response));
    };
    setIsLoading(true);
    fetchSupportData().then(() => {
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      console.error(error);
    }).finally(() => {
      if(!plantId) {
        setPlant({});
        setCustomer(undefined);
        setIntegrator(undefined);
        return;
      }
      const fetchData = async () => {
        await loadPlantWithDevices(plantId).then(r => r.data).then(response => {
          setCustomer(response.customer);
          setIntegrator(response.integrator);

          if(response.zipCode != null){
            if(response.zipCode.address == ""){
              response.zipCode.address = response.address;
              setStateAddressField(false);
            }
            if(response.zipCode.district == ""){
              response.zipCode.district = response.district;
              setStateDistrictField(false);
            }
          }

          setPlant(response);
        });
      }
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false)).catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    });
  }, [plantId]);

  // para q o Sol Agora venha primeiro independente da ordem do cadastro de financiadoras
  const setFinancierList = (response: any) => {
    
    financiers.splice(0,financiers.length);
    
    response.forEach(element => {
      if(element.name == "Sol Agora"){
        financiers.push(element);
      }
    });

    response.forEach(element => {
      if(element.name != "Sol Agora"){
        financiers.push(element);
      }
    });
  }

  useEffect(() => {
    const createIndexOnDevices = (plant: any) => {
      let i = 0;
      if(!plant.devices) {
        plant.devices = [];
      }
      plant.devices = plant?.devices.map(d => {
        d.idx = i++;
        return d;
      });
      return plant;
    };
    const createDateObjects = (plant: any) => {
      if(plant.dtStartProduction) {
        plant.dtStartProduction = new Date(plant.dtStartProduction);
      }
      return plant;
    };
    const normalizeToForm = (plant: any) => {
      plant = createIndexOnDevices(plant);
      plant = createDateObjects(plant);
      return plant;
    };

    if(plant){
      const plantDefaultValues = {...plant};
      const plantDefaultValuesNormalized = normalizeToForm(plantDefaultValues);
      reset(plantDefaultValuesNormalized);
    }
  }, [plant, reset]);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    
    const payload = prepareToSave(data);
    let valueZipcode:string = payload.zipCode?.zipCode!;
    if(Number.parseInt(valueZipcode) <= 1000000){
      return;
    }

    if(!zipcodeValid){
      return;
    }
    
    if(!checkForUnverifiedDevices(data)) {
      return;
    }

    setIsPosting(true);
    savePlantWithDevices(payload).then(response => {
      setIsPosting(false);
      toast.success('Planta salva com sucesso!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      
      setStateZipcodeForm(true);
      navigate(`/managed-plants/plant/edit/${response.data.plantId}`);
    }).catch((response) => {
      let message = "Houve um problema ao salvar os dados. Por favor tente novamente.";

      if(response.response.data.detail != null){
        message = response.response.data.detail;
      }
      
      setIsPosting(false);
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const prepareToSave = (plant: FormSchemaType) => {
    plant.devices = plant.devices.map(device => {
      const manufacturer = {id: device.manufacturer?.id};
      const brandId = device.brandId;
      const distributor = {id: device.distributor?.id};
      return {...device, manufacturer, brandId, distributor};
    });
    return plant;
  }

  const cancel = () => {
    navigate(`/managed-plants`);
  };

  const deletePlant = () => {
    //TODO make this a modal
    const confirm = window.confirm('Confirma excluir a planta? Esta ação não pode ser desfeita.');
    if(confirm) {
      setIsPosting(true);
      deletePlantById(plantId).then(() => {
        setIsPosting(false);
        toast.success('Planta excluida com sucesso!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        navigate(`/managed-plants`);
      }).catch(() => {
        setIsPosting(false);
        toast.error('Houve um problema ao excluir a planta. Por favor tente novamente.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
    }
  };

  const addDevice = () => {
    const values = getValues();
    const devicesFromForm = values.devices;
    let idx = 0;
    if(devicesFromForm?.length > 0) {
      const idxList = devicesFromForm.map(d => d.idx) as number[];
      const maxIdx = Math.max(...idxList);
      idx = maxIdx + 1;
    }

    const newDevice = {
      idx,
      deviceId: undefined,
      plantId: undefined,
      manufacturer: undefined,
      brandId: undefined,
      distributor: undefined,
      dataloggerSn: "",
      deviceSn: "",
      active: true,
      validationStatus: DeviceValidationStatusType.NOT_VALIDATED_YET
    } as any;

    const lastAddedDevice = devicesFromForm[devicesFromForm.length - 1];
    if(lastAddedDevice) {
      newDevice.manufacturer = lastAddedDevice.manufacturer;
      newDevice.brandId = lastAddedDevice.brandId;
      newDevice.distributor = lastAddedDevice.distributor;
    }

    devicesFromForm.push(newDevice);
    setValue('devices', devicesFromForm);
    forceUpdate();
  };

  const validatePlantDevices = () => {
    const values = getValues();
    const devicesFromForm = values.devices;
    const request = {
      plantId: plantId,
      devices: devicesFromForm
        .map(device => {
          const manufacturer = {id: String(device.manufacturer.id)};
          const brandId = {id: String(device.brandId)};
          const distributor = {id: String(device.distributor.id)};
          return {...device, manufacturer, brandId, distributor};
        })
    } as PlantValidateDevicesRequestInterface;
    setIsValidatingDevices(true);
    validateDevices(request).then(response => {
      setIsValidatingDevices(false);
      const devicesValidated = response.data.devices;
      devicesValidated.forEach(devicesValidated => {
        const device = devicesFromForm.find(d => d.idx === devicesValidated.idx);
        if(device) {
          device.validationStatusSn = devicesValidated.validationStatusSn?.toString();
          device.validationStatusDl = devicesValidated.validationStatusDl?.toString();
          device.existsInManufacturer = devicesValidated.existsInManufacturer;
        }
      });
    }).catch(() => {
      setIsValidatingDevices(false);
      toast.error('Houve um problema ao validar os dados. Por favor tente novamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    });
  };

  const removeDevice = (device: any) => {
    if(device.deviceId !== undefined){
      const confirm = window.confirm('Deseja realmente excluir o dispositivo?');
      if(confirm) {
        
        hasDeviceData(device.deviceId).then(r => r.data).then(response => {
          if(response == true){
            if(hasRolePlantDeviceDetele){
              const confirmDeviceData = window.confirm('Esse dispositivo possui dados de monitoria, deseja realmente excluir?');
              if(confirmDeviceData){
                const devicesForm = getValues().devices.filter(d => d.idx !== device.idx);
                setValue('devices', devicesForm);
                forceUpdate();
              }

            }else{

              toast.warning('Não é permitido excluir, pois existem dados de monitoria vinculados a esse dispositivo.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
            }

          }else{

            const devicesForm = getValues().devices.filter(d => d.idx !== device.idx);
            setValue('devices', devicesForm);
            forceUpdate();
          }

        }).catch(() => {
          setIsPosting(false);
          toast.error('Houve um problema ao verificar dados de dispositivo. Por favor tente novamente.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        });

      }

    }else{

      const devicesForm = getValues().devices.filter(d => d.idx !== device.idx);
      setValue('devices', devicesForm);
      forceUpdate();
    }
  };

  const hasDevicesCanBeValidated = (): boolean => {
    return true; //fixed after remove only Growatt validation
  };

  const getSerialNumberClassName = (index, device) => {
    return getDeviceValidationClassName(device.validationStatusSn);
  };

  const getDataloggerSerialNumberClassName = (index, device) => {
    return getDeviceValidationClassName(device.validationStatusDl);
  };

  const getDeviceValidationClassName = (validationStatus: string) => {
    const hasNoValidation = DeviceValidationStatusType.HAS_NO_VALIDATION.toString();
    const unknown = DeviceValidationStatusType.UNKNOWN.toString();
    if(validationStatus === DeviceValidationStatusType.INVALID.toString()) {
      return 'gia-form-field-is-invalid';
    } else if(validationStatus === DeviceValidationStatusType.VALID.toString()) {
      return 'gia-form-field-is-valid';
    } else if(validationStatus === hasNoValidation || validationStatus === unknown) {
      return 'gia-form-field-warning';
    }
    return '';
  }

  const getSerialNumberErrorMessage = (index, device) => {
    if (device.validationStatusSn === DeviceValidationStatusType.INVALID.toString()) {
      return 'Número Série Inválido';
    } else if(device.validationStatusSn === DeviceValidationStatusType.VALID.toString()) {
      return 'Número Série Válido';
    } else if(device.validationStatusSn === DeviceValidationStatusType.HAS_NO_VALIDATION.toString()) {
      return 'Não há validação disponível';
    } else if(device.validationStatusSn === DeviceValidationStatusType.UNKNOWN.toString()) {
      return 'Não validado';
    }
    return '';
  };

  const getDataloggerSerialNumberErrorMessage = (index, device) => {
    if (device.validationStatusDl === DeviceValidationStatusType.INVALID.toString()) {
      return 'Número Série Datalogger Inválido';
    } else if(device.validationStatusDl === DeviceValidationStatusType.VALID.toString()) {
      return 'Número Série Datalogger Válido';
    } else if(device.validationStatusDl === DeviceValidationStatusType.HAS_NO_VALIDATION.toString()) {
      return 'Não há validação disponível';
    } else if(device.validationStatusDl === DeviceValidationStatusType.UNKNOWN.toString()) {
      return 'Não validado';
    }
    return '';
  };

  //TODO see if there's another way to check if the field value has changed to reset the status only when the value is modified for better performance
  const onChangeDeviceSn = (index, device) => {
    device.validationStatusSn = DeviceValidationStatusType.NOT_VALIDATED_YET.toString();
    forceUpdate();
  };

  const onChangeDeviceDl = (index, device) => {
    device.validationStatusDl = DeviceValidationStatusType.NOT_VALIDATED_YET.toString();
    forceUpdate();
  };

  const checkForUnverifiedDevices = (data: any) => {
    const values = getValues();
    const devicesFromForm = values.devices;

    data.deviceValidationStatus = PlantDeviceValidationStatusType.ALL_VALID;

    if(hasUnverifiedDevices(devicesFromForm)) {
      data.deviceValidationStatus = PlantDeviceValidationStatusType.SKIPPED;
      //TODO make this a modal
      return window.confirm('Existem dispositivos não validados, deseja continuar mesmo assim?');
    }

    if(hasInvalidDevices(devicesFromForm)) {
      data.deviceValidationStatus = PlantDeviceValidationStatusType.SAVED_WITH_INVALID;
      //TODO make this a modal
      return window.confirm('Existem dispositivos com número de série inválido, deseja continuar mesmo assim?');
    }

    return true;
  };

  const hasUnverifiedDevices = (devices: any[]):boolean => {
    return devices?.some(device => {
      const hasSnUnverified = device?.validationStatusSn === DeviceValidationStatusType.NOT_VALIDATED_YET;
      const hasDlUnverified = device?.validationStatusDl === DeviceValidationStatusType.NOT_VALIDATED_YET;
      return hasSnUnverified || hasDlUnverified;
    });
  }

  const hasInvalidDevices = (devices: any[]):boolean => {
    return devices?.some(device => {
      const hasSnInvalid = device?.validationStatusSn === DeviceValidationStatusType.INVALID;
      const hasDlInvalid = device?.validationStatusDl === DeviceValidationStatusType.INVALID;
      return hasSnInvalid || hasDlInvalid;
    });
  }

  const projectIdValidate = (value) => {
    const projectidregex = new RegExp(projectIdRegex);
    return projectidregex.test(value);
  }
  
  useEffect(() => {
    if (!isBrand) {
      const devicesForm = getValues().devices;
      if(devicesForm) {
        for (let i = 0; i < devicesForm.length; i++) {
          devicesForm[i].brandId = undefined;
        }
        setValue('devices', devicesForm);
      }
    }
  }, [isBrand, setValue]);
  
  const baseURL = `${process.env.REACT_APP_BACKEND_API_URL}`;
  const findByZipCode = (value:string) => {

    let zipcodeValue = zipCodeOnlyNumbers(value);
    setValue("zipCode.zipCode", zipcodeValue);

    if(zipcodeValue.length === 8){

      let apiURL = `${baseURL}/address-zipcode/${zipcodeValue}`;
      
      Promise.all([
        axios.get(apiURL)
      ]).then(response => {

        const zipCode = response[0]?.data;

        if(zipCode.address != null){
          setValidErrorZipcode(false);
          setTextErrorZipcode("");
          setZipcodeValid(true);
          setStateZipcodeForm(true);
          setValue("zipCode.address", zipCode.address);

          if(zipCode.address != '')
            setStateAddressField(true);
          else
            setStateAddressField(false);

          if(zipCode.district != '')
            setStateDistrictField(true);
          else
            setStateDistrictField(false);

          setValue("zipCode.district", zipCode.district);
          setValue("zipCode.addressCityZipcode.name", zipCode.addressCityZipcode.name);
          setValue("zipCode.addressStateZipcode.state", zipCode.addressStateZipcode.state);
        }else{
          
          if(paramInsertAddressForm?.value == "0"){
            setValidErrorZipcode(true);
            setTextErrorZipcode("CEP inválido");
            setZipcodeValid(false);
            return;
          }

          setStateZipcodeForm(false);
          setStateAddressField(false);
          setStateDistrictField(false);
          setValue("zipCode.address", "");
          setValue("zipCode.district", "");
          setValue("zipCode.addressCityZipcode.name", "");
          setValue("zipCode.addressStateZipcode.state", "");
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Erro ao carregar cep");
      });
    }else{

      if(zipcodeValue.length == 0){
        setValue("zipCode.zipCode", "");
      }

      setZipcodeValid(true);
      setStateZipcodeForm(true);
      setStateAddressField(true);
      setStateDistrictField(true);
      setValue("zipCode.address", "");
      setValue("zipCode.district", "");
      setValue("zipCode.addressCityZipcode.name", "");
      setValue("zipCode.addressStateZipcode.state", "");
      setValue("addressNumber", "");
      setValue("complement", "");
    }
  }

  const validateCep = (value) => {

    let zipcodeValue = zipCodeOnlyNumbers(value);

    if(zipcodeValue.length > 0 && zipcodeValue.length < 8){
      setValidErrorZipcode(true);
      setTextErrorZipcode("Digite os 8 números do CEP");
      return;
    }else{
      if(zipcodeValid){
        setValidErrorZipcode(false);
        setTextErrorZipcode("");
      }
    }

    if(Number.parseInt(zipcodeValue) <= 1000000){
      setValidErrorZipcode(true);
      setTextErrorZipcode("CEP inválido");
      return;
    }
  }

  const setCitiesList = () => {

    if(getValues('zipCode.addressStateZipcode.state') != ''){

      let apiURL = `${baseURL}/address-zipcode/citylist/${getValues('zipCode.addressStateZipcode.state')}`;
        
      Promise.all([
        axios.get(apiURL)
      ]).then(response => {

        const types = response[0]?.data;
        setCities(types);

      })
      .catch(err => {
        console.log(err);
        toast.error("Erro ao carregar lsita de cidade");
      });
    }
  }

  return (
    <div className={`${styles.plantManager} gia-manager`}>
      {isLoading && <PlantManagerSkeleton></PlantManagerSkeleton>}
      {!isLoading &&
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="gia-form">
        <div className="gia-page-title-default header">
          Usinas
        </div>
        <div>

          <Row>
            <Form.Group as={Col} md="5">
              <GiaTextField
                title="ID do Projeto"
                isInvalid={!!errors.projectId}
                isInvalidText={errors?.projectId?.message}
                required={true}
                registerName={{...register('projectId')}}></GiaTextField>
                {errors?.projectId && <div className="gia-message-error">{errors?.projectId?.message}</div>}
            </Form.Group>
          </Row>
          <Row>
            <div className="label required">Financiador</div>
            <Form.Group as={Col} md="5">
              <Form.Select as={Col} 
                title="Financiador"
                isInvalid={!!errors.financierId}
                required={true}
                {...register('financierId', {valueAsNumber: true})}>
                {financiers.map((option) => {
                  return <option key={option.id} value={option.id}>{option.name}</option>
                })}
              </Form.Select>
              <div className="gia-form-feedback-message"></div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="5">
              <GiaTextField
                title="Nome"
                isInvalid={!!errors.plantName}
                isInvalidText={errors?.plantName?.message}
                required={true}
                registerName={{...register('plantName')}}></GiaTextField>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <PersonaSelect
                type={"CUSTOMER" as PersonaTypeEnum}
                persona={customer}
                onSelectPersona={(customer) => {
                  setValue('customer', customer);
                  forceUpdate();
                }}
                isInvalidPersona={!!errors.customer}
                hasAllPerson={false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xxl="5" xl="6" lg="8" md="10" sm="12">
              <PersonaSelect
                type={"INTEGRATOR" as PersonaTypeEnum}
                persona={integrator}
                onSelectPersona={(integrator) => {
                  setValue('integrator', integrator);
                  forceUpdate();
                }}
                isInvalidPersona={!!errors.integrator}
                hasAllPerson={false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="5">
              <div className="label required">Situação</div>
              <Form.Check
                id="active-checkbox"
                label="Ativo"
                isInvalid={!!errors.active}
                value={1}
                {...register('active')}></Form.Check>
              <div className="border-bottom mb-3"></div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="3">
              <GiaTextField
                title="Pico de potência (kWp)"
                type="number"
                placeholder="0,00"
                isInvalid={!!errors.peakPower}
                isInvalidText={errors?.peakPower?.message}
                required={true}
                registerName={{...register('peakPower', { valueAsNumber: true })}}></GiaTextField>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <GiaTextField
                title="Produção estimada por mês (kWh)"
                type="number"
                placeholder="0.0000"
                isInvalid={!!errors.powerByMonth}
                isInvalidText={errors?.powerByMonth?.message}
                required={true}
                registerName={{...register('powerByMonth', { valueAsNumber: true })}}></GiaTextField>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="3">
              <GiaTextField
                title="Latitude (DD)"
                placeholder="-0.00000"
                type="number"
                isInvalid={!!errors.latitude}
                isInvalidText={errors?.latitude?.message}
                registerName={{...register('latitude', { valueAsNumber: true })}}></GiaTextField>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <GiaTextField
                title="Longitude (DD)"
                placeholder="-0.00000"
                type="number"
                isInvalid={!!errors.longitude}
                isInvalidText={errors?.longitude?.message}
                registerName={{...register('longitude', { valueAsNumber: true })}}></GiaTextField>
            </Form.Group>
          </Row>

          <Row>
              <Form.Group as={Col} md="3">
                {/* <GiaTextField
                  title="CEP"
                  isInvalid={validErrorZipcode}
                  isInvalidText={textErrorZipcode}
                  required={false}
                  showMessage={true}
                  onChange={(e) => findByZipCode(e.target.value)}
                  //onBlur={e => validateCep(e.target.value)}
                  registerName={{ ...register('zipCode.zipCode') }}></GiaTextField> */}
                  <div className={`${styles.giaTextField}`}>
                    <div className={`label required`} title="CEP">CEP</div>
                    <FormControl {...register('zipCode.zipCode')}
                                isInvalid={validErrorZipcode}
                                onChange={(e) => findByZipCode(e.target.value)}
                                onBlur={e => validateCep(e.target.value)}
                                />
                    {<div className={`${styles.feedbackMessage} gia-message-error`}>
                      {true && <span>{textErrorZipcode}</span>}
                      </div>}
                  </div>
              </Form.Group>
              <div className={stateZipcodeForm ? 'label col-md-8 hiddenCepMessage' : 'label col-md-8 showCepMessage'}>CEP não encontrado<br/>Por favor reveja o número do CEP ou entre com os dados de endereço abaixo</div>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Endereço"
                  isInvalid={!!errors.zipCode?.address}
                  isInvalidText={errors?.zipCode?.address?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateAdressField}
                  registerName={{ ...register('zipCode.address') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Bairro"
                  isInvalid={!!errors.zipCode?.district}
                  isInvalidText={errors?.zipCode?.district?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateDistrictField}
                  registerName={{ ...register('zipCode.district') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="2">
                <div className="label">UF</div>
                <Form.Select
                  title="UF"
                  isInvalid={!!errors.zipCode?.addressStateZipcode?.state}
                  required={false}
                  disabled={stateZipcodeForm}
                  {...register('zipCode.addressStateZipcode.state')}
                  onChange={e => { setValue("zipCode.addressStateZipcode.state", e.target.value); updateState({}); setCitiesList();}}>
                  <option key={'select-a-option'} value={''}>Selecione...</option>
                  {Object.values(ufEnum).sort((a, b) => a.localeCompare(b)).map((option) => {
                    return <option key={option} value={option}>{option}</option>
                  })}
                </Form.Select>
                <div className="gia-form-feedback-message"></div>
              </Form.Group>
            </Row>
            {stateZipcodeForm && 
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Cidade"
                  isInvalid={!!errors.zipCode?.addressCityZipcode?.name}
                  isInvalidText={errors?.zipCode?.addressCityZipcode?.name?.message}
                  showMessage={true}
                  required={false}
                  disabled={stateZipcodeForm}
                  registerName={{ ...register('zipCode.addressCityZipcode.name') }}></GiaTextField>
              </Form.Group>
            </Row>
            }
            {!stateZipcodeForm &&
            <Row>
              <Form.Group as={Col} md="4">
                <div className="label required">Cidade</div>
                <Form.Select
                  title="Cidade"
                  isInvalid={!!errors.zipCode?.addressCityZipcode?.name}
                  required={true}
                  {...register('zipCode.addressCityZipcode.name')}>
                  {cities.map((option, i) => {
                    return <option key={i} value={option.name}>{option.name}</option>
                  })}
                </Form.Select>
                  <div className="gia-form-feedback-message"></div>
              </Form.Group>
            </Row>
            }
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Número"
                  isInvalid={!!errors.addressNumber}
                  isInvalidText={errors?.addressNumber?.message}
                  showMessage={true}
                  required={false}
                  registerName={{ ...register('addressNumber') }}></GiaTextField>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  title="Complemento"
                  isInvalid={!!errors.complement}
                  isInvalidText={errors?.complement?.message}
                  showMessage={true}
                  required={false}
                  registerName={{ ...register('complement') }}></GiaTextField>
              </Form.Group>
            </Row>

          <Row>
            <Form.Group as={Col} md="3">
              <GiaDatePicker
                register={{...register('dtStartProduction')}}
                title="Data do início da produção"
                required={false}
                selected={getValues().dtStartProduction}
                onChange={(date: Date) => {setValue('dtStartProduction', date);updateState({});}}
                timeFormat="HH:mm"
                timeIntervals={1}
                timeCaption="time"
                className="form-control"
                dateFormat="dd/MM/yyyy"/>
            </Form.Group>
          </Row>

          <div className="label label-devices required">DISPOSITIVOS</div>

            <Row>
              <Form.Group as={Col} md="5">
                <Form.Check
                  id="brand-checkbox"
                  label="Possui Marca?"
                  isInvalid={!!errors.active}
                  checked={isBrand}
                  onChange={(e) => setIsBrand(e.target.checked)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-1">
              {getValues().devices && getValues().devices?.length > 0 &&
                <>
                    <Col md="2"><div className="label" title="Fabricante">Fabricante</div></Col>
                    {isBrand &&
                      <>
                        <Col md="2"><div className="label" title="Marca">Marca</div></Col>
                      </>
                    }
                    <Col md="2"><div className="label" title="Distribuidor">Distribuidor</div></Col>
                    <Col md="3"><div className="label" title="Número de série do Inversor">Número de série do Inversor</div></Col>
                    <Col md="3"><div className="label" title="Número de série do Datalogger">Número de série do Datalogger</div></Col>
                </>
              }
              {(!getValues().devices || !getValues().devices?.length) &&
                  <>
                    <div className="gia-message-warning">Não há dispositivos</div>
                  </>
              }
              {errors?.devices && <div className="gia-message-error">{errors?.devices?.message}</div>}
            </Row>


          {getValues().devices && getValues().devices.map((device, i) => (
            <Row key={i}>
              {!isValidatingDevices &&
                <>
                  <Form.Group as={Col} md="2">
                    <Form.Select
                      title="Fabricante"
                      isInvalid={!!errors.devices?.[i]?.manufacturer?.id}
                      {...register(`devices.${i}.manufacturer.id`, {
                        valueAsNumber: true,
                        onChange: () => forceUpdate()
                      })}>
                        {manufacturers.map((option, i) => {
                          return <option key={i} value={option.id}>{option.name}</option>
                        })}
                    </Form.Select>
                    <div className="gia-form-feedback-message"></div>
                  </Form.Group>

                  {isBrand &&
                    <>
                      <Form.Group as={Col} md="2">
                        <Form.Select
                          title="Marca"
                          isInvalid={!!errors.devices?.[i]?.brandId}
                          {...register(`devices.${i}.brandId`, {
                            valueAsNumber: true,
                            onChange: () => forceUpdate()
                          })}>
                            {brands.map((option, i) => {
                              return <option key={i} value={option.id}>{option.name}</option>
                            })}
                        </Form.Select>
                        <div className="gia-form-feedback-message"></div>
                      </Form.Group>
                    </>
                  }
                  
                  <Form.Group as={Col} md="2">
                    <Form.Select
                      title="Distribuidor"
                      isInvalid={!!errors.devices?.[i]?.distributor?.id}
                      {...register(`devices.${i}.distributor.id`, {valueAsNumber: true})}>
                        {distributors.map((option, i) => {
                          return <option key={i} value={option.id}>{option.name}</option>
                        })}
                    </Form.Select>
                    <div className="gia-form-feedback-message"></div>
                  </Form.Group>
                  <Form.Group as={Col} md="3" title="Número de série do Inversor">
                    <GiaTextField
                      placeholder="Número de série do Inversor"
                      className={getSerialNumberClassName(i, device)}
                      isInvalid={!!errors.devices?.[i]?.deviceSn}
                      showMessage={true}
                      isInvalidText={getSerialNumberErrorMessage(i, device)}
                      onChange={() => onChangeDeviceSn(i, device)}
                      registerName={{...register(`devices.${i}.deviceSn`)}}></GiaTextField>
                  </Form.Group>
                  <Form.Group as={Col} md="3" title="Número de série do Datalogger">
                    <GiaTextField
                      placeholder="Número de série do Datalogger"
                      className={getDataloggerSerialNumberClassName(i, device)}
                      isInvalid={!!errors.devices?.[i]?.dataloggerSn}
                      showMessage={true}
                      isInvalidText={getDataloggerSerialNumberErrorMessage(i, device)}
                      onChange={() => onChangeDeviceDl(i, device)}
                      registerName={{...register(`devices.${i}.dataloggerSn`)}}></GiaTextField>
                  </Form.Group>
                  <Col md="2">
                    <Button
                      type="button"
                      disabled={isValidatingDevices}
                      variant="outline-light"
                      size="sm"
                      onClick={() => removeDevice(device)}>Remover</Button>
                  </Col>
              </>
              }
              {isValidatingDevices &&
                <>
                  <Col md="2"><Skeleton height={32} className="mb-4"></Skeleton></Col>
                  <Col md="2"><Skeleton height={32}></Skeleton></Col>
                  <Col md="2"><Skeleton height={32}></Skeleton></Col>
                  <Col md="2"><Skeleton height={32}></Skeleton></Col>
                  <Col md="1"><Skeleton height={32}></Skeleton></Col>
                </>
              }
            </Row>
          ))}
          <Row className="row-buttons-wrapper">
            <Col md={12}>
              <Button type="button" disabled={isValidatingDevices} className="btn-add-new-device" size="sm" variant="outline-light" onClick={addDevice}>Adicionar {(getValues()?.devices?.length > 0) ? 'Outro' : ''} Dispositivo</Button>
              {hasDevicesCanBeValidated() &&
                  <Button type="button" disabled={isValidatingDevices} className="btn-add-new-device" size="sm" variant="outline-light" onClick={validatePlantDevices}>
                    {isValidatingDevices &&
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> <span>Processando...</span>
                      </>
                    }
                    {!isValidatingDevices && <span>Validar Dispositivos</span>}
                  </Button>
              }
            </Col>
          </Row>
        </div>
        <div className="row-buttons-wrapper">
          <Row>
            <Col>
              <Button type="submit" size="sm" disabled={isPosting || isValidatingDevices}>Salvar</Button>
              <Button type="button" variant="secondary" onClick={cancel} size="sm">Voltar</Button>
              {plantId && <Button type="button" variant="danger" onClick={deletePlant} size="sm" disabled={isPosting}>Excluir</Button>}
            </Col>
          </Row>
        </div>
      </Form>
      }
    </div>
  );
};

export default PlantManager;
