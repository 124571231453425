import EnergyGeneratedAtPlantSummaryLastPeriodsInterface
  from '../interfaces/energy-generated-at-plant-summary-last-periods.interface';

class EnergyGeneratedAtPlantSummaryFactory {

  static makeEmptyLastPeriods(): EnergyGeneratedAtPlantSummaryLastPeriodsInterface {
    return {
      today: {value: undefined, color: undefined},
      yesterday: {value: undefined, color: undefined},
      last15Days: {value: undefined, color: undefined},
      lastMonth: {value: undefined, color: undefined},
    };
  }
}

export default EnergyGeneratedAtPlantSummaryFactory;
