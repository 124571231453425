import React from 'react';
import './App.scss';
import {Outlet} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <Outlet />
      <ToastContainer/>
    </div>
  );
}

export default App;
