import React, {FC, useEffect, useState} from 'react';
import styles from './overview.module.scss';
import EmissionsReduction from '../../components/emissions-reduction/emissions-reduction';
import EnergyGeneratedAtPlant from '../../components/energy-generated-at-plant/energy-generated-at-plant';
import {
  EnergyGeneratedAtPlantInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant.interface';
import {EmissionReductionInterface} from '../../components/emissions-reduction/interfaces/emission-reduction.interface';
import EnergyGeneratedAtPlantSummaryLastPeriodsInterface
  from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-summary-last-periods.interface';
import EnergyGeneratedAtPlantFactory
  from '../../components/energy-generated-at-plant/energy-generated-at-plant.factory';
import {
  handleLastPeriodsPercentage,
  loadEmissionsReduction,
  loadEnergyGeneratedAtPlant,
  loadPowerByLastPeriods
} from '../../shared/services/gia.service';

interface OverviewProps {
}

const Overview: FC<OverviewProps> = () => {

  const [emissionsReduction, setEmissionsReduction] = useState<EmissionReductionInterface>({} as EmissionReductionInterface);
  const [energyGeneratedAtPlant, setEnergyGeneratedAtPlant] = useState<EnergyGeneratedAtPlantInterface>(EnergyGeneratedAtPlantFactory.makeInitialStateEnergyGeneratedAtPlant());
  const [lastPeriodsPercentage, setLastPeriodsPercentage] = useState<EnergyGeneratedAtPlantSummaryLastPeriodsInterface>();
  const [filter, setFilter] = useState<string>('today');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const lastPeriods = await loadPowerByLastPeriods();
      setLastPeriodsPercentage(handleLastPeriodsPercentage(lastPeriods));
      setIsLoading(false);
    }
    fetchData().catch(console.error);
  },[]);

  useEffect(() => {
    const reloadData = (period: any) => {
      const fetchData = async () => {
        const energyGeneratedAtPlantUpdate = await loadEnergyGeneratedAtPlant(period);
        const emissionsReduction = await loadEmissionsReduction(period);
        energyGeneratedAtPlantUpdate.summary.lastPeriodsPercentage = lastPeriodsPercentage as EnergyGeneratedAtPlantSummaryLastPeriodsInterface;
        setEnergyGeneratedAtPlant(energyGeneratedAtPlantUpdate);
        setEmissionsReduction(emissionsReduction);
      }
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false)).catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    }

    /* The Emission Reduction block depends on calculated data from energy generated block */
    if(lastPeriodsPercentage && filter) {
      reloadData(filter);
    }
  }, [filter, lastPeriodsPercentage]);

  const onChangeFilter = (filter) => {
    if(filter) {
      setFilter(filter);
    }
  };

  return (
    <div className={styles.Overview} data-testid="Overview">
      <EmissionsReduction emissionReduction={emissionsReduction} isLoading={isLoading}/>
      <EnergyGeneratedAtPlant onChangeFilter={onChangeFilter} energyGeneratedAtPlant={energyGeneratedAtPlant} isLoading={isLoading}/>
    </div>
  );
};


export default Overview;
