import {FC, useContext, useEffect, useRef, useState} from 'react';
import styles from './user-configuration.module.scss';
import {useForm} from 'react-hook-form';
import {GiaTextField} from '../../components/gia-forms-components/gia-forms-components';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button} from 'react-bootstrap';
import UserService, {UserModel} from '../../modules/auth/UserService';
import axios, {AxiosError} from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import {toAbsoluteUrl} from '../../shared/helpers/AssetHelpers';
import ImageModal from './image-modal/image-modal';
import {ImageModalProps} from './image-modal/image-modal-interface';
import {UserPictureContext} from '../../shared/contexts/UserPictureContext';

interface UserConfigurationProps { }

const UserConfiguration: FC<UserConfigurationProps> = () => {
  return (
    <div className={`${styles.userConfiguration}   container-fluid `}>
      <ToastContainer />
      <div className={`${styles.cardFull}  `}>
        <div className={`gia-page-title-default ${styles.titlePreferences}`}>Preferências</div>
        <div className={` row d-flex   flex-column  align-items-start`}>
          <UserConfigurationForm></UserConfigurationForm>
        </div>
      </div>
    </div>
  );
};

export const UserConfigurationForm: FC = () => {
  const [imageModal, setImageModal] = useState<ImageModalProps>({
    show: false,
    srcImage: undefined,
  });
  const [picture, setPicture] = useState<string>();
  const { setPictureContext } = useContext(UserPictureContext);
  const fileInput = useRef<HTMLInputElement>(null);
  const [currentUser, setCurrentUser] = useState<UserModel>();
  const { register, handleSubmit, setValue } = useForm<UserModel>();
  const navigate = useNavigate();

  useEffect(() => {
    UserService.getUserInfo().then((response) => {
      setCurrentUser(response.data);
      const user = response.data;
      setPicture(user.picture ? user.picture : '');

      //TODO use reset from form here
      setValue('id', user.id);
      setValue('picture', user.picture);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('username', user.username);
    });
  }, []);

  const updatePicture = (files: FileList | null) => {
    if (files != null && files.length > 0) {
      setImageModal({
        show: true,
        srcImage: URL.createObjectURL(files[0]),
      });
    }
  };

  const saveData = (data: UserModel) => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_API_URL}/user/update-profile`, data)
      .then((response) => {
        setPictureContext(data.picture || '');
        toast.success('Usuário atualizado com sucesso', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error: Error | AxiosError) => {
        toast.error(`Não foi possível atualizar o usuário \n Entre em contato com administrador`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const resetPicture = () => {
    setPicture('');
    setValue('picture', '');
  };

  const updatePassword = () => {
    UserService.doUpdatePassword();
  };

  const getNotifyCloseModal = (e: ImageModalProps) => {
    setImageModal({
      show: e.show,
    });

    if (e.srcImage) {
      setPicture(e.srcImage);
      setValue('picture', e.srcImage);
    }
  };

  return (
    <form className="col-md-7" onSubmit={handleSubmit(saveData)}>
      <div
        className={`${styles.containerImage} d-flex justify-content-start align-items-start row`}>
        <div
          className={`${styles.imageOverlay} col-4 col-lg-2 ${picture ? '' : styles.blankUser}`}
          style={{ backgroundImage: `url("${picture || toAbsoluteUrl('/media/user.svg')}")` }}>
          <div className={`${styles.overlay} pt-2`} onClick={() => fileInput.current?.click()}>
            <input
              type="file"
              {...register('picture')}
              name="image"
              onChange={(e) => updatePicture(e.target.files)}
              ref={fileInput}
              style={{ display: 'none' }}
              onClick={(e) => e.currentTarget.value = ''} />
            <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
          </div>
        </div>
        {picture &&
          <div className={styles.deleteImage}>
            <FontAwesomeIcon icon={faCircleXmark} onClick={resetPicture}></FontAwesomeIcon>
          </div>
        }
      </div>
      <div className="row mb-4">
        <GiaTextField
          title="Nome"
          type="text"
          registerName={register('firstName')}
          placeholder="Nome"
          max={20}
          className="col-lg-6 col-sm-12 col-xs-12 col-md-6 " />
      </div>
      <div className="row mb-4">
        <GiaTextField
          title="Sobrenome"
          type="text"
          registerName={register('lastName')}
          placeholder="Sobrenome"
          max={20}
          className="col-lg-6 col-sm-12 col-xs-12 col-md-6" />
      </div>
      <div className={`${styles.containerButtons} d-flex`}>
        <Button type="submit" size="sm">Salvar</Button>
        <Button type="button" onClick={() => updatePassword()} size="sm" variant="outline-light"> Alterar senha</Button>
        <Button type="button" size="sm" onClick={() => navigate('/')} variant="secondary">Cancelar</Button>
      </div>
      {imageModal.show &&
          <ImageModal
            show={imageModal.show}
            srcImage={imageModal.srcImage}
            notifyClose={getNotifyCloseModal}></ImageModal>}
    </form>
  );
};

export default UserConfiguration;
