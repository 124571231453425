import React, {FC} from 'react';
import styles from './energy-generated-at-plant-chart.module.scss';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import {EnergyGeneratedAtPlantChartInterface} from '../interfaces/energy-generated-at-plant-chart.interface';
import GiaChartStatistic from '../../../shared/components/chart-statistic/chart-statistic';
import Skeleton from 'react-loading-skeleton';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

interface EnergyGeneratedAtPlantChartProps {
  chart: EnergyGeneratedAtPlantChartInterface,
  isLoading?: boolean
}

export const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  },
  interaction: {
    mode: 'index',
    intersect: false
  },
  scales: {
    x: {
      display: true
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'kWh'
      }
    }
  }
} as any;

const EnergyGeneratedAtPlantChart: FC<EnergyGeneratedAtPlantChartProps> = ({chart, isLoading}) => {
  return (
    <div className={styles.EnergyGeneratedAtPlantChart} data-testid="EnergyGeneratedAtPlantChart">

      {isLoading && <Skeleton width={'100%'} height={400}/>}

      {!isLoading && chart.data &&
          <>
              <div className={styles.chartWrapper}>
                {chart.type === 'line' && <Line options={defaultChartOptions} data={chart.data}/>}
                {chart.type === 'bar' && <Bar options={defaultChartOptions} data={chart.data}/>}
              </div>
              <div className={styles.chartStatisticsWrapper}>
                  <GiaChartStatistic statistics={chart.statistics}/>
              </div>
          </>
      }
    </div>
  );
};

export default EnergyGeneratedAtPlantChart;
