import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC} from "react";
import {Badge, Stack} from "react-bootstrap";
import styles from './tags.module.scss'


export interface TagsSelectedInterface {
  option: string,
  text: string,
  value: string
}

export interface TagsInterface {
  selected: TagsSelectedInterface[],
  changeTags: (selected: TagsSelectedInterface[]) => void;
}


export const Tags: FC<TagsInterface> = (props) => {

  const onHandleTags = (item) => {
    const index = props.selected.findIndex(filter => filter === item)
    props.selected.splice(index, 1);
    props.changeTags(props.selected);
  }

  return (
    <div className={styles.tags}>
      {props.selected.map((item, i) => {
        return (
          <div key={i} className='me-2'>
            <Stack direction="horizontal" className="d-flex">
              <Badge bg="light" text="muted" key={i}>
                {item.text}: {item.value}
              </Badge>
              <div onClick={() => onHandleTags(item)} className={styles.deleteIcon}>
                <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon>
              </div>
            </Stack>
          </div>
        );
      })}
    </div>
  );
}

export default Tags;
