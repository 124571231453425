import { z } from "zod";
import { Roles } from "../../modules/auth/roles";

export const groupFormSchema = z.object({
    id: z.number().nullable().optional(),
    name: z.string().min(1).max(128),
    description: z.string().max(300).optional(),
    type: z.string().min(1),
    persona: z.object({
      id: z.number(),
      name: z.string()
    }),
    roles: z.object({
      id: z.number(),
      name: z.custom<Roles>(),
    }).array().min(1, "Necessário que seja incluído no mínimo uma Função")
  });
export type GroupFormSchemaType = z.infer<typeof groupFormSchema>;
