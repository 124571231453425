import { FC, useEffect, useState } from "react";
import RobbuSchedulerGroupInterface from "../../shared/interfaces/robbu-scheduler-group-interface";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { filterByFields } from "../../components/filter/filter";
import Skeleton from "react-loading-skeleton";
import { Button } from "react-bootstrap";
import SubHeaderComponent from "../../components/subHeader/subHeader";
import CustomDatatable from "../../components/custom-datatable/custom-datatable";
import styles from "./robbu-scheduler-group.module.scss";
import RobbuSchedulerGroupRequests from "./_requests";

interface RobbuSchedulerGroupProps {};
interface DatatableRowInterface extends RobbuSchedulerGroupInterface {} ;

const convertResponse = (response:DatatableRowInterface[]) => {
    return response
      .map((r: DatatableRowInterface) => {
      return {
        id: r.id,
        name: r.name,
      } as DatatableRowInterface;
    });
}

const columns: TableColumn<DatatableRowInterface>[] = [
    {
        name: 'Nome',
        selector: row => row.name!,
        sortable: true,
    },
];

const RobbuSchedulerGroup: FC<RobbuSchedulerGroupProps> = () => {

    const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const navigate = useNavigate();

    const filteredItems:DatatableRowInterface[] = filterByFields(datasource, filterText, ['name']);

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const { data } = await RobbuSchedulerGroupRequests.getAllScheduleGroups();
          setDatasource(convertResponse(data));
          setIsLoading(false);
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }finally{
          setIsLoading(false);
        }
      };

      fetchData();
    },[]);


    const onRowClicked = (row:RobbuSchedulerGroupInterface) => {
      navigate(`edit/${row.id}`, {state: { row }});
    };

    return(
    <div className={styles.robbuSchedulerContainer} data-testid="UsersContainer">
      <div className="gia-page-title-default">Grupos de Mensagens</div>
      {isLoading && <Skeleton height={48} />}

      {isLoading && <Skeleton height={'50vh'} />}

      {!isLoading && 
      <>
      <div className="container-fluid g-0 mb-3">
        <div className="row">
          <div className="col-sm-12 d-flex">
            <Button type="button" variant="primary" size="sm" className="me-4" onClick={() => navigate('/robbu-scheduler-group/edit')}>Criar Grupo de Usinas</Button>
            {!isLoading && <SubHeaderComponent filterText={filterText} resetPaginationToggle={resetPaginationToggle} setResetPaginationToggle={setResetPaginationToggle} setFilterText={setFilterText}/>}
          </div>
        </div>
      </div>
      <CustomDatatable columns={columns} filteredItems={filteredItems} filterText={filterText} setFilterText={setFilterText} isLoading={isLoading} selectableRows={false} onRowClicked={onRowClicked} resetPaginationToggle={resetPaginationToggle}/>
      </>
      }
    </div>
    );

}

export default RobbuSchedulerGroup;

