import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './customer.module.scss'
import {FilterOptionsInterface, MultipleFilterComponent} from '../../components/filter/filter';
import {Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {TableColumn} from 'react-data-table-component';
import CustomDatatable from '../../components/custom-datatable/custom-datatable';
import {maskDocument} from '../../shared/helpers/string-utils.helper';
import Tags, {TagsSelectedInterface} from '../../components/tags/tags';
import {listAll} from './_requests';
import PersonaInterface from '../../shared/interfaces/persona.interface';

export default function Customer() {

  const filterOptions: FilterOptionsInterface[] = useMemo(() => [
    {
      "text": "Nome",
      "field": "name"
    },
    {
      "text": "Documento",
      "field": "document"
    },
    {
      "text": "E-mail",
      "field": "email"
    },
  ], []);

  const columns: TableColumn<any>[] = [
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
      sortField: "legalEntity.name"
    },
    {
      name: 'Documento',
      selector: row => maskDocument(row.document),
      sortable: true,
      sortField: "legalEntity.document"
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      sortField: "legalEntity.email"
    },

  ];

  type sortDirectionType = 'asc' | 'desc';

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [sortField, setSortField] = useState<string>('legalEntity.name');
  const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc');

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [filterSelect, setFilterSelect] = useState<string>('name');
  const [applyFilter, setApplyFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<TagsSelectedInterface[]>([]);
  const [filteredItems, setFilteredItems] = useState<PersonaInterface[]>([]);
  const [notifyChange, setNotifyChange] = useState(false);

  const normalizeDocument = useCallback((document) => {
    if (document !== undefined && document !== null) {
      return document.replace(/[.,\-/*_]/g, "").toLowerCase();
    }
    return "";
  }, []);

  const normalizeFilters = useCallback((filters) => {
    let filterParam = {} as any;

    filters.forEach((item) => {
      if (item.option === 'document') {
        item.value = normalizeDocument(item.value)
      }
      filterParam[item.option] = item.value;
    });

    const filterOptions = {
      legalEntity: filterParam
    };

    return '&filterOptions=' + encodeURI(JSON.stringify(filterOptions));
  }, [normalizeDocument]);

  const normalizePagination = useCallback((currentPage: number, size: number, sort: string, sortDirection: sortDirectionType) => {
    return `page=${currentPage}&size=${size}&sort=${sort},${sortDirection}`;
  }, []);

  const convertResponse = (data: any[]): PersonaInterface[] => {
    return data.map(r => {
      return {
        "id": r.id,
        "name": r.legalEntity.name,
        "type": r.description,
        "document": r.legalEntity.document,
        "email": r.legalEntity.email,
        "zipCode": r.legalEntity.zipCode
      }
    })
  }

  //TODO this useEffect is triggered twice or more because changes on currentPage and other dependencies
  // this need to be fixed to improve performance
  useEffect(() => {
    const filtersParam = normalizeFilters(appliedFilters);
    const paginationParam = normalizePagination(currentPage - 1, perPage, sortField, sortDirection);

    setIsLoading(true);
    listAll(paginationParam, filtersParam).then(response => {
      const data = response.data;
      setFilteredItems(convertResponse(data['content']));
      setTotalPages(data['totalElements'])
      setIsLoading(false);
    });
  }, [applyFilter, appliedFilters, currentPage, perPage, sortDirection, sortField, notifyChange, normalizeFilters, normalizePagination])

  const navigate = useNavigate();


  const subHeaderComponentMemo = useMemo(() => {
    const goToNewPersona = () => {
      navigate(`/customer/edit/`);
    };
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    const handleText = (event) => {
      setApplyFilter(false);
      setFilterText(event);
    }

    const normalizeOption = (option) => {
      const index = appliedFilters.findIndex(af => {
        return af.option === option.field;
      });

      if (index > -1) {
        appliedFilters.splice(index, 1);
      }

      return appliedFilters;
    }

    const submitFilter = () => {
      if (filterText.length === 0) {
        return;
      }

      const option = filterOptions.filter(op => op.field === filterSelect);
      const newFilter = normalizeOption(option[0]);
      newFilter.push({ option: option[0].field, text: option[0].text, value: filterText });

      setAppliedFilters(newFilter)

      setApplyFilter(true);
      setFilterText("")
    }


    const changeTags = (tag) => {
      setNotifyChange(!notifyChange);
      setApplyFilter(false);
      setAppliedFilters(tag);

    }

    return (
      <div className="container-fluid g-0 mb-3" key={`filterBar`}>
        <div className="row">
          <div className="d-flex">
            {
              <div className='me-4' >
                <Button type="button" variant="primary" size="sm" onClick={goToNewPersona}>Novo Cliente</Button>
              </div>
            }
            <MultipleFilterComponent
              onFilter={(e: any) => handleText(e.target.value)}
              onClear={handleClear} filterText={filterText}
              options={filterOptions}
              OnFilterSelect={(e) => setFilterSelect(e.target.value)}
              filterSelect={filterSelect}
              submitFitler={submitFilter} />
          </div>
          {appliedFilters.length > 0 && <div className='mt-3'>
            <Tags selected={appliedFilters} changeTags={(tag) => changeTags(tag)}></Tags>
          </div>}
        </div>
      </div>
    );
  }, [navigate, resetPaginationToggle, filterText, filterSelect, filterOptions, appliedFilters, notifyChange]);


  const onSort = (rows, direction: sortDirectionType) => {
    if(rows?.sortField) {
      setSortField(rows.sortField);
    }
    if(direction) {
      setSortDirection(direction);
    }
  }

  const onClicked = (row:PersonaInterface) => {
    navigate(`/customer/edit/${row.id}`)
  }

  return (
    <div className={styles.Customer}>
      <div className="gia-page-title-default">Clientes</div>
      {subHeaderComponentMemo}
      {<CustomDatatable
        columns={columns}
        filteredItems={filteredItems}
        filterText={filterText}
        setFilterText={setFilterText}
        isLoading={isLoading}
        selectableRows={false}
        selectableRowsSingle={false}
        resetPaginationToggle={resetPaginationToggle}
        paginationServer
        paginationTotalRows={totalPages}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={setPerPage}
        onChangePage={setCurrentPage}
        sortServer={true}
        onSort={onSort}
        onRowClicked={onClicked}
      />}
    </div>
  );
}
