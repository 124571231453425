import React, {FC, useEffect, useState} from 'react';
import styles from './gia-datetime-period-selector.module.scss';
import {DatetimePeriodSelectorType} from '../../type/datetime-period-selector.type';

interface GiaDatetimePeriodSelectorProps {
  onChange: any
  value?: any,
  disabled?: boolean
}

const GiaDatetimePeriodSelector: FC<GiaDatetimePeriodSelectorProps> = (props) => {

  const [value, setValue] = useState('today');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = event.target.value as any;
    if(value !== fieldValue) {
      setValue(fieldValue);
      props.onChange(fieldValue);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className={styles.GiaDatetimePeriodSelector} data-testid="GiaDatetimePeriodSelector">
      <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
        <input type="radio" name="radio" defaultChecked className="btn-check" id="gia-datetime-period-selector-btn-1" value={DatetimePeriodSelectorType.today} onChange={handleChange}/>
        <label className="btn btn-outline-primary" htmlFor="gia-datetime-period-selector-btn-1">Hoje</label>

        <input type="radio" name="radio" value={DatetimePeriodSelectorType.yesterday} onChange={handleChange} className="btn-check" id="gia-datetime-period-selector-btn-2"/>
        <label className="btn btn-outline-primary" htmlFor="gia-datetime-period-selector-btn-2">Ontem</label>

        <input type="radio" name="radio" value={DatetimePeriodSelectorType.last15Days} onChange={handleChange} className="btn-check" id="gia-datetime-period-selector-btn-3"/>
        <label className="btn btn-outline-primary" htmlFor="gia-datetime-period-selector-btn-3">Quinzena</label>

        <input type="radio" name="radio" value={DatetimePeriodSelectorType.lastMonth} onChange={handleChange} className="btn-check" id="gia-datetime-period-selector-btn-4"/>
        <label className="btn btn-outline-primary" htmlFor="gia-datetime-period-selector-btn-4">Mês</label>

        <input type="radio" name="radio" value={DatetimePeriodSelectorType.lastYear} onChange={handleChange} className="btn-check" id="gia-datetime-period-selector-btn-5"/>
        <label className="btn btn-outline-primary" htmlFor="gia-datetime-period-selector-btn-5">Ano</label>

        <input type="radio" name="radio" disabled={true} value={DatetimePeriodSelectorType.period} onChange={handleChange} className="btn-check" id="gia-datetime-period-selector-btn-6"/>
        <label className="btn btn-outline-primary not-allowed" htmlFor="gia-datetime-period-selector-btn-6">Específico</label>
      </div>
    </div>
  );
};

export default GiaDatetimePeriodSelector;
