import React, {FC} from 'react';
import {KpiValueInterface} from '../../../interfaces/kpi-value.interface';
import styles from './chart-statistic-kpi.module.scss';
import GiaNumberFormat from '../../number-format/gia-number-format';

interface GiaChartStatisticKpiProps {
  kpi: KpiValueInterface
}

const GiaChartStatisticKpi: FC<GiaChartStatisticKpiProps> = ({kpi}) => {
  return (
    <div className={styles.GiaChartStatisticKpi}>
      <div className={styles.name}>{kpi?.name}</div>
      <div className={`${styles.value} ${kpi?.color ? `text-${kpi.color}` : ''}`}>
        <GiaNumberFormat value={kpi?.value} decimalPlaces={1} suffix={kpi?.suffix} />
      </div>
    </div>
  );
}
export default GiaChartStatisticKpi;
