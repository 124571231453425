import React, {FC} from 'react';
import {ChartStatisticsInterface} from '../../interfaces/chart-statistics-interface';
import GiaChartStatisticKpi from './kpi/chart-statistic-kpi';
import styles from './chart-statistic.module.scss';

interface GiaChartStatisticProps {
  statistics: ChartStatisticsInterface
}

const GiaChartStatistic: FC<GiaChartStatisticProps> = ({statistics}) => {

  return (
    <div className={styles.GiaChartStatistic}>
      <GiaChartStatisticKpi kpi={statistics?.threshold} />
      <GiaChartStatisticKpi kpi={statistics?.min} />
      <GiaChartStatisticKpi kpi={statistics?.avg} />
      <GiaChartStatisticKpi kpi={statistics?.max} />
      <GiaChartStatisticKpi kpi={statistics?.total} />
    </div>
  );
}

export default GiaChartStatistic;
