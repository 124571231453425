import axios from 'axios';

export function loadGrowattUser(userId) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/growatt-end-user/${userId}`;
  return axios.get(url);
}

export function saveGrowattUser(user) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/growatt-end-user/save`;
  return axios.put(url, user);
}

export function deleteGrowattUserById(userId) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/growatt-end-user/${userId}`;
  return axios.delete(url);
}

export function loadCredentials() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential/growattToken`;
  return axios.get(url);
}
