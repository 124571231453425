import Skeleton from "react-loading-skeleton";
import styles from "./scheduler-message.module.scss";
import React from "react";

const SchedulerMessageFormSkeleton = () => {

    return (
        <div className={`${styles.skeletonContainer}`}>
            <Skeleton className="mt-4" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

            <Skeleton className="mt-3" height={12} width={50}/>
            <Skeleton className="col-lg-5 col-sm-12 col-xs-5 col-md-5 mb-4" height={32}/>

        </div>
    );
}

export default SchedulerMessageFormSkeleton;
