import React, {FC} from 'react';
import styles from './footer.module.scss';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className={styles.Footer} data-testid="Footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-center footer-container">
          Copyright © 2023 Haus Smart Home Platform. All rights reserved.
          <span className="separator">|</span>
          <a href="http://www.hausplatform.com.br/" rel="noreferrer" target="_blank">Sobre a Haus Smart Home Platform</a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
