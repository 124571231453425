import {FC} from "react";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import {CustomDatatableProps} from "./custom-datatable-interface";
import styles from "./custom-datatable.module.scss";

const progressComponent = <div style={{width:'100%',paddingBottom:'.25rem'}}><Skeleton height={45} count={10}/></div>;

const paginationComponentOptions = {
  rowsPerPageText: 'Registros por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const CustomDatatable:FC<CustomDatatableProps> = (props) => {
    return(
        <div className={`${styles.giaDatatableWra} gia-datatable-wrapper`}>
            <DataTable
              className={`${props.className} giaDatatable`}
              columns={props.columns}
              data={props.filteredItems}
              selectableRows={props.selectableRows}
              selectableRowsSingle={props.selectableRowsSingle}
              persistTableHead
              progressPending={props.isLoading}
              progressComponent={progressComponent}
              fixedHeader={true}
              noDataComponent={<span className="gia-datatable-no-results">{props.noDataText ?? 'Nenhum registro'}</span>}
              sortServer={props.sortServer}
              pointerOnHover={props.pointerOnHover ?? true}
              theme="gia-default"
              pagination
              paginationComponentOptions={paginationComponentOptions}
              paginationServer={props.paginationServer}
              paginationResetDefaultPage={props.resetPaginationToggle}
              paginationTotalRows={props.paginationTotalRows}
              paginationDefaultPage={props.paginationDefaultPage}
              onChangeRowsPerPage={props.onChangeRowsPerPage}
              onChangePage={props.onChangePage}
              onSort={props.onSort}
              onRowClicked={props.onRowClicked}
              onSelectedRowsChange={props.onSelectedRowsChange}
              selectableRowsHighlight={props.selectableRowsHighlight}
              selectableRowSelected={props.selectableRowSelected}
              paginationPerPage={props.paginationPerPage}
              customStyles={props.customStyles}
            />
        </div>
    )
}

export default CustomDatatable;
