import axios from "axios";

export function loadGroups() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/group`;
  return axios.get(url);
}

export function loadManufacturers() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/manufacturer`;
  return axios.get(url);
}

export function loadManufacturerCredentialTypesByManufacturerId(id: any) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/manufacturerCredentialType/manufacturer/${id}`;
  return axios.get(url);
}

export function loadPersonaCredentialById(id: any) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential/${id}`;
  return axios.get(url);
}

export function savePersonaCredential(payload: any) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential`;
  return axios.post(url, payload);
}

export function patchPersonaCredential(payload: any) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential`;
  return axios.patch(url, payload);
}

export function deletePersonaCredentialById(id: any) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/personaCredential/${id}`;
  return axios.delete(url);
}
