import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './event-configuration-edit.module.scss';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Skeleton from 'react-loading-skeleton';

import { useNavigate, useParams } from 'react-router-dom';
import { DestinationListProps, EventConfigurationProps, NotificationServiceProps, SendNotificationProps } from '../event-configuration-interface';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { GiaTextField } from '../../../components/gia-forms-components/gia-forms-components';
import { useForm } from 'react-hook-form';
import { readFile } from '../../../shared/helpers/conversion.utils';
import { toast } from 'react-toastify';
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultToastOptions } from '../../../shared/toast/toast-default.options';
import { cronRegex } from '../../../shared/helpers/string-utils.helper';
import { deleteEventConfiguration, eventConfigurationDetail, listDestination, listNotificationService, saveEventConfiguration } from './_request';
import { convertFileInUrl, createNewFile } from '../../../shared/helpers/file.utils';




/* INTERFACES */
interface EventConfigurationEditProps { }


const EventConfigurationEdit: FC<EventConfigurationEditProps> = () => {

  let { eventId } = useParams();


  const eventConfigurationConfig: EventConfigurationProps = {
    id: null,
    name: "",
    active: false,
    cronExpression: "",
    dataFrom: "",
    jsonRootName: "",
    jsonBody: "",
    objectFieldId: "",
    dmn: "",
    dmnName: "",
    notificationServiceEventList: []
  }


  const [eventConfiguration, setEventConfiguration] = useState<EventConfigurationProps>(eventConfigurationConfig);
  const [destinationList, setDestinationList] = useState<DestinationListProps[]>([]);
  const [notificationServiceList, setNotificationServiceList] = useState<NotificationServiceProps[]>();
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileLabel, setFileLabel] = useState<string>();
  const [fileDownload, setFileDownload] = useState<string>();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();

  const requiredErrorMessage = String.fromCharCode(0);

  const formSchema = z.object({
    id: z.number().optional().nullable(),
    name: z.string().min(1).max(255),
    active: z.preprocess(value => Number(value) === 1, z.boolean()),
    cronExpression: z.string().min(1, requiredErrorMessage).max(255).refine(value => cronValidate(value.trim()), { message: "Expressão inválida" }),
    dataFrom: z.string().max(255).optional().nullable(),
    jsonRootName: z.string().max(255).optional().nullable(),
    jsonBody: z.string().max(255).optional().nullable(),
    objectFieldId: z.string().optional().nullable(),
    dmn: z.string().max(65535).optional().nullable(),
    dmnName: z.string().max(255).optional().nullable(),

    notificationServiceEventList: z.preprocess((value) => containsDuplicityDestination(value), z.object({
      nseId: z.number().optional().nullable(),
      destination: z.object({
        desId: z.number().min(1),
        name: z.string().min(1).max(255)
      }).optional().nullable(),
      notificationService: z.object({
        nsId: z.number().min(1),
        name: z.string().min(1).max(255)
      }).optional().nullable(),
      active: z.preprocess(value => Number(value) === 1, z.boolean())
    }).array().optional().nullable()).optional().nullable()
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const { register, handleSubmit, setValue, reset, getValues, formState: { errors } } = useForm<FormSchemaType>({
    mode: "all",
    resolver: zodResolver(formSchema)
  });


  const containsDuplicityDestination = (value) => {
    const list = value as SendNotificationProps[];
    const listElements = [];
    let isValid = true;
    list.forEach(l => {
      if (!l.destination?.name || !l.notificationService?.name || !isValid) {
        return;
      }
      const key = l.destination?.name + "-" + l.notificationService?.name

      if (!listElements[key]) {
        listElements[key] = true;
      }
      else if (listElements[key]) {
        toast.warning("Não é possível adicionar destinatários iguais, \n" +
          "exclua uma das opções", defaultToastOptions)
        isValid = false;
      }
    });

    return isValid ? value : false;

  }

  const preparationDmnDownload = (data, fileName, fileType) => {
    if (!fileName) {
      fileName = "download_dmn";
    }
    if (data) {
      createNewFile(data, fileName, fileType).then(response => {
        setFileDownload(convertFileInUrl(response));
      })
    }
  }


  useMemo(() => {
    if (!eventConfiguration) {
      return;
    }
    setFileLabel(eventConfiguration.dmnName);
    preparationDmnDownload(eventConfiguration.dmn, eventConfiguration.dmnName, '.dmn');
    reset(eventConfiguration as FormSchemaType)
  }, [eventConfiguration, reset]);




  const getFile = (files: FileList | null) => {
    if (files == null) {
      return;
    }

    setFileLabel(files[0]?.name)
    readFile(files, (response: string) => {
      setValue("dmn", response);
      preparationDmnDownload(response, fileLabel, '.dmn');
    });
  }

  const cronValidate = (value) => {
    const cronregex = new RegExp(cronRegex);
    return cronregex.test(value);
  }

  const saveEvent = (data: FormSchemaType) => {
    data.dmnName = fileLabel;
    saveEventConfiguration(data).then((response) => {
      setEventConfiguration(response.data);
      toast.success(`Evento ${eventId ? "atualizado" : "criado"} com sucesso`);
      if (!eventId) {
        eventId = response.data.id;
        navigate(`/event-configuration/detail/${eventId}`);
      }
    })
      .catch(() => {
        toast.error("Não foi possível atualizar o evento \n" +
          "Entre em contato com administrador")
      });

  }



  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      listDestination(),
      listNotificationService(),
      eventId ? eventConfigurationDetail(eventId) : [] as never

    ]).then((response) => {
      setDestinationList(response[0].data);
      setNotificationServiceList(response[1].data);
      setEventConfiguration(response[2].data);


      setIsLoading(false);
    })
  }, [eventId]);



  const handleDestination = (event, i) => {


    const destination = destinationList.filter(d => d.desId === Number(event));
    const notificationServiceEventList = getValues().notificationServiceEventList;

    if (destination.length === 0) {
      return;
    }

    if (notificationServiceEventList) {
      notificationServiceEventList[i].destination = { desId: destination[0].desId || 0, name: destination[0].name ? destination[0].name : "" };
      setValue(`notificationServiceEventList`, notificationServiceEventList);
      forceUpdate();

    }
  }

  const handleNotificationService = (event, i) => {
    if (!notificationServiceList) {
      return;
    }

    const notificationService = notificationServiceList.filter(n => n.nsId === Number(event));
    const notificationServiceEventList = getValues().notificationServiceEventList;
    if (notificationServiceEventList && notificationService) {
      notificationServiceEventList[i].notificationService = {
        nsId: notificationService[0].nsId || 0,
        name: notificationService[0].name ? notificationService[0].name : ""
      };
      setValue(`notificationServiceEventList`, notificationServiceEventList);
      forceUpdate();

    }
  }

  const addNotification = () => {
    const notificationServiceEventList = getValues().notificationServiceEventList;
    notificationServiceEventList?.push({
      nseId: null,
      notificationService: { nsId: 0, name: "Selecione o meio" },
      destination: { desId: 0, name: "Selecione o Destinatário" },
      active: false
    })
    setValue("notificationServiceEventList", notificationServiceEventList);
    forceUpdate();
  }

  const removeNotification = (index) => {
    let notificationServiceEventList = getValues().notificationServiceEventList?.filter((n, i) => i !== index);
    setValue("notificationServiceEventList", notificationServiceEventList);
    forceUpdate();
  }

  const removeDmn = () => {
    setValue("dmn", "");
    setFileDownload("");
    setFileLabel("");
  }

  const deleteEvent = () => {
    const id = getValues().id;

    const confirm = window.confirm('Confirma excluir o alerta ? Esta ação não pode ser desfeita.');
    if (confirm) {
      deleteEventConfiguration(id).then(() => {
        toast.success('Usuário excluido com sucesso!', defaultToastOptions);
        navigate(`/event-configuration`);
      }).catch(() => {
        toast.error('Houve um problema ao excluir o usuário. Por favor tente novamente.', defaultToastOptions);
      });
    }
  }

  return (
    <div className={styles.EventConfigurationEdit} data-testid="EventConfigurationEdit">
      {isLoading && <Skeleton height={48} />}
      {
        !isLoading &&
        <div className={`${styles.EventConfigurationForm} ps-3 container-fluid`}>
          <div className="row mb-4">
            <div className="gia-page-title-default header col-md-9 col-lg-9">Alertas</div>
          </div>
          <form onSubmit={handleSubmit(saveEvent)} >
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  type="text"
                  registerName={register('name')}
                  required
                  isInvalid={!!errors.name}
                  isInvalidText={errors?.name?.message}
                  title="Nome" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  type="text"
                  registerName={register('cronExpression')}
                  isInvalid={!!errors.cronExpression}
                  isInvalidText={errors?.cronExpression?.message}
                  title="Expressão da Cron" />
                {errors?.cronExpression && <div className="gia-message-error">{errors?.cronExpression?.message}</div>}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  type="text"
                  required={false}
                  registerName={register('dataFrom')}
                  isInvalid={!!errors.dataFrom}
                  isInvalidText={errors?.dataFrom?.message}
                  title="Origem dos dados" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  type="text"
                  registerName={register('jsonRootName')}
                  isInvalid={!!errors.jsonRootName}
                  isInvalidText={errors?.jsonRootName?.message}
                  title="Raiz do JSON" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                  type="text"
                  registerName={register('jsonBody')}
                  height={30} isInvalid={!!errors.jsonBody}
                  isInvalidText={errors?.jsonBody?.message}
                  title="Corpo do JSON" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <GiaTextField
                    type="text"
                    registerName={register('objectFieldId')}
                    isInvalid={!!errors.objectFieldId}
                    isInvalidText={errors?.objectFieldId?.message}
                    height={30} title="ID do campo do Objeto" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <label htmlFor="Ativo" className="label required">Situação</label>
                <div className={`${styles.checkContainer} mb-4`}>
                  <Form.Check
                    type="checkbox"
                    id="Ativo"
                    label='Ativo'
                    value={1}
                    {...register('active')}
                    isInvalid={!!errors.active}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
                <div className={`${styles.UploadDmn} mb-4`}>
                  <div className="label mb-2">Upload DMN</div>
                  <input
                    type="file" {...register("dmn")}
                    accept=".dmn"
                    onChange={e => getFile(e.target.files)}
                    onClick={(e) => e.currentTarget.value = ''}
                    ref={fileInput}
                    style={{ display: 'none' }} />
                  <div className="form-control" >
                    <FontAwesomeIcon icon={faCloudArrowUp} onClick={() => fileInput.current?.click()} className="me-2" />
                    {
                      fileDownload ?
                        <a
                          href={fileDownload}
                          download={fileLabel || "download.dmn"}
                          target="_blank"
                          rel="noreferrer">
                          {fileLabel || "Download DMN"}
                        </a>
                        :
                        <span onClick={() => fileInput.current?.click()}>Selecione o arquivo</span>
                    }
                  </div>
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Button className="mt-4" variant="outline-light" size="sm" onClick={removeDmn}>Remover</Button>
              </Form.Group>
            </Row>
            <div className={styles.EventConfigurationForm}>
              <div className="label mb-2">DESTINATÁRIOS</div>
              {
                getValues().notificationServiceEventList?.map((notification, i) => (
                  <div key={i} className="mb-3 md-sm-4">
                    <Row >
                      <FormGroup as={Col} md="3">
                        {getValues().notificationServiceEventList?.length && <span className="label mt-2">Modo de envio</span>}
                        <Form.Select
                          value={notification.notificationService?.nsId || 0}
                          onChange={event => handleNotificationService(event.target.value, i)}
                          isInvalid={errors.notificationServiceEventList && !!errors.notificationServiceEventList[i]}
                        >
                          <option value="0" disabled>Selecione o modo de envio</option>
                          {notificationServiceList?.map((notification) => (
                            <option key={notification?.nsId} value={notification?.nsId}>
                              {notification?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </FormGroup>

                      <FormGroup as={Col} md="3">
                        {getValues().notificationServiceEventList?.length ? <span className="label mt-2">Destinatário</span> : false}
                        <Form.Select
                          value={notification.destination?.desId || 0}
                          onChange={(event) => handleDestination(event.target.value, i)}
                        >
                          <option value="0" disabled>Selecione o Destinatário</option>
                          {destinationList?.map((destination) => (
                            <option key={destination?.desId} value={destination?.desId}>
                              {destination?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </FormGroup>
                      <FormGroup as={Col} md="2" className="mt-1">
                        <label htmlFor="Ativo" className="label">Notificar Encerramento?</label>
                        <div className={`${styles.border} `}>
                          <Form.Check
                            type="checkbox"
                            label="Ativo"
                            value={1}
                            {...register(`notificationServiceEventList.${i}.active`)}
                            isInvalid={!!errors.active}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup as={Col} md="4" className="mt-3">
                        <Button variant="outline-light" size="sm" onClick={() => removeNotification(i)}>Remover</Button>
                      </FormGroup>
                    </Row>
                  </div>
                ))
              }
              <Button className="mt-2" size="sm" variant="outline-light" onClick={addNotification}>Adicionar Destinatário </Button>
            </div>
            <div className={`${styles.saveContainer} d-flex mt-4`}>
              <Button type="submit">Salvar</Button>
              <Button type="button" variant="secondary" onClick={() => navigate('/event-configuration')} size="sm">Voltar</Button>
              {eventId ? <Button type="button" variant="danger" size="sm" onClick={deleteEvent}>Excluir</Button> : <></>}
            </div>
          </form>
        </div >}
    </div >
  );
};

export default EventConfigurationEdit;
