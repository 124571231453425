import React, {FC} from 'react';
import styles from './gia-spacer.module.scss';

interface GiaSpacerProps {}

const GiaSpacer: FC<GiaSpacerProps> = () => (
  <div className={styles.GiaSpacer} data-testid="GiaSpacer"></div>
);

export default GiaSpacer;
