export enum PersonaTypeEnum {
  ALL = 'Todos',
  CUSTOMER = 'Cliente',
  DISTRIBUTOR = 'Distribuidor',
  INTEGRATOR = 'Integrador',
  MANUFACTURER = 'Fabricante',
  FINANCIER = 'Financiador'
}

export interface PersonaTypeInterface {
  type: keyof typeof PersonaTypeEnum,
  name: string
}
