import {z} from "zod";

export const schedulerMessageFormSchema = z.object({
    id: z.number().nullable().optional(),
    active: z.union([z.number(), z.preprocess(value => value === '1', z.boolean())]),
    cronExpression: z.string().min(1).max(255),
    dataFrom: z.string().min(1).max(255),
    messageBody: z.string().max(255).nullable(),
    messageSubject: z.string().max(255).nullable(),
    name: z.string().min(1).max(255),
    plantIdField: z.string().min(1).max(255),
    personaField: z.string().min(1).max(255),
    powerField: z.string().max(255).nullable(),
    template: z.string().max(255).nullable(),
    groups: z.object({
        robbuSchedulerMessageGroupIdDTO: z.object({
            robbuSchedulerMessageId: z.number(),
            robbuSchedulerGroupId: z.number()
        })
    }).array().min(1, "Necessário que seja incluído no mínimo um Grupo de Usinas"),
    channel: z.string().min(1).max(50)
});

export type SchedulerMessageFormSchemaType = z.infer<typeof schedulerMessageFormSchema>;



