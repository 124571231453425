import {KeycloakOnLoad, KeycloakResponseType} from 'keycloak-js';

export const kcInitOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required' as KeycloakOnLoad,
  responseType: 'id_token token' as KeycloakResponseType,
  checkLoginIframe: false
} as any;
