/**
 * Convert the string of DatetimePeriodSelectorType for compatibility of backend
 * @param value
 */
export const convertDatetimePeriodForBackend = (value) => {
  switch (value) {
    case 'last15Days':
      return 'fortnight';
    case 'lastMonth':
      return 'month';
    case 'lastYear':
      return 'year';
    case 'period':
      return 'custom';
    default:
      return value;
  }
};

/**
 * Convert the given number to locale format
 * @param value
 */
export const numberFormatLocale = (value, maxDigits) => {
  if (value) {
    return value.toLocaleString('pt-br', { maximumFractionDigits: maxDigits });
  }
  return value;
};

/**
 * Calculate e convert the value to the appropriate order of magnitude
 * @param value A number or decimal to convert
 * @param precision decimal units | Default 1
 * @param returnAsObject Return the value as object with value and suffix separated | Default false
 */
export const convertWattPicoToHumanReadable = (value: number, precision: number, returnAsObject = false): any => {
  if (value === 0 || !isFinite(value)) {
    return '0';
  }
  if (typeof precision === 'undefined') {
    precision = 1;
  }
  const base = 1000;
  const units = ['kWp', 'MWp', 'GWp'];
  const calc = Math.floor(Math.log(value) / Math.log(base));
  const valueConverted = (value / Math.pow(base, Math.floor(calc))).toFixed(precision);
  const suffix = units[calc];
  return (returnAsObject) ? { valueConverted, suffix } : `${valueConverted} ${suffix}`;
}


export const readFile = (files: FileList | null, callback: CallableFunction) => {
  if (files != null && files.length > 0) {
    let reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = (event) => {
      const content = event?.target?.result;
      callback(content);
    };
  }
}

