import axios from "axios";

export const listAll = (paginationUri, filtersUri) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/customer/list?${paginationUri}${filtersUri}`;
  return axios.get(url);
}

export const findById = (id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/customer/${id}`;
  return axios.get(url);

}

export const findLegalEntityByDocument = (document: string) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/legalEntity/document/${document}`;
  return axios.get(url);
}

export const findType = (id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/customer/type/${id}`;
  return axios.get(url);
}

export const save = (type, id, persona, personaTYpeValue) => {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/customer/${id}`;
  return axios.put(url, { personaDTO: persona, simplePersonaTypeValue: personaTYpeValue });
}
