import axios from 'axios';


export function listEvents() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events/list`;
  return axios.get(url);
}

export function deleteEventConfiguration(id) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events/${id}`;
  return axios.delete(url);
}

export function saveEventConfiguration(data) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events//save-event`;
  return axios.post(url, data);
}

export function listDestination() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events/destination-list`;
  return axios.get(url);
}

export function listNotificationService() {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events/notification-service-list`;
  return axios.get(url);
}

export function eventConfigurationDetail(id) {
  const url = `${process.env.REACT_APP_BACKEND_API_URL}/events/detail/${id}`;
  return axios.get(url);
}